import React, { useState, useEffect, useContext } from "react";
import SiteWrapper from "../../Layouts/SiteWrapper";
import { Button, Image } from "react-bootstrap";
import {
  Card,
  Grid,
  Tab,
  TabbedHeader,
  TabbedContainer,
  Badge,
} from "tabler-react";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import axios from "axios";
import Loader from "react-loader-spinner";

import AvailabilityCard from "../../Components/Artis/AvailabilityCardComponent";
import CatatanCard from "../../Components/Artis/CatatanCardComponent";
import Foto from "../../Components/Foto/FotoComponent";
import TambahFoto from "../../Components/Foto/TambahFotoComponent";
import Video from "../../Components/Video/VideoComponent";
import TambahVideo from "../../Components/Video/TambahVideoComponent";
import { Context } from "../../Context/Store";
import { ContextPermission } from "../../Context/Permission";
import { ContextAlert } from "../../Context/Alert";
import JadwalCard from "../../Components/Jadwal/JadwalCardComponent";

function DetailArtis(prop) {
  const [state, dispatch] = useContext(Context);
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [selectedTitle, setSelectedTitle] = useState("Biografi");
  const [buttonAction, setButtonAction] = useState("");
  const [imagePreview, setImagePreview] = useState({
    image: "",
    isOpen: false,
  });
  const [dataValue, setDataValue] = useState({});
  const [foto, setFoto] = useState([]);
  const [video, setVideo] = useState([]);
  const [loader, setLoader] = useState(true);
  const [change, setChange] = useState(true);
  const statePermission = useContext(ContextPermission);
  const [message, setMessage] = useContext(ContextAlert);

  const jenis_film = (value) => {
    let data = ["None", "Film TV", "Iklan", "TV Series", "Layar Lebar"];
    return data[value - 1];
  };

  const peran = (value) => {
    let data = [
      "None",
      "Peran Utama",
      "P. Pembantu Utama",
      "Peran Pembantu",
      "Figuran",
    ];
    return data[value - 1];
  };

  useEffect(() => {
    if (change === true) {
      fetchData();
    }
  }, [selectedTitle, change]);

  function fetchData() {
    setLoader(true);
    var url = `${urlMicroservice}talent/kandidat-artis/show?id=${prop.match.params.id}`;
    if (selectedTitle === "Biografi") {
      setButtonAction(
        <Link
          className="ml-auto my-auto"
          to={`/kandidat-artis/edit-biografi/${prop.match.params.id}`}
        >
          <Button variant="outline-danger" type="button">
            <i className="bx bxs-edit-alt"></i> Edit Biografi
          </Button>
        </Link>
      );
    } else if (selectedTitle === "Foto") {
      url = `${urlMicroservice}talent/kandidat-artis/foto?artist_id=${prop.match.params.id}`;
      setButtonAction(
        <TambahFoto
          jenis="kandidat-artis"
          setChange={setChange}
          id={prop.match.params.id}
        ></TambahFoto>
      );
    } else if (selectedTitle === "Video") {
      url = `${urlMicroservice}talent/kandidat-artis/video?artist_id=${prop.match.params.id}`;
      setButtonAction(
        <TambahVideo
          jenis="kandidat-artis"
          setChange={setChange}
          id={prop.match.params.id}
        ></TambahVideo>
      );
    } else {
      setButtonAction("");
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (selectedTitle === "Biografi") {
          setDataValue(res.data.data);
        } else if (selectedTitle === "Foto") {
          setFoto(res.data.data);
        } else if (selectedTitle === "Video") {
          setVideo(res.data.data);
        }
        setLoader(false);
        setChange(false);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }

  useEffect(() => {
    dispatch({
      ...state,
      submit: true,
    });
  }, []);

  function tabClick(params) {
    setChange(true);
    setSelectedTitle(params);
  }

  type breadcrumbItem = {|
    +value: string,
    +to?: string,
    +active?: boolean,
  |};

  const breadcrumbItems: Array<breadcrumbItem> = [
    {
      value: "Kandidat Artis",
      to: "/kandidat-artis",
      active: true,
    },
    {
      value: dataValue.nama_artis,
      to: "/kandidat-artis/detail/" + prop.match.params.id,
      active: false,
    },
  ];

  return (
    <SiteWrapper breadcrumbItems={breadcrumbItems} title={dataValue.nama_artis}>
      <Loader
        visible={loader}
        className="loaders"
        type="ThreeDots"
        color="#ED1C24"
        height="100"
        width="100"
      />
      {statePermission.permission.kandidat.view_detail_kandidat.active === 1 ? (
        <>
          <Grid.Row>
            <Grid.Col width="9">
              <Grid.Row>
                <Grid.Col md="12">
                  <Card className="border-card mt-5">
                    <Card.Body className="px-4 py-0 d-flex">
                      <TabbedHeader
                        selectedTitle={selectedTitle}
                        stateCallback={tabClick}
                      >
                        <Tab title="Biografi"></Tab>
                        <Tab title="Foto"></Tab>
                        <Tab title="Video"></Tab>
                        {/* <Tab title="Kontrak"></Tab> */}
                      </TabbedHeader>
                      {buttonAction}
                    </Card.Body>
                  </Card>
                  <TabbedContainer selectedTitle={selectedTitle}>
                    <Grid.Row cards={true} title="Biografi">
                      <Grid.Col width={12}>
                        <Card className="border-card">
                          <Card.Body>
                            <Grid.Row>
                              <Grid.Col width={2}>
                                <Image
                                  src="/images/icon/biodata.png"
                                  className="artis-icon"
                                />
                                <h4 className="font-weight-bold mt-4">
                                  Biodata
                                </h4>
                              </Grid.Col>
                              <Grid.Col width={10}>
                                <div className="text-grey mb-1">
                                  Foto Profil
                                </div>
                                <Image
                                  src={dataValue.foto}
                                  className="profil-icon"
                                />
                                <Grid.Row className="mt-4">
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Nama Depan</div>
                                    <span className="font-weight-bold">
                                      {dataValue.nama_depan}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">
                                      Nama Belakang
                                    </div>
                                    <span className="font-weight-bold">
                                      {dataValue.nama_belakang}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Nama Artis</div>
                                    <span className="font-weight-bold">
                                      {dataValue.nama_artis}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Gender</div>
                                    <span className="font-weight-bold">
                                      {dataValue.gender === 1
                                        ? "Laki-Laki"
                                        : "Perempuan"}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">
                                      Tanggal Lahir
                                    </div>
                                    <span className="font-weight-bold">
                                      {dataValue.tanggal_lahir}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">
                                      Tempat Lahir
                                    </div>
                                    <span className="font-weight-bold">
                                      {dataValue.tempat_lahir}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Agama</div>
                                    <span className="font-weight-bold">
                                      {dataValue.agama}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Tinggi</div>
                                    <span className="font-weight-bold">
                                      {dataValue.tinggi}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Berat</div>
                                    <span className="font-weight-bold">
                                      {dataValue.berat}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Status</div>
                                    <span className="font-weight-bold">
                                      {dataValue.status_pernikahan !== null &&
                                      dataValue.status_pernikahan !== ""
                                        ? dataValue.status_pernikahan == 1
                                          ? "Single"
                                          : "Menikah"
                                        : null}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Group</div>
                                    <span className="font-weight-bold">
                                      {dataValue.group}
                                    </span>
                                  </Grid.Col>
                                  {/* <Grid.Col className="mb-4" width={12}>
                                    <div className="text-grey">Attitude</div>
                                    <span className="font-weight-bold">
                                      {dataValue.attitude}
                                    </span>
                                  </Grid.Col> */}
                                  <Grid.Col className="mb-4" width={12}>
                                    <div className="text-grey">Remark</div>
                                    <span className="font-weight-bold">
                                      {dataValue.remark}
                                    </span>
                                  </Grid.Col>
                                </Grid.Row>
                              </Grid.Col>
                            </Grid.Row>
                            <hr className="h-dashed"></hr>
                            <Grid.Row>
                              <Grid.Col width={2}>
                                <Image
                                  src="/images/icon/casting.png"
                                  className="artis-icon"
                                />
                                <h4 className="font-weight-bold mt-4">
                                  Info Casting
                                </h4>
                              </Grid.Col>
                              <Grid.Col width={10}>
                                <Grid.Row className="mt-4">
                                  {/* <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">
                                      Artis Sinemart
                                    </div>
                                    {dataValue.artis_sinemart === 1 ? (
                                      <div className="font-weight-bold d-flex align-items-center">
                                        <i className="bx bxs-check-circle text-success fa-2x mr-2"></i>
                                        Iya
                                      </div>
                                    ) : (
                                      <div className="font-weight-bold d-flex align-items-center">
                                        <i className="bx bxs-x-circle text-danger fa-2x mr-2"></i>
                                        Tidak
                                      </div>
                                    )}
                                  </Grid.Col> */}

                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Eksklusif</div>
                                    {dataValue.is_exclusive === 1 ? (
                                      <div className="font-weight-bold d-flex align-items-center">
                                        <i class="bx bxs-check-circle text-success fa-2x mr-2"></i>
                                        Iya
                                      </div>
                                    ) : (
                                      <div className="font-weight-bold d-flex align-items-center">
                                        <i class="bx bxs-x-circle text-danger fa-2x mr-2"></i>
                                        Tidak
                                      </div>
                                    )}
                                  </Grid.Col>

                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">
                                      Tanggal Casting
                                    </div>
                                    <span className="font-weight-bold">
                                      {dataValue.tanggal_casting}
                                    </span>
                                  </Grid.Col>
                                  {/* <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">
                                      Nilai Casting
                                    </div>
                                    <span className="font-weight-bold">
                                      {dataValue.nilai_casting}
                                    </span>
                                  </Grid.Col> */}
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Peran</div>
                                    <span className="font-weight-bold">
                                      {dataValue.peran !== null &&
                                      dataValue.peran !== ""
                                        ? dataValue.peran === 1
                                          ? "Protagonis"
                                          : dataValue.peran === 2
                                          ? "Antagonis"
                                          : "Protagonis & Antagonis"
                                        : null}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Karakter</div>
                                    <span className="font-weight-bold">
                                      {dataValue.karakter}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Grade</div>
                                    <span className="font-weight-bold">
                                      <Badge
                                        color="outline-warning"
                                        className="p-2 pr-4 d-inline-flex align-items-center"
                                      >
                                        <i className="bx bxs-medal text-warning fa-2x mr-2"></i>{" "}
                                        Grade {dataValue.grade}
                                      </Badge>
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">
                                      Talent Prospect
                                    </div>
                                    <div className="font-weight-bold">
                                      {dataValue.talent_prospects &&
                                        dataValue.talent_prospects.map(
                                          (item) => {
                                            return (
                                              <Badge
                                                color="outline-primary"
                                                className="p-2 d-inline-flex align-items-center mr-2"
                                              >
                                                {item.nama}
                                              </Badge>
                                            );
                                          }
                                        )}
                                    </div>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Talent Tim</div>
                                    <span className="font-weight-bold">
                                      {dataValue.talent_team_name}
                                    </span>
                                  </Grid.Col>

                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">
                                      Categori Casting
                                    </div>
                                    <span className="font-weight-bold">
                                      {dataValue.category_casting_name}
                                    </span>
                                  </Grid.Col>
                                </Grid.Row>
                              </Grid.Col>
                            </Grid.Row>
                            <hr className="h-dashed"></hr>
                            <Grid.Row>
                              <Grid.Col width={2}>
                                <Image
                                  src="/images/icon/kontak.png"
                                  className="artis-icon"
                                />
                                <h4 className="font-weight-bold mt-4">
                                  Kontak
                                </h4>
                              </Grid.Col>
                              <Grid.Col width={10}>
                                <Grid.Row className="mt-4">
                                  <Grid.Col className="mb-4" width={12}>
                                    <div className="text-grey">Alamat</div>
                                    <span className="font-weight-bold">
                                      {dataValue.alamat}
                                    </span>
                                  </Grid.Col>
                                  {/* <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Region</div>
                                    <span className="font-weight-bold">
                                      {dataValue.region}
                                    </span>
                                  </Grid.Col> */}
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Email</div>
                                    <span className="font-weight-bold">
                                      {dataValue.email}
                                    </span>
                                  </Grid.Col>
                                  {/* <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Fax</div>
                                    <span className="font-weight-bold">
                                      {dataValue.fax}
                                    </span>
                                  </Grid.Col> */}
                                  {/* <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Telepon 1</div>
                                    <span className="font-weight-bold">
                                      {dataValue.telepon_1}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Telepon 2</div>
                                    <span className="font-weight-bold">
                                      {dataValue.telepon_2 == null
                                        ? "-"
                                        : dataValue.telepon_2}
                                    </span>
                                  </Grid.Col> */}
                                  {/* <Grid.Col
                                    className="mb-4"
                                    width={4}
                                  ></Grid.Col> */}
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">No HP</div>
                                    <span className="font-weight-bold">
                                      {dataValue.hp_1}
                                    </span>
                                  </Grid.Col>
                                  {/* <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">HP 2</div>
                                    <span className="font-weight-bold">
                                      {dataValue.hp_2 == null
                                        ? "-"
                                        : dataValue.hp_2}
                                    </span>
                                  </Grid.Col> */}
                                </Grid.Row>
                              </Grid.Col>
                            </Grid.Row>
                            <hr className="h-dashed"></hr>
                            <Grid.Row>
                              <Grid.Col width={2}>
                                <Image
                                  src="/images/icon/pengalaman.png"
                                  className="artis-icon"
                                />
                                <h4 className="font-weight-bold mt-4">
                                  Pengalaman
                                </h4>
                              </Grid.Col>
                              <Grid.Col width={10}>
                                <Grid.Row className="mt-4">
                                  {dataValue.experiences &&
                                    dataValue.experiences.map((item, index) => {
                                      return (
                                        <Grid.Col className="mb-4" width={12}>
                                          <div className="d-flex">
                                            <div className="text-circle">
                                              {index + 1}
                                            </div>
                                            <div>
                                              <div className="font-weight-bold">
                                                {item.judul}{" "}
                                                <span className="font-weight-normal">
                                                  ({item.tahun})
                                                </span>
                                              </div>
                                              <div className="text-grey">
                                                {jenis_film(item.jenis_film)}
                                                <span className="mx-1">
                                                  &bull;
                                                </span>
                                                {peran(item.peran)}
                                              </div>
                                            </div>
                                          </div>
                                        </Grid.Col>
                                      );
                                    })}
                                </Grid.Row>
                              </Grid.Col>
                            </Grid.Row>
                            <hr className="h-dashed"></hr>
                            <Grid.Row>
                              <Grid.Col width={2}>
                                <Image
                                  src="/images/icon/management.png"
                                  className="artis-icon"
                                />
                                <h4 className="font-weight-bold mt-4">
                                  Management
                                </h4>
                              </Grid.Col>
                              <Grid.Col width={10}>
                                <Grid.Row className="mt-4">
                                  <Grid.Col className="mb-4" width={12}>
                                    <div className="text-grey">PIC</div>
                                    <span className="font-weight-bold">
                                      {dataValue.person_in_charge}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Manager</div>
                                    <span className="font-weight-bold">
                                      {dataValue.manajer}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">
                                      Telepon Manager
                                    </div>
                                    <span className="font-weight-bold">
                                      {dataValue.telepon_manajer}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col
                                    className="mb-4"
                                    width={4}
                                  ></Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Management</div>
                                    <span className="font-weight-bold">
                                      {dataValue.manajemen}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">
                                      Telepon Management 1
                                    </div>
                                    <span className="font-weight-bold">
                                      {dataValue.telepon_manajemen_1}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">
                                      Telepon Management 2
                                    </div>
                                    <span className="font-weight-bold">
                                      {dataValue.telepon_manajemen_2 == null
                                        ? "-"
                                        : dataValue.telepon_manajemen_2}
                                    </span>
                                  </Grid.Col>
                                  {/* <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">Agency</div>
                                    <span className="font-weight-bold">
                                      {dataValue.agency}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">
                                      Telepon Agency 1
                                    </div>
                                    <span className="font-weight-bold">
                                      {dataValue.telepon_agency_1}
                                    </span>
                                  </Grid.Col>
                                  <Grid.Col className="mb-4" width={4}>
                                    <div className="text-grey">
                                      Telepon Agency 2
                                    </div>
                                    <span className="font-weight-bold">
                                      {dataValue.telepon_agency_2 == null
                                        ? "-"
                                        : dataValue.telepon_agency_2}
                                    </span>
                                  </Grid.Col> */}
                                </Grid.Row>
                              </Grid.Col>
                            </Grid.Row>
                            {/* <hr className="h-dashed"></hr>
                            <Grid.Row>
                              <Grid.Col width={2}>
                                <Image
                                  src="/images/icon/npwp.png"
                                  className="artis-icon"
                                />
                                <h4 className="font-weight-bold mt-4">NPWP</h4>
                              </Grid.Col>
                              <Grid.Col width={10}>
                                <Grid.Row className="mt-4">
                                  {dataValue.taxes &&
                                    dataValue.taxes.map((item) => {
                                      return (
                                        <Grid.Col className="mb-4" width={4}>
                                          <div className="text-grey">
                                            {item.nama_npwp}
                                          </div>
                                          <span className="font-weight-bold">
                                            {item.npwp}
                                          </span>
                                        </Grid.Col>
                                      );
                                    })}
                                </Grid.Row>
                              </Grid.Col>
                            </Grid.Row> */}
                            <hr className="h-dashed"></hr>
                            <Grid.Row>
                              <Grid.Col width={2}>
                                <Image
                                  src="/images/icon/rekening.png"
                                  className="artis-icon"
                                />
                                <h4 className="font-weight-bold mt-4">
                                  Rekening
                                </h4>
                              </Grid.Col>
                              <Grid.Col width={10}>
                                <Grid.Row className="mt-4">
                                  {dataValue.bank_accounts &&
                                    dataValue.bank_accounts.map((item) => {
                                      return (
                                        <Grid.Col className="mb-4" width={4}>
                                          <div className="text-grey">
                                            {item.pemilik_rekening}
                                          </div>
                                          {item.bank == null ? (
                                            ""
                                          ) : (
                                            <span className="font-weight-bold">
                                              {item.bank.kode + " "}-
                                              {" " + item.bank.nama}
                                              &bull; {item.nomor_rekening}
                                            </span>
                                          )}
                                        </Grid.Col>
                                      );
                                    })}
                                </Grid.Row>
                              </Grid.Col>
                            </Grid.Row>
                          </Card.Body>
                        </Card>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row cards={true} title="Foto">
                      <Grid.Col width={12}>
                        <Card className="border-card">
                          <Card.Body>
                            <Grid.Row>
                              <Grid.Col
                                width={12}
                                className="align-items-center"
                              >
                                <span className="font-weight-bold font-20">
                                  Foto
                                </span>
                              </Grid.Col>
                              <Grid.Col width={12} className="mt-3">
                                <Grid.Row>
                                  {foto &&
                                    foto.map((item) => {
                                      return (
                                        <Foto
                                          jenis="kandidat-artis"
                                          imagePreview={imagePreview}
                                          setChange={setChange}
                                          data={item}
                                          setImagePreview={setImagePreview}
                                        ></Foto>
                                      );
                                    })}
                                </Grid.Row>
                              </Grid.Col>
                            </Grid.Row>
                          </Card.Body>
                        </Card>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row cards={true} title="Video">
                      <Grid.Col width={12}>
                        <Card className="border-card">
                          <Card.Body>
                            <Grid.Row>
                              <Grid.Col
                                width={12}
                                className="align-items-center"
                              >
                                <span className="font-weight-bold font-20">
                                  Video
                                </span>
                              </Grid.Col>
                              <Grid.Col width={12} className="mt-3">
                                <Grid.Row>
                                  {video &&
                                    video.map((item) => {
                                      return (
                                        <Video
                                          jenis="kandidat-artis"
                                          setChange={setChange}
                                          data={item}
                                        ></Video>
                                      );
                                    })}
                                </Grid.Row>
                              </Grid.Col>
                            </Grid.Row>
                          </Card.Body>
                        </Card>
                      </Grid.Col>
                    </Grid.Row>
                    {/* <Grid.Row cards={true} title="Kontrak">
                                            <Grid.Col width={12}>
                                                <Card className="border-card">
                                                    <Card.Body>
                                                        <Grid.Row>
                                                            <Grid.Col width={12} className="align-items-center">
                                                                <span className="font-weight-bold font-20">Kontrak</span>
                                                            </Grid.Col>
                                                            <Grid.Col width={12} className="mt-2">
                                                                <Card className="p-5 rounded-card">
                                                                    <Grid.Row>
                                                                        <Grid.Col width={12}>
                                                                            <div className="h5">Avengers : End Game</div>
                                                                        </Grid.Col>
                                                                        <Grid.Col width={2} className="mt-2 font-12">
                                                                            <div className="text-grey font-weight-bold font-12">Nomor Kontrak</div>
                                                                            <div>EA/1235123568125</div>
                                                                        </Grid.Col>
                                                                        <Grid.Col width={2} className="mt-2 font-12">
                                                                            <div className="text-grey font-weight-bold font-12">Jenis Kontrak</div>
                                                                            <div>Exclusive Artis</div>
                                                                        </Grid.Col>
                                                                        <Grid.Col width={2} className="mt-2 font-12">
                                                                            <div className="text-grey font-weight-bold font-12">Tanggal Kontrak</div>
                                                                            <div>23 Agustus 2022</div>
                                                                        </Grid.Col>
                                                                        <Grid.Col width={6} className="text-right">
                                                                            <Link to={"/artis/detail-kontrak"}>
                                                                                <Button variant="outline-danger" className="mr-3 ml-5" type="button">Lihat Detail</Button>
                                                                            </Link>
                                                                        </Grid.Col>
                                                                    </Grid.Row>   
                                                                </Card>
                                                            </Grid.Col>
                                                        </Grid.Row>    
                                                    </Card.Body>
                                                </Card>
                                            </Grid.Col>
                                        </Grid.Row> */}
                  </TabbedContainer>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
            <Grid.Col width="3">
              <Grid.Row>
                <Grid.Col md="12">
                  <AvailabilityCard data={dataValue}></AvailabilityCard>
                </Grid.Col>
                <Grid.Col md="12">
                  <CatatanCard data={dataValue} fetch={() => fetchData()} />
                </Grid.Col>
                {statePermission.permission.artis.tambah_jadwal_artis.active ===
                1 ? (
                  <Grid.Col md="12">
                    <JadwalCard id={prop.match.params.id}></JadwalCard>
                  </Grid.Col>
                ) : (
                  ""
                )}
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>

          {imagePreview.isOpen && (
            <Lightbox
              mainSrc={imagePreview.image}
              onCloseRequest={() =>
                setImagePreview({ image: "", isOpen: false })
              }
            />
          )}
        </>
      ) : (
        <Grid.Row>
          <Grid.Col width="12" className="text-center">
            Anda Tidak Memiliki Akses Detail Kandidat Artis
          </Grid.Col>
        </Grid.Row>
      )}
    </SiteWrapper>
  );
}

export default DetailArtis;
