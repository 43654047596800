import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Dropdown, Row, Col, Form } from "react-bootstrap";
import { FormTextInput } from "tabler-react";
import axios from "axios";
import { ContextAlert } from "../../../Context/Alert";

function FinalBagan(prop) {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useContext(ContextAlert);
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [year, setYear] = useState();

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  function onSubmit() {
    axios
      .post(
        `${urlMicroservice}talent/bagan/finalization/${prop.id}`,
        { year: year },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setMessage({
          type: "success",
          message: "Berhasil Menandai Bagan Sebagai Final",
          show: "d-block",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(function (e) {
        let message = e.response?.data?.message || e.message;
        if (e.response?.data?.errors) {
          const errors = e.response.data.errors;
          message = Object.keys(errors)
            .map((field) => `${errors[field].join(", ")}`)
            .join("; ");
        }

        setMessage({
          type: "danger",
          message: message,
          show: "d-block",
        });
      });
  }

  return (
    <>
      <Button variant="danger" onClick={handleShow}>
        <i className="bx bxs-check-circle"></i> Tandai sebagai Final
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tandai sebagai Final</Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-2">
          {/* Apakah Anda yakin ingin menandai bagan ini sebagai Final? */}
          <Row className="mb-0">
            <Col sm="12" className="mt-0">
              <Form.Group className="mb-0" controlId="nama">
                <Form.Label>
                  Tahun Judul <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Masukan Tahun"
                  onChange={(e) => setYear(e.target.value)}
                  value={year}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" type="button" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="danger" type="button" onClick={() => onSubmit()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FinalBagan;
