import React, {createContext, useState, useEffect} from "react";
import axios from 'axios';

let initialState = "";

const Permission = ({children}) => {
    const urlMicroservice   = process.env.REACT_APP_BE_DBTALENT_URL;
    const token = localStorage.getItem('token');
    const [state, dispatch] = useState(initialState);
    const loginUrl   = process.env.REACT_APP_LOGIN_TOKEN_URL;

    useEffect(() => {
        const userMenu = sessionStorage.getItem("userMenu");
        if (userMenu) {
            let storedData = JSON.parse(userMenu);
            initialState = storedData;
            dispatch(storedData)
        } else {
            axios.get(`${urlMicroservice}user/menu`,{ 
                headers: {
                    'Authorization' : `Bearer ${token}`, 
                    'Accept'        : 'application/json', 
                    'Content-Type'  : 'application/json'
                }
            })
            .then((res) =>{
                initialState = res.data.data;
                dispatch(initialState)
                const dataUserMenu = JSON.stringify(initialState);
                sessionStorage.setItem('userMenu', dataUserMenu);
            })
            .catch(e =>{
                var message = e.message;
                if (typeof e.response != "undefined") {
                    message = e.response.data.message;
                }
                if (e.response) {
                    if(e.response.status===401){
                        window.open(loginUrl,"_parent")
                    }
                }
            });
        }
    }, [])

    return (
        <ContextPermission.Provider value={state}>
            {initialState ? children : ""}
        </ContextPermission.Provider>
    )
};

export const ContextPermission = createContext(initialState);
export default Permission;