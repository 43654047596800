import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button } from "react-bootstrap";
import axios from "axios";
import capitalizeWords from "../utils/capitalizeWords";

const KarakterDropdown = ({ value, onChange, btnAdd = false, onKeyDown }) => {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [categories, setCategories] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    fetchKarakter();
  }, []);

  const fetchKarakter = () => {
    axios
      .get(`${urlMicroservice}talent/karakter`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data.data;
        const arrData = data.map((item) => ({
          label: capitalizeWords(item.nama_karakter),
          value: capitalizeWords(item.nama_karakter),
        }));

        setCategories(arrData);
      })
      .catch((e) => {
        console.error("Error fetching karakter:", e);
      });
  };

  const handleInputChange = (newValue) => {
    onChange(newValue);
    setSelectedOption(newValue);
  };

  const handleCreateOption = () => {
    if (selectedOption) {
      const data = JSON.stringify({
        nama_karakter: selectedOption,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${urlMicroservice}talent/karakter`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then(() => {
          fetchKarakter();
          setSelectedOption("");
        })
        .catch((error) => {
          console.error("Error creating karakter:", error);
        });
    }
  };

  const customNoOptionsMessage = () => (
    <div>
      <span>Tidak ada opsi tersedia.</span>
      {btnAdd && (
        <Button variant="link" onClick={handleCreateOption}>
          Tambah Opsi
        </Button>
      )}
    </div>
  );

  return (
    <div>
      <label>Karakter Wajah</label>
      <Select
        value={categories.find((option) => option.value === value) || null}
        onChange={(option) => handleInputChange(option ? option.value : "")}
        onInputChange={setSelectedOption}
        options={categories}
        noOptionsMessage={customNoOptionsMessage}
        isClearable
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default KarakterDropdown;
