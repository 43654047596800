import { API_CREATE } from "../Utils/api-list";
import ServerManager from "../Utils/server-manager";

export const GetArtisSp = (params, body, token, callback) => {
  const queryString = new URLSearchParams(params).toString();
  const options = {
    url: API_CREATE.GetArtisSp(queryString),
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  ServerManager().post(options, (res) => {
    callback(res);
  });
};

export const GetTalentProspect = (token, callback) => {
  const options = {
    url: API_CREATE.getTalentProspect(),
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  ServerManager().get(options, (res) => {
    callback(res);
  });
};

export const GetTalentProvinces = (token, callback) => {
  const options = {
    url: API_CREATE.getTalentProvinces(),
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  ServerManager().get(options, (res) => {
    callback(res);
  });
};
