import React, { useEffect, useState } from "react";
import { Modal, Form, Button, InputGroup, FormControl } from "react-bootstrap";
import { Card } from "tabler-react";
import axios from "axios";
import "./talentSinemart.css";
import KarakterDropdown from "../DropDown/KarakterDropdown";

function FilterCard(prop) {
  const [initialDate, setInitialDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [{ startDate, endDate }, setDate] = useState(initialDate);
  const [dataCastingTeam, setDataCastingTeam] = useState([]);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenTalent, setIsOpenTalent] = useState(true);
  const token = localStorage.getItem("token");
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;

  useEffect(() => {
    const handleCtrlF = (event) => {
      if (event.ctrlKey && event.key === "f") {
        event.preventDefault();
        setIsOpenFilter(true);
      }
    };

    window.addEventListener("keydown", handleCtrlF);

    return () => {
      window.removeEventListener("keydown", handleCtrlF);
    };
  }, []);

  function handleChange(evt) {
    let value = evt.target.value;
    if (
      evt.target.name === "gender" ||
      evt.target.name === "peran" ||
      evt.target.name === "category_casting"
    ) {
      var oldValue = prop.filter[evt.target.name];
      if (evt.target.checked) {
        oldValue.push(parseInt(value));
      } else {
        var index = oldValue.indexOf(parseInt(value));
        if (index !== -1) {
          oldValue.splice(index, 1);
        }
      }
      value = oldValue;
    } else if (evt.target.name === "eksklusif") {
      if (evt.target.id === "eksklusif" && evt.target.checked) {
        setIsOpenTalent(true);
      } else if (evt.target.id === "eksklusif" && !evt.target.checked) {
        setIsOpenTalent(false);
      }

      var oldValue = prop.filter[evt.target.name];
      if (
        (evt.target.checked && value == "1" && oldValue == "0") ||
        (evt.target.checked && value == "0" && oldValue == "1")
      ) {
        value = "";
      } else if (!evt.target.checked && value == "1") {
        value = "0";
      } else if (!evt.target.checked && value == "0") {
        value = "1";
        setIsOpenTalent(true);
      }
    } else if (evt.target.name === "available") {
      var oldValue = prop.filter[evt.target.name];
      if (
        (evt.target.checked && value == "1" && oldValue == "0") ||
        (evt.target.checked && value == "0" && oldValue == "1")
      ) {
        value = "";
      } else if (!evt.target.checked && value == "1") {
        value = "0";
      } else if (!evt.target.checked && value == "0") {
        value = "1";
      }
    }
    prop.setFilter({
      ...prop.filter,
      tampil_filter: false,
      filter_id: "",
      [evt.target.name]: value,
    });
  }

  function hapusSemua() {
    prop.setFilter({
      nama_artis: "",
      gender: [1, 2],
      usia_min: "",
      usia_maks: "",
      tinggi_min: "",
      tinggi_maks: "",
      berat_min: "",
      berat_maks: "",
      peran: [],
      nilai_casting_min: "",
      nilai_casting_maks: "",
      talent_prospect: [],
      judul_sinetron: "",
      eksklusif: "",
      penerimaan_artis_dari_tanggal: "",
      penerimaan_artis_sampai_tanggal: "",
      filter_id: "",
      tampil_filter: false,
      submit: true,
      talent_tim: "",
      management: "",
      category_casting: [],
      karakter: "",
    });
  }

  const handleClick = () => {
    prop.setFilter({
      ...prop.filter,
      tampil_filter: true,
      submit: true,
    });
    setIsOpenFilter(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleClick();
    } else if (event.key === "Escape") {
      hapusSemua();
    }
  };

  useEffect(() => {
    axios
      .get(`${urlMicroservice}management/team-talent`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        let datas = [];

        if (Array.isArray(res.data.data) && res.data.data.length > 0) {
          datas = res.data.data.map((items) => ({
            value: items.talent_team_name,
            label: items.talent_team_name,
          }));
        }

        setDataCastingTeam(datas);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  }, []);

  return (
    <>
      <Button
        variant="danger"
        className="__button-seacrh"
        type="button"
        onClick={() => setIsOpenFilter(true)}
      >
        Cari
      </Button>
      <Modal
        show={isOpenFilter}
        size="lg"
        onHide={() => setIsOpenFilter(false)}
      >
        <Modal.Body>
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <h4 className="mb-0 __text-bold">Filter</h4>
            </Card.Header>
            <Form>
              <Card.Body>
                <Form.Group className="mb-4" controlId="nama">
                  <Form.Label>
                    <span className="__text-bold">Nama Artis</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukkan nama artis"
                    name="nama_artis"
                    value={prop.filter.nama_artis}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="management">
                  <Form.Label className="text-grey">
                    <span className="__text-bold">Management</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukkan nama management"
                    name="management"
                    value={prop.filter.management}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>

                {/* karakter */}
                <KarakterDropdown
                  value={prop.filter.karakter}
                  onChange={(value) =>
                    prop.setFilter({
                      ...prop.filter,
                      karakter: value,
                    })
                  }
                  btnAdd={false}
                  onKeyDown={handleKeyDown}
                />
              </Card.Body>

              <Card.Body className="__eklusif-gender">
                <Form.Group className="mb-4 __eklusif" controlId="eklusif">
                  <Form.Label className="text-grey">
                    <span className="__text-bold">Eksklusif</span>
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    id="eksklusif"
                    label="Eksklusif"
                    className="mb-2"
                    name="eksklusif"
                    value={1}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    checked={
                      prop.filter.eksklusif == "1"
                        ? true
                        : !prop.filter.eksklusif
                        ? true
                        : false
                    }
                  />
                  <Form.Check
                    type="checkbox"
                    id="not_eksklusif"
                    label="Non Eksklusif"
                    className="mb-2"
                    name="eksklusif"
                    value={0}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    checked={
                      prop.filter.eksklusif == "0"
                        ? true
                        : !prop.filter.eksklusif
                        ? true
                        : false
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4 __gender" controlId="nama">
                  <Form.Label className="text-grey">
                    <span className="__text-bold">Gender</span>
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    id="male"
                    label="Laki-laki"
                    className="mb-2 gender"
                    name="gender"
                    value={1}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    checked={prop.filter.gender.includes(1) ? true : false}
                  />
                  <Form.Check
                    type="checkbox"
                    id="female"
                    label="Perempuan"
                    className="gender"
                    name="gender"
                    value={2}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    checked={prop.filter.gender.includes(2) ? true : false}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-4 __available"
                  controlId="availability"
                >
                  <Form.Label className="text-grey">
                    <span className="__text-bold">Availability</span>
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    id="available"
                    label="Available"
                    className="mb-2"
                    name="available"
                    value={1}
                    onChange={handleChange}
                    checked={
                      prop.filter.available == "1"
                        ? true
                        : !prop.filter.available
                        ? true
                        : false
                    }
                    onKeyDown={handleKeyDown}
                  />
                  <Form.Check
                    type="checkbox"
                    id="not_available"
                    label="Not Available"
                    className="mb-2"
                    name="available"
                    value={0}
                    onChange={handleChange}
                    checked={
                      prop.filter.available == "0"
                        ? true
                        : !prop.filter.available
                        ? true
                        : false
                    }
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>

                <Form.Group className="mb-4" controlId="peran">
                  <Form.Label className="text-grey">
                    <span className="__text-bold">Peran</span>
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    id="protagonis"
                    label="Protagonis"
                    className="mb-2"
                    name="peran"
                    value={1}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    checked={prop.filter.peran.includes(1) ? true : false}
                  />
                  <Form.Check
                    type="checkbox"
                    id="antagonis"
                    label="Antagonis"
                    className="mb-2"
                    name="peran"
                    value={2}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    checked={prop.filter.peran.includes(2) ? true : false}
                  />
                </Form.Group>
              </Card.Body>

              {/* casting tim judul film */}
              <Card.Body>
                {isOpenTalent && (
                  <Form.Group className="mb-4" label="Casting Tim" width={6}>
                    <Form.Label className="text-grey">
                      <span className="__text-bold">Casting Tim</span>
                    </Form.Label>
                    <Form.Select
                      className="__casting-select"
                      name="talent_tim"
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    >
                      <option value={""}>- Pilih Casting -</option>
                      {dataCastingTeam.length > 0 &&
                        dataCastingTeam.map((items, key) => (
                          <option
                            key={key}
                            value={items.value}
                            selected={items.value == prop.filter.talent_tim}
                          >
                            {items.label}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                )}

                <Form.Group className="mb-4" controlId="judul">
                  <Form.Label className="text-grey">
                    <span className="__text-bold">Judul Film</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukkan judul film"
                    name="judul_sinetron"
                    value={prop.filter.judul_sinetron}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>

                <Form.Group className="mb-4" controlId="category">
                  <Form.Label className="text-grey">
                    <span className="__text-bold">Categori Casting</span>
                  </Form.Label>
                  <div className="__category-casting">
                    {prop.categoryCasting.length > 0 &&
                      prop.categoryCasting.map((items, key) => (
                        <Form.Check
                          key={key}
                          type="checkbox"
                          id={items.value}
                          label={items.label}
                          name="category_casting"
                          value={items.value}
                          onChange={handleChange}
                          onKeyDown={handleKeyDown}
                          checked={
                            prop.filter.category_casting?.includes(items.value)
                              ? true
                              : false
                          }
                        />
                      ))}
                  </div>
                </Form.Group>
              </Card.Body>

              {/* usia tinggi berat badan */}
              <Card.Body className="__usia-tinggi-berat">
                <Form.Group className="mb-4" controlId="usia">
                  <Form.Label className="text-grey">
                    <span className="__text-bold">Usia</span>
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Label className="my-auto w-25">Min.</Form.Label>
                    <FormControl
                      type="number"
                      min="1"
                      placeholder="Usia Minimum"
                      name="usia_min"
                      value={prop.filter.usia_min}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                    <InputGroup.Text>Tahun</InputGroup.Text>
                  </InputGroup>
                  <InputGroup className="mb-2">
                    <Form.Label className="my-auto w-25">Maks.</Form.Label>
                    <FormControl
                      type="number"
                      min="1"
                      placeholder="Usia Maksimum"
                      name="usia_maks"
                      value={prop.filter.usia_maks}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                    <InputGroup.Text>Tahun</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-4" controlId="tinggi">
                  <Form.Label className="text-grey">
                    <span className="__text-bold">Tinggi Badan</span>
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Label className="my-auto w-25">Min.</Form.Label>
                    <FormControl
                      type="number"
                      min="1"
                      placeholder="Tinggi Minimum"
                      name="tinggi_min"
                      value={prop.filter.tinggi_min}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                    <InputGroup.Text>cm</InputGroup.Text>
                  </InputGroup>
                  <InputGroup className="mb-2">
                    <Form.Label className="my-auto w-25">Maks.</Form.Label>
                    <FormControl
                      type="number"
                      min="1"
                      placeholder="Tinggi Maksimum"
                      name="tinggi_maks"
                      value={prop.filter.tinggi_maks}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                    <InputGroup.Text>cm</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-4" controlId="berat">
                  <Form.Label className="text-grey">
                    <span className="__text-bold">Berat Badan</span>
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Label className="my-auto w-25">Min.</Form.Label>
                    <FormControl
                      type="number"
                      min="1"
                      placeholder="Berat Minimum"
                      name="berat_min"
                      value={prop.filter.berat_min}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                    <InputGroup.Text>kg</InputGroup.Text>
                  </InputGroup>
                  <InputGroup className="mb-2">
                    <Form.Label className="my-auto w-25">Maks.</Form.Label>
                    <FormControl
                      type="number"
                      min="1"
                      placeholder="Berat Maksimum"
                      name="berat_maks"
                      value={prop.filter.berat_maks}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                    <InputGroup.Text>kg</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Card.Body>

              <Card.Footer className="d-flex justify-content-center">
                <Button
                  variant="outline-danger"
                  className="mr-3 w-100"
                  type="button"
                  onClick={() => hapusSemua()}
                >
                  Reset
                </Button>
                <Button
                  variant="danger"
                  className="w-100"
                  type="button"
                  onClick={handleClick}
                  onKeyDown={handleKeyDown}
                >
                  Terapkan
                </Button>
              </Card.Footer>
            </Form>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FilterCard;
