import React, { useState, useEffect, useRef, useContext } from "react";
import { Modal, Button, Image } from "react-bootstrap";
import { Grid, Form, FormTextInput } from "tabler-react";
import axios from "axios";
import Loader from "react-loader-spinner";
import { ContextAlert } from "../../Context/Alert";

function TambahVideo(prop) {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [textButton, setTextButton] = useState("Pilih Video");
  const [imageUpload, setImageUpload] = useState({ src: "", type: "" });
  const inputReference = useRef(null);
  const [showInput, setShowInput] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useContext(ContextAlert);
  const [input, setInput] = useState({
    artist_id: prop.id,
    video: "",
    nama: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true), setInput({ ...input, video: "", nama: "" });
  };

  const fileUploadAction = () => {
    setShowInput(false);
    setInput({
      ...input,
      video: "",
    });
    inputReference.current.click();
  };

  const fileUploadInputChange = (e) => {
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    const type = e.target.files[0].type;
    setImageUpload({ src: objectUrl, type: type });
    setTextButton("Ganti Video");
    setDisabledButton(false);
    setShowInput(true);
    setInput({
      ...input,
      video: e.target.files[0],
    });
  };

  function handleChange(evt) {
    let value = evt.target.value;
    setInput({
      ...input,
      [evt.target.name]: value,
    });
  }

  function submit() {
    setDisabledButton(true);
    setLoader(true);
    const data = new FormData();
    data.append("artist_id", input.artist_id);
    data.append("video", input.video);
    data.append("nama", input.nama);

    axios
      .post(`${urlMicroservice}talent/${prop.jenis}/video/create`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setMessage({
          type: "success",
          message: `Berhasil menambahkan video`,
          show: "d-block",
        });
        prop.setChange(true);
        setLoader(false);
        handleClose();
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
        setLoader(false);
        setDisabledButton(false);
      });
  }

  useEffect(() => {
    if (show == true) {
      setImageUpload({ src: "", type: "" });
      setTextButton("Pilih Video");
      setDisabledButton(true);
      setShowInput(false);
    }
  }, [show]);

  return (
    <>
      <Button
        variant="outline-danger"
        className="ml-auto my-auto"
        type="button"
        onClick={handleShow}
      >
        <i class="bx bx-plus"></i> Tambah Video
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Form action="/test" method="POST" enctype="multipart/form-data">
            <Grid.Row className="mb-5 mt-3">
              <Grid.Col width="12">
                <h5 className="font-weight-bold">Tambah Video</h5>
              </Grid.Col>
              <Grid.Col width="12" className="mt-5">
                <div className="custom-input-file">
                  <input
                    name="video"
                    ref={inputReference}
                    onChange={fileUploadInputChange}
                    type="file"
                    accept="video/mp4,video/avi,video/mkv"
                  />
                  <Button
                    variant="outline-danger"
                    className="px-2 mr-3"
                    onClick={fileUploadAction}
                  >
                    {textButton}
                  </Button>
                  <span>Pilih video dengan format .mp4 / .avi / .mkv</span>
                </div>
                <div className="text-center">
                  <Loader
                    visible={loader}
                    type="ThreeDots"
                    color="#ED1C24"
                    height="50"
                    width="100"
                  />
                </div>
              </Grid.Col>
              {showInput ? (
                <Grid.Col width="12" className="mt-5">
                  <div className="image-upload">
                    <video style={{ width: "100%", height: "100%" }} controls>
                      <source
                        src={imageUpload.src}
                        type={imageUpload.type}
                      ></source>
                    </video>
                  </div>
                  <div className="mt-2">
                    <FormTextInput
                      name="nama"
                      placeholder="Masukkan nama video"
                      value={input.nama}
                      onChange={handleChange}
                    />
                  </div>
                </Grid.Col>
              ) : null}
            </Grid.Row>
            <Grid.Row className="mb-2">
              <Grid.Col width="12" className="text-right">
                <Button
                  variant="outline-danger"
                  className="px-5 mr-3"
                  onClick={handleClose}
                >
                  Batal
                </Button>
                <Button
                  variant="danger"
                  className="px-5"
                  onClick={() => submit()}
                  disabled={disabledButton}
                >
                  Tambah Video
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TambahVideo;
