import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Form, Image } from "react-bootstrap";
import { Grid, FormTextInput } from "tabler-react";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import axios from "axios";
import "./Calendar.css";
import { ContextAlert } from "../../Context/Alert";

function UbahJadwal(props) {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [message, setMessage] = useContext(ContextAlert);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { RangePicker } = DatePicker;

  const [input, setInput] = useState({
    id: "",
    artist_id: "",
    jadwal: "",
    dari_tanggal: "",
    dari_jam: "",
    sampai_tanggal: "",
    sampai_jam: "",
    all_day: false,
  });

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  function handleDatePickerStart(evt) {
    if (input.sampai_tanggal) {
      if (moment(input.sampai_tanggal, "DD/MM/YYYY") <= evt) {
        setInput({
          ...input,
          dari_tanggal: evt,
          sampai_tanggal: evt,
          sampai_jam: input.dari_jam,
        });
      } else {
        setInput({
          ...input,
          dari_tanggal: evt,
        });
      }
    } else {
      setInput({
        ...input,
        dari_tanggal: evt,
      });
    }
  }

  function handleDatePickerEnd(evt) {
    setInput({
      ...input,
      sampai_tanggal: evt,
    });
  }

  function disabledDate(current) {
    return (
      current && current.valueOf() < moment(input.dari_tanggal, "DD/MM:YYYY")
    );
  }

  function handleTimePickerStart(evt) {
    if (input.sampai_tanggal) {
      if (moment(input.sampai_tanggal, "DD/MM/YYYY") <= evt) {
        setInput({
          ...input,
          dari_jam: evt,
          sampai_jam: evt,
        });
      } else {
        setInput({
          ...input,
          dari_jam: evt,
        });
      }
    } else {
      setInput({
        ...input,
        dari_jam: evt,
      });
    }
  }

  function handleTimePickerEnd(evt) {
    setInput({
      ...input,
      sampai_jam: evt,
    });
  }

  function disabledTime(current) {
    if (
      input.dari_jam &&
      input.sampai_tanggal &&
      input.dari_tanggal &&
      moment(input.sampai_tanggal.format("YYYY-MM-DD")).isSameOrBefore(
        moment(input.dari_tanggal.format("YYYY-MM-DD"))
      )
    ) {
      var jam = moment(input.dari_jam, "HH:mm").format("HH");
      var menit = moment(input.dari_jam, "HH:mm").format("mm");
      return {
        disabledHours: () => range(0, jam),
        disabledMinutes: () => range(0, menit),
      };
    } else {
      return {
        disabledHours: () => null,
        disabledMinutes: () => null,
      };
    }
  }

  function handleChange(evt) {
    let value = evt.target.value;
    if (evt.target.name === "all_day") {
      if (evt.target.checked) {
        value = true;
      } else {
        value = false;
      }
    }
    setInput({
      ...input,
      [evt.target.name]: value,
    });
  }

  useEffect(() => {
    let dari_tanggal = moment(props.data.dari_tanggal, "DD/MM/YYYY HH:mm");
    let sampai_tanggal = moment(props.data.sampai_tanggal, "DD/MM/YYYY HH:mm");
    setInput({
      id: props.data.id,
      artist_id: props.data.artist_id,
      jadwal: props.data.jadwal,
      dari_tanggal: dari_tanggal,
      sampai_tanggal: sampai_tanggal,
      dari_jam: dari_tanggal,
      sampai_jam: sampai_tanggal,
      all_day: props.data.all_day,
    });
  }, [props.data]);

  function submit() {
    if (input.dari_tanggal && input.sampai_tanggal && input.jadwal) {
      var startDate = input.dari_tanggal.format("DD/MM/YYYY");
      var endDate = input.sampai_tanggal.format("DD/MM/YYYY");
      var startTime = "00:00";
      var endTime = "00:00";

      if (input.all_day == false) {
        if (!input.dari_jam || !input.sampai_jam) {
          setMessage({
            type: "danger",
            message: `Jam diperlukan`,
            show: "d-block",
          });
          return false;
        } else {
          startTime = input.dari_jam.format("HH:mm");
          endTime = input.sampai_jam.format("HH:mm");
        }
      }
      const data = {
        id: input.id,
        artist_id: input.artist_id,
        jadwal: input.jadwal,
        dari_tanggal: startDate + " " + startTime,
        sampai_tanggal: endDate + " " + endTime,
        all_day:
          input.all_day == 1
            ? true
            : input.all_day == 0
            ? false
            : input.all_day,
      };
      console.log(data);
      axios
        .post(`${urlMicroservice}talent/artis/jadwal/update`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setMessage({
            type: "success",
            message: `Berhasil mengubah jadwal`,
            show: "d-block",
          });

          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((e) => {
          var message = e.response.data.message;
          if (!message) {
            message = e.message;
          }
          setMessage({
            type: "danger",
            message: JSON.stringify(message),
            show: "d-block",
          });
        });
    } else {
      setMessage({
        type: "danger",
        message: `Nama Jadwal dan Tanggal diperlukan`,
        show: "d-block",
      });
      return false;
    }
  }

  return (
    <>
      <Button
        variant="outline-warning"
        className="mr-1 btn-jadwal"
        onClick={handleShow}
      >
        <i className="bx bxs-edit-alt" />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ubah Jadwal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-2">
          <Grid.Row>
            <Grid.Col width="12">
              <FormTextInput
                name="jadwal"
                label="Nama Jadwal"
                placeholder="Masukkan nama jadwal"
                value={input.jadwal}
                onChange={handleChange}
                autoComplete="off"
              />
            </Grid.Col>
            <Grid.Col width="12" className="my-2">
              <div className="form-label">Tanggal</div>
              <div className="d-flex align-items-center">
                <DatePicker
                  format={"DD/MM/YYYY"}
                  onChange={(date) => handleDatePickerStart(date)}
                  value={input.dari_tanggal}
                  suffixIcon={false}
                  className="datepicker mr-1"
                  allowClear={false}
                  placeholder="DD/MM/YYYY"
                />
                {input.all_day == false ? (
                  <TimePicker
                    suffixIcon={false}
                    format={"HH:mm"}
                    className="timepicker"
                    allowClear={false}
                    placeholder="00:00"
                    onChange={(date) => handleTimePickerStart(date)}
                    value={input.dari_jam}
                  />
                ) : (
                  ""
                )}
                <div className="mx-4">-</div>
                <DatePicker
                  format={"DD/MM/YYYY"}
                  onChange={(date) => handleDatePickerEnd(date)}
                  value={input.sampai_tanggal}
                  suffixIcon={false}
                  className="datepicker mr-1"
                  allowClear={false}
                  placeholder="DD/MM/YYYY"
                  disabledDate={disabledDate}
                />
                {input.all_day == false ? (
                  <TimePicker
                    suffixIcon={false}
                    format={"HH:mm"}
                    className="timepicker"
                    allowClear={false}
                    placeholder="00:00"
                    onChange={(date) => handleTimePickerEnd(date)}
                    value={input.sampai_jam}
                    disabledTime={disabledTime}
                  />
                ) : (
                  ""
                )}
              </div>
            </Grid.Col>
            <Grid.Col width="12" className="mt-4">
              <Form.Group controlId="nama">
                <Form.Check
                  type="checkbox"
                  id="all_day"
                  label="All Day"
                  name="all_day"
                  onChange={handleChange}
                  checked={input.all_day}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="danger" onClick={() => submit()}>
            Ubah Jadwal
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UbahJadwal;
