import React, { useState } from "react";
import { Modal, Button, Row, Col, Form, Image } from "react-bootstrap";
import axios from "axios";
import { AiFillEdit } from "react-icons/ai";
import AlertCustom from "../../../Components/Modal/AlertCustomComponent";
import Judul from "../Judul";

function EditJudul(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [message, setMessage] = useState({
    type: "",
    message: "",
    show: "d-none",
  });
  const [disable, setDisable] = useState(false);
  const loginUrl = process.env.REACT_APP_LOGIN_TOKEN_URL;
  const [urlImage, setUrlImage] = useState(
    process.env.REACT_APP_FRONTEND_URL + "demo/imgjudul.png"
  );
  const [fileImage, setFileImage] = useState("");
  const [inputJudul, setInputJudul] = useState("");
  const [inputJudulAwal, setInputJudulAwal] = useState("");
  const [inputKategori, setInputKategori] = useState("");
  const [inputGroup, setInputGroup] = useState("");
  const [inputSinopsis, setInputSinopsis] = useState("");
  const [inputSutradara, setInputSutradara] = useState("");
  const [inputSutradaraId, setInputSutradaraId] = useState("");
  const [inputProduser, setInputProduser] = useState("");
  const [inputProduserId, setInputProduserId] = useState("");
  const [inputSkenario, setInputSkenario] = useState("");
  const [inputSkenarioId, setInputSkenarioId] = useState("");
  const [inputCastingTeam, setInputCastingTeam] = useState("");
  const [inputCastingTeamId, setInputCastingTeamId] = useState("");
  const [inputCompanyId, setInputCompanyId] = useState("");
  const [inputStatus, setInputStatus] = useState("");
  const [erpId, setErpId] = useState("");
  const hiddenFileInput = React.useRef(null);
  const [errorImage, setErrorImage] = useState("none");
  const [errorJudul, setErrorJudul] = useState("none");
  const [errorKategori, setErrorKategori] = useState("none");
  const [errorGrup, setErrorGrup] = useState("none");
  const [errorSinopsis, setErrorSinopsis] = useState("none");
  const [errorSutradara, setErrorSutradara] = useState("none");
  const [errorProduser, setErrorProduser] = useState("none");
  const [errorSkenario, setErrorSkenario] = useState("none");
  const [errorCastingTeam, setErrorCastingTeam] = useState("none");
  const [errorCompanyId, setErrorCompanyId] = useState("none");
  const [errorStatus, setErrorStatus] = useState("none");
  const token = localStorage.getItem("token");
  const [produserErp, setproduserErp] = useState(" ");
  const [castingTeamErp, setCastingTeamErp] = useState(" ");
  const [status, setStatus] = useState("");

  const getDataEdit = () => {
    setDisable(true);
    axios
      .get(
        process.env.REACT_APP_BE_DBTALENT_URL + `talent/title/edit/` + props.id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setStatus(res.data.data.title.status);
        setUrlImage(res.data.data.title.title_cover);
        setInputJudul(res.data.data.title.title);
        setInputJudulAwal(res.data.data.title.title_old);
        setInputSinopsis(res.data.data.title.synopsis);
        setInputKategori(res.data.data.title.categories[0].id);
        setInputGroup(res.data.data.title.groups[0].id);
        setInputSutradara(res.data.data.title.director);
        setInputProduser(res.data.data.title.producer);
        setInputSkenario(res.data.data.title.writer);
        setInputCastingTeam(res.data.data.title.casting_team);
        setInputSutradaraId(parseInt(res.data.data.title.director_id, 10));
        setInputProduserId(parseInt(res.data.data.title.producer_id, 10));
        setInputSkenarioId(parseInt(res.data.data.title.writer_id, 10));
        setInputCastingTeamId(
          parseInt(res.data.data.title.casting_team_id, 10)
        );
        setInputCompanyId(res.data.data.title.company_id);
        setInputStatus(res.data.data.title.status);
        setErpId(res.data.data.title.erp_id);
        setproduserErp(
          props.produser.filter(
            (items) => items.id == res.data.data.title.producer_id
          )[0]?.id_user_erp ?? " "
        );
        setCastingTeamErp(
          props.castingTeam.filter(
            (items) => items.id == res.data.data.title.casting_team_id
          )[0]?.id_user_erp ?? " "
        );
        setDisable(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  };

  const submitEditJudul = () => {
    const data = new FormData();
    if (fileImage !== "") {
      data.append("title_cover", fileImage);
    }
    data.append("title", inputJudul);
    data.append("producer", capitalizeWords(inputProduser));
    data.append("producer_id", inputProduserId);
    data.append("director", capitalizeWords(inputSutradara));
    data.append("director_id", inputSutradaraId);
    data.append("writer", capitalizeWords(inputSkenario));
    data.append("writer_id", inputSkenarioId);
    data.append("synopsis", inputSinopsis);
    data.append("category_id", inputKategori);
    data.append("group_id", inputGroup);
    data.append("company_id", inputCompanyId);
    data.append("status", inputStatus);
    data.append("erp_id", erpId);
    data.append("produser_erp", produserErp);
    data.append("casting_team_erp", castingTeamErp);
    data.append("casting_team", inputCastingTeam);
    data.append("casting_team_id", inputCastingTeamId);

    // if(fileImage === ''){
    //   setErrorImage('inline')
    // }else
    if (inputJudul === "") {
      setErrorJudul("inline");
    } else if (inputKategori === "") {
      setErrorKategori("inline");
    } else if (inputGroup === "") {
      setErrorGrup("inline");
    } else if (inputSinopsis === "") {
      setErrorSinopsis("inline");
    } else if (inputSutradara === "") {
      setErrorSutradara("inline");
    } else if (inputProduser === "") {
      setErrorProduser("inline");
    } else if (inputSkenario === "") {
      setErrorSkenario("inline");
    } else if (inputCastingTeam === "") {
      setErrorCastingTeam("inline");
    } else if (inputCompanyId === null || inputCompanyId === "null") {
      setErrorCompanyId("inline");
    } else if (inputStatus === "") {
      setErrorStatus("inline");
    } else {
      setDisable(true);

      axios
        .post(
          process.env.REACT_APP_BE_DBTALENT_URL +
            `talent/title/update/` +
            props.id,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setMessage({
            type: "success",
            message: "Berhasil Mengubah Judul",
            show: "d-block",
          });

          handleClose();

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          setDisable(false);
          var message = error.message;
          if (typeof error.response != "undefined") {
            message = error.response.data.message;
          }
          setMessage({
            type: "danger",
            message: Object.values(message)[0][0],
            show: "d-block",
          });

          if (error.response) {
            if (error.response.status === 401) {
              window.open(loginUrl, "_parent");
            }
          }
        });
    }
  };

  const changeInputJudul = (e) => {
    let val = e.target.value;
    setInputJudul(val);
    if (val !== "") {
      setErrorJudul("none");
    }
  };
  const changeInputKategori = (e) => {
    let val = e.target.value;
    setInputKategori(val);
    if (val !== "") {
      setErrorKategori("none");
    }
  };
  const changeInputGroup = (e) => {
    let val = e.target.value;
    setInputGroup(val);
    if (val !== "") {
      setErrorGrup("none");
    }
  };
  const changeInputSinopsis = (e) => {
    let val = e.target.value;
    setInputSinopsis(val);
    if (val !== "") {
      setErrorSinopsis("none");
    }
  };
  const changeInputSutradara = (e) => {
    let val = e.target.value;
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("valuea");

    setInputSutradara(option);
    setInputSutradaraId(parseInt(val, 10));

    if (val !== "") {
      setErrorSinopsis("none");
    }
  };
  const changeInputProduser = (e) => {
    let val = e.target.value;
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("valuea");
    const erpId = el.getAttribute("valueb");

    setInputProduser(option);
    setInputProduserId(parseInt(val, 10));
    setproduserErp(erpId);

    if (val !== "") {
      setErrorProduser("none");
    }
  };
  const changeInputSkenario = (e) => {
    let val = e.target.value;
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("valuea");

    setInputSkenario(option);
    setInputSkenarioId(parseInt(val, 10));

    if (val !== "") {
      setErrorSkenario("none");
    }
  };
  const changeInputCastingTeam = (e) => {
    let val = e.target.value;
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("valuea");
    const erpId = el.getAttribute("valueb");

    setInputCastingTeam(option);
    setInputCastingTeamId(parseInt(val, 10));
    setCastingTeamErp(erpId);

    if (val !== "") {
      setErrorSkenario("none");
    }
  };
  const klikAmbilFoto = (e) => {
    hiddenFileInput.current.click();
  };
  const ambilFoto = (e) => {
    setUrlImage(URL.createObjectURL(e.target.files[0]));
    if (e.target.files[0]) {
      setFileImage(e.target.files[0]);
      setErrorImage("none");
    }
  };

  const changeInputCompany = (e) => {
    let val = e.target.value;

    if (val !== "") {
      setInputCompanyId(val);
      setErrorCompanyId("none");
    }
  };

  const changeInputStatus = (e) => {
    let val = e.target.value;
    if (val !== "") {
      setInputStatus(val);
      setErrorStatus("none");
    }
  };

  function capitalizeWords(str) {
    return str.toLowerCase().replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  function statusJudul(status) {
    const statusJudul = [
      { value: "Perencanaan" },
      { value: "Persiapan" },
      { value: "Sedang Berjalan" },
      { value: "Selesai" },
    ];

    const index = statusJudul.findIndex(
      (item) => item.value.toLowerCase() === status.toLowerCase()
    );

    if (index === -1) {
      return [];
    }

    const filteredStatusJudul = statusJudul.slice(index);

    return filteredStatusJudul.map((item) => (
      <option key={item.value} value={item.value}>
        {item.value}
      </option>
    ));
  }

  return (
    <>
      <Button
        variant="light"
        className="mr-1 d-flex justify-content-between align-items-center"
        onClick={() => {
          handleShow();
          getDataEdit();
        }}
      >
        <AiFillEdit className="mr-2" size={16} />
        Edit
      </Button>
      <AlertCustom
        setMessage={setMessage}
        showError={message.show}
        message={message.message}
        type={message.type}
      ></AlertCustom>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="mx-4">
          <Modal.Title>Edit Judul</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-4">
          <Form>
            <Form.Group className="mb-4" controlId="img">
              <Form.Label>Cover Judul</Form.Label>
              <Image
                src={urlImage}
                style={{ height: "150px", width: "120px" }}
              />
              <Form.Control
                type="file"
                style={{ display: "none" }}
                ref={hiddenFileInput}
                onChange={ambilFoto}
              />
              <Button
                variant="outline-danger"
                className="ml-3"
                onClick={klikAmbilFoto}
              >
                Pilih Cover
              </Button>
              <br />
              <p className="text-danger" style={{ display: errorImage }}>
                * cover tidak boleh kosong
              </p>
            </Form.Group>

            {inputJudulAwal &&
              inputJudulAwal?.toLowerCase() != inputJudul?.toLowerCase() && (
                <Form.Group className="mb-4" controlId="inputJudulAwal">
                  <Form.Label>Judul Awal</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={inputJudulAwal}
                    disabled
                  />
                </Form.Group>
              )}

            <Form.Group className="mb-4" controlId="Judul">
              <Form.Label>Judul</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukan Judul Lengkap"
                onChange={changeInputJudul}
                value={inputJudul}
              />
              <span className="text-danger" style={{ display: errorJudul }}>
                * judul tidak boleh kosong
              </span>
            </Form.Group>
            <Form.Group className="mb-4" controlId="role">
              <Form.Label>Kategori</Form.Label>
              <Form.Control
                as="select"
                disabled={status != "Perencanaan"}
                onChange={changeInputKategori}
                value={inputKategori}
              >
                <option>Pilih Kategori</option>
                {props.title &&
                  props.title.categories.map((item, i) => (
                    <option key={item.id} value={item.id}>
                      {item.category_name}
                    </option>
                  ))}
              </Form.Control>
              <span className="text-danger" style={{ display: errorKategori }}>
                * kategori tidak boleh kosong
              </span>
            </Form.Group>
            <Form.Group className="mb-4" controlId="Group">
              <Form.Label>Group</Form.Label>
              <Form.Control
                as="select"
                disabled={status != "Perencanaan"}
                onChange={changeInputGroup}
                value={inputGroup}
              >
                <option>Pilih Group</option>
                {props.title &&
                  props.title.groups.map((item, i) => (
                    <option key={item.id} value={item.id}>
                      {item.group_name}
                    </option>
                  ))}
              </Form.Control>
              <span className="text-danger" style={{ display: errorGrup }}>
                * group tidak boleh kosong
              </span>
            </Form.Group>
            <Form.Group className="mb-4" controlId="Sinopsis">
              <Form.Label>Sinopsis</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Masukan Sinopsis"
                onChange={changeInputSinopsis}
                value={inputSinopsis}
              />
              <span className="text-danger" style={{ display: errorSinopsis }}>
                * sinopsis tidak boleh kosong
              </span>
            </Form.Group>
            <Form.Group className="mb-4" controlId="Sutradara">
              <Form.Label>Sutradara</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => changeInputSutradara(e)}
                value={inputSutradaraId}
              >
                <option>Pilih Sutradara</option>
                {props.sutradara &&
                  props.sutradara.map((item, i) => (
                    <option key={item.id} value={item.id} valuea={item.name}>
                      {capitalizeWords(item.name)}
                    </option>
                  ))}
              </Form.Control>
              <span className="text-danger" style={{ display: errorSutradara }}>
                * sutradara tidak boleh kosong
              </span>
            </Form.Group>
            <Form.Group className="mb-4" controlId="Produser">
              <Form.Label>Produser</Form.Label>
              <Form.Control
                as="select"
                onChange={changeInputProduser}
                value={inputProduserId}
              >
                <option>Pilih Produser</option>
                {props.produser &&
                  props.produser.map((item, i) => (
                    <option
                      key={item.id}
                      value={item.id}
                      valuea={item.name}
                      valueb={item.id_user_erp}
                    >
                      {capitalizeWords(item.name)}
                    </option>
                  ))}
              </Form.Control>
              <span className="text-danger" style={{ display: errorProduser }}>
                * produser tidak boleh kosong
              </span>
            </Form.Group>
            <Form.Group className="mb-4" controlId="Produser">
              <Form.Label>Cerita & Skenario</Form.Label>
              <Form.Control
                as="select"
                onChange={changeInputSkenario}
                value={inputSkenarioId}
              >
                <option>Pilih Penulis</option>
                {props.skenario &&
                  props.skenario.map((item, i) => (
                    <option key={item.id} value={item.id} valuea={item.name}>
                      {capitalizeWords(item.name)}
                    </option>
                  ))}
              </Form.Control>
              <span className="text-danger" style={{ display: errorSkenario }}>
                * cerita & skenario tidak boleh kosong
              </span>
            </Form.Group>
            {/* penambahan casting team */}
            <Form.Group className="mb-4" controlId="Produser">
              <Form.Label>Casting Team</Form.Label>
              <Form.Control
                as="select"
                onChange={changeInputCastingTeam}
                value={inputCastingTeamId}
              >
                <option>Pilih Casting Team</option>
                {props.castingTeam &&
                  props.castingTeam.map((item, i) => (
                    <option
                      key={item.id}
                      value={item.id}
                      valuea={item.name}
                      valueb={item.id_user_erp}
                    >
                      {capitalizeWords(item.name)}
                    </option>
                  ))}
              </Form.Control>
              <span
                className="text-danger"
                style={{ display: errorCastingTeam }}
              >
                * Casting team tidak boleh kosong
              </span>
            </Form.Group>
            {/* penambahan company id da status */}
            <Form.Group className="mb-4" controlId="Produser">
              <Form.Label>Company</Form.Label>
              <Form.Control
                as="select"
                onChange={changeInputCompany}
                value={inputCompanyId}
              >
                <option value="">Pilih Company</option>
                <option value="001">Sinemart</option>
                <option value="002">ESSJAY</option>
              </Form.Control>
              <span className="text-danger" style={{ display: errorCompanyId }}>
                * Company tidak boleh kosong
              </span>
            </Form.Group>

            <Form.Group className="mb-4" controlId="Produser">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                onChange={changeInputStatus}
                value={inputStatus}
              >
                <option value="">Pilih Status</option>
                {statusJudul(status)}
              </Form.Control>
              <span className="text-danger" style={{ display: errorStatus }}>
                * Status tidak boleh kosong
              </span>
            </Form.Group>

            {/* penambahan company id da status */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="danger" disabled={disable} onClick={submitEditJudul}>
            Simpan Perubahan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditJudul;
