import React, {useState, useEffect} from 'react'
import {Row, Col, Form, Button, InputGroup, FormControl, Pagination, Container, Navbar,Image} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Card, Grid, Tab, TabbedHeader, TabbedContainer, Badge } from "tabler-react";
import {BsFillEyeFill, BsFillEyeSlashFill, BsXLg} from "react-icons/bs";
import axios from 'axios';
import AlertCustom from '../../Components/Modal/AlertCustomComponent';
import Loader from 'react-loader-spinner';

function Profile(){
    const [selectedTitle, setSelectedTitle]       = useState("Ubah Profile");
    const [urlImageAvatar]                        = useState(process.env.REACT_APP_FRONTEND_URL+"images/avatar.png");
    const [typePassword, setTypePassword]         = useState('password');
    const [typeNewPassword, setTypeNewPassword]   = useState('password');

    const [loader, setLoader]                     = useState(true);
    const token                                   = localStorage.getItem('token');
    const [nama, setNama]                         = useState('');
    const [email, setEmail]                       = useState('');
    const [password, setPassword]                 = useState('');
    const [passwordConfirm, setPasswordConfirm]   = useState('');
    const [urlImage, setUrlImage]                 = useState(process.env.REACT_APP_FRONTEND_URL+"images/avatar.png");
    const [fileImage, setFileImage]               = useState('');
    const hiddenFileInput                         = React.useRef(null);
    const [message, setMessage]                   = useState({type:'',message:'', show:'d-none'});
    const [disable, setDisable]                   = useState(false);
    const [displayPassword, setDisplayPassword]   = useState('none');
    const [displayConfirm, setDisplayConfirm]     = useState('none');
    const loginUrl                                = process.env.REACT_APP_LOGIN_TOKEN_URL;

    function tabClick(params) {
        setSelectedTitle(params);
    }

    const changeTypePassword = () =>{
        setTypePassword(typePassword === "password" ? "text" : "password");
    }
    const changeTypeNewPassword = () =>{
        setTypeNewPassword(typeNewPassword === "password" ? "text" : "password");
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_BE_DBTALENT_URL+`user/profile`,
        { 
          headers: {
              'Authorization' : `Bearer ${token}`, 
              'Accept'        : 'application/json', 
              'Content-Type'  : 'application/json'
          }
        })
        .then((res) =>{
            setNama(res.data.data.name);
            setEmail(res.data.data.email);
            if(res.data.data.image){
                setUrlImage(res.data.data.image);
                localStorage.setItem('image', res.data.data.image);
            }
            localStorage.setItem('username', res.data.data.name)
            setLoader(false)
        })
        .catch(error =>{
            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })
    
    }, [token]);

    const ubahProfile = () => {
        const data = new FormData();
        data.append('name', nama);
        data.append('email', email);
        data.append('image', fileImage);

        setDisable(true);
        axios.post(process.env.REACT_APP_BE_DBTALENT_URL+`user/profile/update`, data,
        { 
            headers: {
                'Authorization' : `Bearer ${token}`, 
                'Accept'        : 'application/json', 
                'Content-Type'  : 'application/json'
            }
        })
        .then((res) =>{
            setMessage({
            type:'success',
            message:"Berhasil Mengubah Profile",
            show:'d-block'
            });
            setTimeout(() => {
            window.location.reload();
            }, 1000);
        })
        .catch(error =>{
            setDisable(false);
            var message = error.message;
            if(typeof error.response != "undefined"){
                message = error.response.data.message;
            }
            setMessage({
                type:'danger',
                message:message,
                show:'d-block'
            });

            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })
    }
    
    const ubahPassword = () => {
        const data = new FormData();
        data.append('password', password);
        data.append('password_confirmation', passwordConfirm);

        setDisable(true);
        axios.post(process.env.REACT_APP_BE_DBTALENT_URL+`user/profile/update`, data,
        { 
            headers: {
                'Authorization' : `Bearer ${token}`, 
                'Accept'        : 'application/json', 
                'Content-Type'  : 'application/json'
            }
        })
        .then((res) =>{
            setMessage({
            type:'success',
            message:"Berhasil Mengubah Password",
            show:'d-block'
            });
            setTimeout(() => {
            window.location.reload();
            }, 1000);
        })
        .catch(error =>{
            setDisable(false);
            var message = error.message;
            if(typeof error.response != "undefined"){
                message = error.response.data.message;
            }
            setMessage({
                type:'danger',
                message:message,
                show:'d-block'
            });

            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })
    }

    const klikAmbilFoto = (e) =>{
        hiddenFileInput.current.click();
    }
    const ambilFoto = (e) =>{
      setUrlImage(URL.createObjectURL(e.target.files[0]));
      if(e.target.files[0]){
        setFileImage(e.target.files[0]);
      }
    }

    const changeInputNama = (e) => {
        let val = e.target.value;
        setNama(val);
    }
    const changeInputEmail = (e) => {
        let val = e.target.value;
        setEmail(val);
    }
    const changeInputPassword = (e) => {
        let val = e.target.value;
        if((passwordConfirm !== '') && (passwordConfirm !== val)){
            setDisplayPassword('inline');
        }else{
            setPassword(val);
            setDisplayPassword('none');
        }
    }
    const changeInputPasswordConfirm = (e) => {
        let val = e.target.value;
        if(password === val){
            setPasswordConfirm(val);
            setDisplayConfirm('none');
        }else{
            setDisplayConfirm('inline');
        }
    }
    return(
        <>
            <Container>
                <Loader visible={loader} className="loaders" type="ThreeDots" color="#ED1C24" height="100" width="100" />
                <AlertCustom 
                    setMessage={setMessage}
                    showError={message.show}
                    message={message.message}
                    type={message.type}
                ></AlertCustom>
                <Row>
                    <Col sm="12">
                        <Navbar expand="lg" variant="light" bg="white">
                            <Container>
                                <Navbar.Brand as={Link} to={"/artis"}><BsXLg size="15px" /> <span className="ml-3">Ubah Profile</span></Navbar.Brand>
                                <Navbar.Toggle />
                                <Navbar.Collapse className="justify-content-end">
                                <Navbar.Text>
                                    {/* <Button variant="danger">Buat Role</Button> */}
                                </Navbar.Text>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </Col>
                </Row>
                <Row className="mt-5 justify-content-md-center">
                    <Col sm="6">
                        <Card className="border-card mt-5">
                            <Card.Body className="px-4 py-0 d-flex">
                                <TabbedHeader selectedTitle={selectedTitle} stateCallback={tabClick}>
                                    <Tab title="Ubah Profile"></Tab>
                                    <Tab title="Ubah Password"></Tab>
                                </TabbedHeader>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='mt-0 justify-content-md-center'>
                    <Col sm="6">
                        <Card className="border-card mt-0">
                            <Card.Body>
                                <TabbedContainer selectedTitle={selectedTitle}>
                                    <Grid.Row cards={true} title="Ubah Profile">
                                        <Form>
                                            <Form.Group className="mb-4" controlId="nama">
                                                <Form.Label>Foto Profil</Form.Label>
                                                <Image src={urlImage} roundedCircle style={{height: "85px", width: "85px"}}/>
                                                <Form.Control 
                                                    type="file" 
                                                    style={{display: "none"}}
                                                    ref={hiddenFileInput}
                                                    onChange={ambilFoto} 
                                                />
                                                <Button variant="outline-danger" onClick={klikAmbilFoto} className="ml-3">Ganti Foto</Button>
                                            </Form.Group>
                                            <Form.Group className="mb-4" controlId="nama">
                                                <Form.Label>Nama</Form.Label>
                                                <Form.Control type="text" placeholder="Masukan Nama Lengkap" value={nama} onChange={changeInputNama}/>
                                            </Form.Group>
                                            <Form.Group className="mb-4" controlId="Email">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="text" placeholder="Masukan Email" value={email} onChange={changeInputEmail}/>
                                            </Form.Group>
                                            <Button variant="danger" disabled={disable} onClick={ubahProfile}>Simpan Perubahan</Button>
                                        </Form>
                                    </Grid.Row>
                                    <Grid.Row cards={true} title="Ubah Password">
                                        <Form>
                                            <Form.Group className="mb-0" controlId="password">
                                                <Form.Label>Password <span style={{color: 'red', display: displayPassword}} className="font-weight-bold ml-3">Password tidak sama</span></Form.Label>
                                            </Form.Group>
                                            <InputGroup hasValidation>
                                                <Form.Control type={typePassword}  placeholder="Password" onChange={changeInputPassword}/>
                                                <InputGroup.Text onClick={changeTypePassword} >{typePassword === "password" ?   <BsFillEyeSlashFill/> : <BsFillEyeFill/>}</InputGroup.Text>
                                            </InputGroup>
                                            
                                            <Form.Group className="mt-4" controlId="password">
                                                <Form.Label>Confirm Password <span style={{color: 'red', display: displayConfirm}} className="font-weight-bold ml-3">Password tidak sama</span></Form.Label> 
                                            </Form.Group>
                                            <InputGroup hasValidation>
                                                <Form.Control type={typeNewPassword}  placeholder="Confirm Password" onChange={changeInputPasswordConfirm}/> 
                                                <InputGroup.Text onClick={changeTypeNewPassword} >{typeNewPassword === "password" ?   <BsFillEyeSlashFill/> : <BsFillEyeFill/>}</InputGroup.Text>
                                            </InputGroup>
                                             
                                            <Button variant="danger" className='mt-4' disabled={disable} onClick={ubahPassword}>Simpan Perubahan</Button>
                                        </Form>
                                    </Grid.Row>
                                </TabbedContainer>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Profile