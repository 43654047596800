import React, {useState, useEffect} from "react";

import SiteWrapper from '../../Layouts/SiteWrapper';
import { Row, Col, Button, Dropdown, Image, Badge} from 'react-bootstrap';
import DataTable from "react-data-table-component";
import { Card, FormTextInput } from "tabler-react";
import HapusGroup from "./Modal/HapusGroup";
import EditGroup from "./Modal/EditGroup";
import BuatGroup from "./Modal/BuatGroup";
import { BsThreeDots} from "react-icons/bs";
import axios from 'axios';
import Loader from 'react-loader-spinner';

function ManajamenGroup() {

  type breadcrumbItem = {|
    +value: string,
    +to?: string,
    +active?: boolean,
  |};

  const breadcrumbItems: Array<breadcrumbItem> = [
    {
      value: "Judul",
      to: "/judul",
      active: true,
    },
    {
      value: "Manajemen Group",
      to: "/manajemen-group",
      active: false,
    }
    
  ];

  const [urlImageAvatar]          = useState(process.env.REACT_APP_FRONTEND_URL+"images/image2.png");
  const [paginate, setPaginate]   = useState({
    page:1,
    perPage:10,
    selectedColumn:"",
    sortDirection:"",
    search:"",
  });
  const token                     = localStorage.getItem('token');
  const [loader, setLoader]       = useState(true);
  const [dataGroup, setDataGroup] = useState('');
  const loginUrl                  = process.env.REACT_APP_LOGIN_TOKEN_URL;

  useEffect( () => {
    axios.get(process.env.REACT_APP_BE_DBTALENT_URL+`talent/group?page=${paginate.page}&limit=${paginate.perPage}&sortBy=${paginate.selectedColumn}&desc=${paginate.sortDirection}&field_search=name&search=${paginate.search}`,
    { 
      headers: {
          'Authorization' : `Bearer ${token}`, 
          'Accept'        : 'application/json', 
          'Content-Type'  : 'application/json'
      }
    })
    .then((res) =>{
      setDataGroup(res.data);
      setLoader(false)
    })
    .catch(error =>{
      if (error.response) {
        if(error.response.status===401){
            window.open(loginUrl,"_parent")
        }
      }
    })
  
  }, [token, paginate]);

  const columns = [
    {
        name: 'Nama',
        selector: 'nama',
        sortable: true,
    },
    
    {
        name: 'Aksi',
        selector: 'aksi',
        maxWidth:'10%',
        sortable: false,
    },
  ];

  const data = [];
      dataGroup&&dataGroup.data.data.map((item) =>{
        const itemGroup =
        {
            id: item.id,
            nama: item.group_name,
            aksi: <><EditGroup token={token} idGroup={item.id} nameGroup={item.group_name}/> &nbsp;<HapusGroup idGroup={item.id} nameGroup={item.group_name} token={token}/></>
        }
        data.push(itemGroup);
      });

  return (
    <SiteWrapper breadcrumbItems={breadcrumbItems} title={<span className="font-weight-bold">Manajemen Group</span>}>
      <Loader visible={loader} className="loaders" type="ThreeDots" color="#ED1C24" height="100" width="100" />
      <Card className="border-card mt-5">
      <Card.Body>
        <Row>
          <Col md="6">
            <h4 className="font-weight-bold">Daftar Group</h4>
          </Col>
          <Col md="6" className="text-right">
             <BuatGroup token={token}/>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col sm="12" className="mr-5">
            <div class="row flex-row-reverse ml-1 justify-content-between">
              <div class="mr-3 d-flex align-items-center">
                <div class="mdb-datatable-filter flex-row text-left">
                  <FormTextInput
                    class="form-control form-control-sm ml-0 my-1" 
                    placeholder="Search" 
                    value={paginate.search}
                    onChange={evt => setPaginate({...paginate,search:evt.target.value})}
                    />
                </div>
              </div>
            </div>

            <DataTable
              className={"table-dropdown"}
              columns={columns}
              data={data}
              highlightOnHover
              responsive
              pagination
              paginationServer
              paginationTotalRows={dataGroup&&dataGroup.data.total}
              paginationPerPage={paginate.perPage}
              onChangeRowsPerPage={perPage => setPaginate({...paginate,page:1,perPage:perPage})}
              onChangePage={page => setPaginate({...paginate,page:page})}
              // onSort={(selectedColumn, sortDirection) => {setPaginate({...paginate,selectedColumn:selectedColumn.selector,sortDirection:sortDirection==='desc'?true:false})}}
            />
            
          </Col>
        </Row>

      </Card.Body>
      </Card>
    </SiteWrapper>
  )
}

export default ManajamenGroup;