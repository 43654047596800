import React, {useState} from 'react';
import {Modal, Button, Row, Col, Image} from "react-bootstrap";
import { BsTrashFill} from "react-icons/bs";
import axios from 'axios';
import AlertCustom from '../../../Components/Modal/AlertCustomComponent';

function HapusGroup(props) {
    const [show, setShow]       = useState(false);
    const handleClose           = () => setShow(false);
    const handleShow            = () => setShow(true);
    const [urlImageAvatar]      = useState(process.env.REACT_APP_FRONTEND_URL+"images/delete.png");
    const [disable, setDisable] = useState(false);
    const [message, setMessage] = useState({type:'',message:'', show:'d-none'});
    const loginUrl              = process.env.REACT_APP_LOGIN_TOKEN_URL;

    const hapusGroup = () =>{
      setDisable(true);
      axios.delete(process.env.REACT_APP_BE_DBTALENT_URL+`talent/group/delete/`+props.idGroup,
      { 
        headers: {
            'Authorization' : `Bearer ${props.token}`, 
            'Accept'        : 'application/json', 
            'Content-Type'  : 'application/json'
        }
      })
      .then((res) =>{
        setMessage({
          type:'success',
          message:"Berhasil Menghapus Group",
          show:'d-block'
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(error =>{
        setDisable(false);
        var message = error.message;
        if(typeof error.response != "undefined"){
          message = error.response.data.message;
        }
        setMessage({
          type:'danger',
          message:message,
          show:'d-block'
        });

        if (error.response) {
          if(error.response.status===401){
              window.open(loginUrl,"_parent")
          }
        }
      })
    }

    return (
      <>
        <Button variant="danger" className="mr-0" size="sm" onClick={handleShow}><BsTrashFill size={"16px"}/></Button>
  
        <Modal show={show} onHide={handleClose}>
          <AlertCustom 
              setMessage={setMessage}
              showError={message.show}
              message={message.message}
              type={message.type}
          ></AlertCustom>
          <Modal.Body>
            <Row className="my-5">
                <Col sm="12" className="text-center">
                    <Image src={urlImageAvatar} roundedCircle style={{height: "100px", width: "100px"}}/>
                </Col>
                <Col sm="12" className="text-center mt-5">
                    <p>Hapus Group {props.nameGroup}?</p>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col sm="6" className="text-right">
                    <Button variant="outline-danger" className="px-5" onClick={handleClose}>Batal</Button>
                </Col>
                <Col sm="6">
                    <Button variant="danger" className="px-5" disabled={disable} onClick={hapusGroup}>Hapus</Button>
                </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  }
  
  export default HapusGroup