import React from "react";

import {
  Button,
  Image,
  Row,
  Col,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Badge } from "tabler-react";
import { Link } from "react-router-dom";

function ArtisCard(props) {
  return (
    <Card className="card-rounded">
      <Card.Body className="p-0 ">
        <Link to={"/artis/detail/" + props.dataArtis.id}>
          <Image
            src={props.dataArtis.foto}
            className="img-rounded img-card-bookmark"
          />
        </Link>
        <div className="p-3">
          <Link to={"/artis/detail/" + props.dataArtis.id}>
            {props.dataArtis.nama_artis.length > 15 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2">
                    {props.dataArtis.nama_artis}
                  </Tooltip>
                }
              >
                <h4 className="font-weight-bold">
                  {props.dataArtis.nama_artis.substring(0, 12)}...
                </h4>
              </OverlayTrigger>
            ) : (
              <h4 className="font-weight-bold">{props.dataArtis.nama_artis}</h4>
            )}
            <div>
              {props.dataArtis.usia} &#183; {props.dataArtis.gender}
            </div>
            <div className="mt-3">
              {props.dataArtis.availability === 0 ? (
                <Badge color="danger" className="mr-1 p-2">
                  Not Available
                </Badge>
              ) : (
                <Badge color="success" className="mr-1 p-2">
                  {props.dataArtis.availability}
                </Badge>
              )}
            </div>
            <div className="mt-3">
              <div>
                {props.dataArtis.artis_sinemart === 1 ? (
                  <>
                    <i class="bx bxs-camera-movie text-danger"></i>{" "}
                    <span>Sinemart</span>
                  </>
                ) : (
                  <>
                    <i class="bx bxs-camera-movie text-danger"></i>{" "}
                    <span>Luar Sinemart</span>
                  </>
                )}
              </div>

              {props?.dataArtis?.manajemen?.length > 15 ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      {props.dataArtis.manajemen}
                    </Tooltip>
                  }
                >
                  <div className="mt-2">
                    <>
                      <i class="bx bxs-buildings text-warning"></i>{" "}
                      {props.dataArtis.manajemen.substring(0, 12)}...
                    </>
                  </div>
                </OverlayTrigger>
              ) : (
                <div className="mt-2">
                  <>
                    <i class="bx bxs-buildings text-warning"></i>{" "}
                    {props?.dataArtis?.manajemen}
                  </>
                </div>
              )}
            </div>
          </Link>
          <Row className="mt-3">
            <Col className="mb-1">
              <Button
                variant="light"
                className="w-100"
                type="button"
                style={{ fontSize: "11px" }}
                onClick={() =>
                  props.onClick(props.dataArtis.id, props.idFolder)
                }
              >
                <i class="bx bxs-bookmark-plus"></i> Hapus dari Bookmark
              </Button>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
}
export default ArtisCard;
