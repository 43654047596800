import React from "react";
import { Header } from "../ui.header";
import { Sidebar } from "../ui.sidebar";
import { SubHeader } from "../ui.sub-header";
import "./layout.css";

export function Layout({
  itemsObjects,
  activePath,
  subHeader,
  subHeaderLink,
  subHeaderText,
  urlLogo,
  userName,
  accessLevel,
  fotoAvatar,
  logoutLink,
  backendUrl,
  accessToken,
  dbTalentUrl,
  children,
}) {
  return (
    <div className={"page"}>
      <div className={"page-main"}>
        <Header
          urlLogo={urlLogo}
          userName={userName}
          accessLevel={accessLevel}
          fotoAvatar={fotoAvatar}
          logoutLink={logoutLink}
          backendUrl={backendUrl}
          accessToken={accessToken}
          dbTalentUrl={dbTalentUrl}
        ></Header>
        {subHeader === "true" ? (
          <SubHeader linkTo={subHeaderLink} text={subHeaderText}></SubHeader>
        ) : (
          ""
        )}
        <Sidebar
          menuObj={itemsObjects}
          activePath={activePath}
          subHeader={subHeader}
        ></Sidebar>
        <div className={"main-layout " + subHeader}>
          <div className="main">{children}</div>
        </div>
        {/* {tampilPassword()} */}
      </div>
    </div>
  );
}
