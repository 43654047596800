import React, { useEffect, useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import { AiOutlinePaperClip } from "react-icons/ai";
import AlertCustom from "../../../Components/Modal/AlertCustomComponent";
import { DatePicker } from "antd";
import moment from "moment";
import "moment/locale/id";

const EditInfoJudul = (props) => {
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const loginUrl = process.env.REACT_APP_LOGIN_TOKEN_URL;

  const [dataReading, setDataReading] = useState({
    startDate: null,
    endDate: null,
  });

  const [dataShooting, setDataShooting] = useState({
    startDate: null,
    endDate: null,
  });

  const [totalEpisode, setTotalEpisode] = useState();
  const [durasi, setDurasi] = useState();

  const [message, setMessage] = useState({
    type: "",
    message: "",
    show: "d-none",
  });

  useEffect(() => {
    if (show) {
      handleGetData();
    }
  }, [show]);

  const handleGetData = () => {
    const data = JSON.stringify({
      id_judul: props.id,
    });

    axios
      .post(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `management/pengajuan/get-additional-info`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const data = res.data.data;
        if (Array.isArray(data) && data.length > 0) {
          setDataReading({
            startDate: data[0].tgl_reading_skenario_from
              ? new Date(data[0].tgl_reading_skenario_from)
              : null,
            endDate: data[0].tgl_reading_skenario_to
              ? new Date(data[0].tgl_reading_skenario_to)
              : null,
          });
          setDataShooting({
            startDate: data[0].tgl_shooting_from
              ? new Date(data[0].tgl_shooting_from)
              : null,
            endDate: data[0].tgl_shooting_to
              ? new Date(data[0].tgl_shooting_to)
              : null,
          });
          setTotalEpisode(parseFloat(data[0].total_episode));
          setDurasi(parseFloat(data[0].durasi_per_episode));
        }
      })
      .catch((error) => {
        var message = error.message;
        if (typeof error.response != "undefined") {
          message = error.response.data.message;
        }
        setMessage({
          type: "danger",
          message: Object.values(message)[0][0],
          show: "d-block",
        });

        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  };

  const handleSubmit = () => {
    // if (
    //   !dataReading.startDate ||
    //   !dataReading.endDate ||
    //   !dataShooting.startDate ||
    //   !dataShooting.endDate
    // ) {
    //   return setMessage({
    //     type: "danger",
    //     message: "Tanggal tidak boleh kosong",
    //     show: "d-block",
    //   });
    // }

    // if (
    //   dataReading.startDate > dataReading.endDate ||
    //   dataShooting.startDate > dataShooting.endDate
    // ) {
    //   return setMessage({
    //     type: "danger",
    //     message: "Tanggal dari tidak boleh lebih besar tanggal",
    //     show: "d-block",
    //   });
    // }

    const data = JSON.stringify({
      id_judul: props.id,
      reading_date_from: dataReading.startDate,
      reading_date_to: dataReading.endDate,
      shooting_date_from: dataShooting.startDate,
      shooting_date_to: dataShooting.endDate,
      total_episode: totalEpisode ? totalEpisode : 0,
      durasi: durasi ? durasi : 0,
    });

    axios
      .post(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `management/pengajuan/generate-additional-info`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setMessage({
          type: "success",
          message: "Berhasil Mengubah Judul",
          show: "d-block",
        });

        handleClose();

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        var message = error?.response?.data?.message;
        setMessage({
          type: "danger",
          message: Object.values(message),
          show: "d-block",
        });

        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  };

  return (
    <>
      <Button
        variant="light"
        className="mr-1 d-flex justify-content-between align-items-center"
        onClick={() => {
          handleShow();
        }}
      >
        <AiOutlinePaperClip className="mr-2" size={16} />
        Info Judul
      </Button>
      <AlertCustom
        setMessage={setMessage}
        showError={message.show}
        message={message.message}
        type={message.type}
      ></AlertCustom>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="mx-4">
          <Modal.Title>Info Judul</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-4">
          <Form.Group className="mb-4" controlId="reading">
            <Form.Label className="text-black">
              <h6 style={{ fontWeight: "bold" }}>Reading</h6>
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Label className="my-auto w-25">Dari</Form.Label>
              <DatePicker
                name="reading_to"
                className={"form-control"}
                onChange={(date) =>
                  setDataReading((prevState) => ({
                    ...prevState,
                    startDate: date,
                  }))
                }
                value={
                  dataReading.startDate
                    ? moment(dataReading.startDate, "DD/MM/YYYY")
                    : ""
                }
                dateFormat="dd/MM/Y"
              />
            </InputGroup>
            <InputGroup className="mb-2">
              <Form.Label className="my-auto w-25">Hingga</Form.Label>
              <DatePicker
                name="reading_from"
                className={"form-control"}
                onChange={(date) =>
                  setDataReading((prevState) => ({
                    ...prevState,
                    endDate: date,
                  }))
                }
                value={
                  dataReading.endDate
                    ? moment(dataReading.endDate, "DD/MM/YYYY")
                    : ""
                }
                dateFormat="dd/MM/Y"
              />
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-4" controlId="shooting">
            <Form.Label className="text-grey fw-bold">
              <h6 style={{ fontWeight: "bold" }}>Shooting</h6>
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Label className="my-auto w-25">Dari</Form.Label>
              <DatePicker
                name="reading_to"
                className={"form-control"}
                onChange={(date) =>
                  setDataShooting((prevState) => ({
                    ...prevState,
                    startDate: date,
                  }))
                }
                value={
                  dataShooting.startDate
                    ? moment(dataShooting.startDate, "DD/MM/YYYY")
                    : ""
                }
                dateFormat="dd/MM/Y"
              />
            </InputGroup>
            <InputGroup className="mb-2">
              <Form.Label className="my-auto w-25">Hingga</Form.Label>
              <DatePicker
                name="reading_from"
                className={"form-control"}
                onChange={(date) =>
                  setDataShooting((prevState) => ({
                    ...prevState,
                    endDate: date,
                  }))
                }
                value={
                  dataShooting.endDate
                    ? moment(dataShooting.endDate, "DD/MM/YYYY")
                    : ""
                }
                dateFormat="dd/MM/Y"
              />
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-4" controlId="totalEpisode">
            <Form.Label>
              <h6 style={{ fontWeight: "bold" }}>Total Episode</h6>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Masukan Total Episode"
              onChange={(e) => {
                setTotalEpisode(e.target.value);
              }}
              value={totalEpisode}
            />
          </Form.Group>

          <Form.Group className="mb-4" controlId="durasi">
            <Form.Label>
              <h6 style={{ fontWeight: "bold" }}>Durasi Dalam Menit</h6>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Masukan Durasi"
              onChange={(e) => {
                setDurasi(e.target.value);
              }}
              value={durasi}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="danger" onClick={handleSubmit}>
            Simpan Perubahan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditInfoJudul;
