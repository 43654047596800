import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Dropdown, Row, Col, Image } from "react-bootstrap";
import { Grid, FormTextInput, Form } from "tabler-react";
import { ContextAlert } from "../../Context/Alert";
import { Link } from "react-router-dom";
import {
  GetArtisBiografi,
  apiValidationData,
} from "../../actions/actions-artis";
import "./kandidat.css";
import axios from "axios";

function JadikanArtis(prop) {
  const [urlImageAvatar] = useState(
    process.env.REACT_APP_FRONTEND_URL + "images/settings.png"
  );
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleShow = async () => {
    setLoader(true);
    await getData();
    setShow(true);
  };

  const handleClose = () => {
    setInput({
      status_pernikahan: "",
      alamat: "",
      telepon_1: "",
      nama_ktp: "",
      id_ktp: "",
      email_bp: "",
      kewarganegaraan: "",
      pekerjaan: "",
      kecamatan: "",
      kel_desa: "",
      rw: 0,
      rt: 0,
      gol_darah: "",
      jenis_kelamin: "",
      tempat_lahir: "",
      agama: "",
      tgl_lahir: "",
      status_pernikhan: "",
      erp_id: 0,
      id_dbtalent: 0,
      email: "",
    });
    setShow(false);
  };

  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useContext(ContextAlert);
  const [resWarning, setResWarning] = useState("");

  const [inputNpwp, setInputNpwp] = useState([
    { nama_npwp: "", npwp: "", alamat_npwp: "" },
  ]);
  const [input, setInput] = useState({
    id: 0,
    status_pernikahan: "",
    alamat: "",
    telepon_1: "",
    nama_ktp: "",
    id_ktp: "",
    email_bp: "",
    kewarganegaraan: "",
    pekerjaan: "",
    kecamatan: "",
    kel_desa: "",
    rw: 0,
    rt: 0,
    gol_darah: "",
    jenis_kelamin: "",
    tempat_lahir: "",
    agama: "",
    tgl_lahir: "",
    status_pernikhan: "",
    erp_id: 0,
    id_dbtalent: 0,
    email: "",
  });

  function handleError(e) {
    let message = e;
    if (!message) {
      message = e.message;
    }
    setMessage({
      type: "danger",
      message: JSON.stringify(message),
      show: "d-block",
    });
  }

  const getData = () => {
    return new Promise((resolve, reject) => {
      GetArtisBiografi(
        `id=${prop.data.id}`,
        token,
        ({ data, isFetching, isFound, isError }) => {
          setLoader(isFetching);

          if (isFound) {
            if (data.data) {
              setInput({
                id: data.data.id_dbtalent,
                status_pernikahan: data.data.status_perkawinan,
                alamat: data.data.alamat,
                telepon_1: data.data.telepon_dbtalent,
                nama_ktp: data.data.supplier_name,
                id_ktp: data.data.id_ktp,
                email_bp: data.data.email_bp,
                agama: data.data.agama,
                tgl_lahir: data.data.tanggal_lahir,
                tempat_lahir: data.data.tempat_lahir,
                jenis_kelamin:
                  data.data.jenis_kelamin === "L"
                    ? "Laki-laki"
                    : data.data.jenis_kelamin === "P"
                    ? "Perempuan"
                    : "",
                provinsi: data.data.provinsi,
                kel_desa: data.data.kelurahan,
                kecamatan: data.data.kecamatan,
                rt_rw: data.data.rt_rw,
                pekerjaan: data.data.pekerjaan,
                kewarganegaraan: data.data.kewarganegaraan,
                erp_id: data.data.erp_id,
                id_dbtalent: data.data.id_dbtalent,
                email: data.data.email,
              });

              if (
                Array.isArray(data.data.data_npwp) &&
                data.data.data_npwp.length
              ) {
                const datass = data.data.data_npwp
                  .filter((element) => element.nama)
                  .map((element) => ({
                    nama_npwp: element.nama,
                    npwp: element.npwp,
                    alamat_npwp: element.alamat,
                  }));
                setInputNpwp(datass);
              }

              resolve(data);
            }
          } else if (isError) {
            handleError(data);
            reject(new Error("Error fetching data"));
          }
        }
      );
    });
  };

  async function submit() {
    const dataResult = {
      no_ajuan_kontrak:
        (input.erp_id != null ? input.erp_id : 0) +
        "|" +
        (Number.isInteger(input.id_dbtalent)
          ? input.id_dbtalent.toString()
          : input.id_dbtalent) +
        "|" +
        input.id_ktp,
      jenis_proses: 3,
    };

    apiValidationData(dataResult, token, ({ data, isFound, isError }) => {
      if (data) {
        if (Array.isArray(data.data) && data.data.length) {
          setShow(false);
          setShowWarning(true);
          setResWarning(data.data[0].warning);
        } else {
          submit2();
        }
      }
    });
  }

  function submit2() {
    const dataResult = {
      no_ajuan_kontrak:
        (input.erp_id != null ? input.erp_id : 0) +
        "|" +
        (Number.isInteger(input.id_dbtalent)
          ? input.id_dbtalent.toString()
          : input.id_dbtalent) +
        "|" +
        input.id_ktp,
      jenis_proses: 4,
    };

    apiValidationData(dataResult, token, ({ data, isFound, isError }) => {
      if (data) {
        if (Array.isArray(data.data) && data.data[0]?.warning?.trim() !== "") {
          handleError(data.data[0].warning.trim());
        } else {
          setDisabled(true);
          handleCreateArtis();
        }
      }
    });
  }

  function handleCreateArtis() {
    const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
    const inputData = {
      id: input.id,
      id_dbtalent: input.id_dbtalent,
      id_ktp: input.id_ktp,
      email_bp: input.email_bp,
      email: input.email,
      telepon_1: input.telepon_1,
    };

    axios
      .post(`${urlMicroservice}talent/artis/create`, inputData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setShowSuccess(true);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }

  return (
    <>
      <Button
        variant="outline-danger"
        className="w-100"
        type="button"
        onClick={handleShow}
      >
        Jadikan Artis
      </Button>

      <Modal show={show}>
        <Modal.Body>
          <Row className="my-5">
            <Col sm="12" className="text-center">
              <Image
                src={urlImageAvatar}
                roundedCircle
                style={{ height: "100px", width: "100px" }}
              />
            </Col>
            <Col sm="12" className="text-center mt-5">
              <p className="font-weight-bold">
                Apakah Anda yakin ingin melanjutkan proses menjadikan{" "}
                {input.nama_ktp} sebagai artis?
              </p>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col sm="6" className="text-right">
              <Button
                variant="outline-danger"
                className="px-5"
                onClick={() => {
                  handleClose();
                }}
              >
                Batal
              </Button>
            </Col>
            <Col sm="6">
              <Button variant="danger" className="px-5" onClick={submit}>
                Lanjut
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={showWarning}>
        <Modal.Body>
          <Row className="my-5">
            <Col sm="12" className="text-center">
              <Image
                src={urlImageAvatar}
                roundedCircle
                style={{ height: "100px", width: "100px" }}
              />
            </Col>
            <Col sm="12" className="text-center mt-5">
              <p className="font-weight-bold">{resWarning}</p>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col sm="6" className="text-right">
              <Button
                variant="outline-danger"
                className="px-5"
                onClick={() => {
                  setShowWarning(false);
                }}
              >
                Batal
              </Button>
            </Col>
            <Col sm="6">
              <Button variant="danger" className="px-5" onClick={submit2}>
                Jadikan artis
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={showSuccess} backdrop="static">
        <Modal.Body className="p-5 text-center modal-convert">
          <div className="mb-5 image-circle">
            <Image src={prop.data.foto} />
          </div>
          <Modal.Title className="pt-5">
            {prop.data.nama_artis} Telah Menjadi Artis!
          </Modal.Title>
          <div className="mt-3 mb-5">
            Sekarang Anda dapat menemukannya pada menu Artis
          </div>
          <div className="d-flex justify-content-between">
            <Button
              variant="outline-transparant"
              onClick={() => window.location.reload()}
            >
              Kembali ke Kandidat Artis
            </Button>
            <Link to="/artis">
              <Button variant="light-transparant">Buka Menu Artis</Button>
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default JadikanArtis;
