import React from "react";

import {Button, Image, Row, Col} from 'react-bootstrap';
import { Card, Badge } from "tabler-react";
import { Link } from "react-router-dom";

function ArtisCardNot() {
    return (
        <Card className="card-rounded">
            <Card.Body className="p-0 ">
                <Link to={"/artis/detail"}>
                    <Image src="/demo/artis.jpg" className="img-rounded" style={{filter: "grayscale(100%)"}}/>
                </Link>
                <div className="p-3">
                    <Link to={"/artis/detail"}>
                        <h4 className="font-weight-bold">Chelsea Islan</h4>
                        <div>24 Tahun &#183; Perempuan</div>
                        <div className="mt-3">
                            <Badge color="danger" className="mr-1 p-2">Not Available</Badge>
                            <Badge color="primary" className="mr-1 p-2">SP</Badge>
                        </div>
                        <div className="mt-3">
                            <div>
                                <i class='bx bxs-camera-movie text-danger'></i> Sinemart
                            </div>
                            <div className="mt-2">
                                <i class='bx bxs-buildings text-warning'></i> Stark Management
                            </div>
                        </div>
                    </Link>
                    <Row className="mt-3">
                        <Col className="mb-1">
                            <Button variant="light" className="w-100" type="button" style={{fontSize: "11px"}}><i class='bx bxs-bookmark-plus'></i> Hapus dari Bookmark</Button>
                        </Col>
                    </Row>
                </div>
            </Card.Body>
        </Card>
    );
}
export default ArtisCardNot;