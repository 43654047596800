import React, { useState, useEffect } from "react";
import "boxicons/css/boxicons.min.css";
import "tabler-react/dist/Tabler.css";
import "./header.css";
import image from "./logo.png";
import { Site, AccountDropdown } from "tabler-react";
import { Dropdown } from "react-bootstrap";
import axios from "axios";

export function Header({
  urlLogo,
  userName,
  accessLevel,
  fotoAvatar,
  logoutLink,
  backendUrl,
  accessToken,
  dbTalentUrl,
}) {
  // const [akses,setAkses] = useState("");

  function logout() {
    localStorage.clear();
    window.open(logoutLink, "_parent");
  }

  const passwordClick = () => {
    window.open(dbTalentUrl + "profile", "_parent");
  };

  const backToMenu = () => {
    window.open(
      process.env.REACT_APP_LOGIN_TOKEN_URL + "/main-menu",
      "_parent"
    );
  };

  const header = {
    href: urlLogo,
    imageURL: image,
    alt: "Sinemart",
  };

  const account = {
    avatarURL: fotoAvatar,
    name: userName,
    access_level: accessLevel,
    options: [
      // { icon: "lock", value: "Ubah Kata Sandi", onClick: passwordClick },
      { icon: "send", value: "Halaman Menu", onClick: backToMenu },
      { icon: "log-out", value: "Keluar", onClick: logout },
    ],
  };

  const [pageNotif, setPageNotif] = useState(true);
  const [show, setShow] = useState(false);
  const [notif, setNotif] = useState([]);
  const [newNotif, setNewNotif] = useState(false);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState({
    current_page: 1,
    last_page: 1,
  });
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className={"mr-5 align-self-center"}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      {newNotif ? <i className="dot-notif bx bxs-circle"></i> : ""}
    </div>
  ));

  const handleShow = (e) => {
    setShow(e);
  };

  useEffect(() => {
    axios
      .get(`${backendUrl}api/talent/notifikasi/index?page=${page}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((res) => {
        const data = res.data.data.data;
        setPaginate({
          current_page: res.data.data.current_page,
          last_page: res.data.data.last_page,
        });

        const datas = [];
        const newId = [];
        data &&
          data.notifications.map((item) => {
            const resData = {
              id: item[0].id,
              title: item[0].title,
              content: item[0].content,
              path: item[0].path,
              created_at: item[0].created_at,
              is_read: item[0].is_read,
            };
            datas.push(resData);

            if (item[0].is_read == 0) {
              newId.push(item[0].id);
            }
          });

        if (res.data.data.current_page == 1) {
          setNotif(datas);
        } else if (pageNotif === true) {
          setNotif((notifExisting) => {
            return [...notifExisting, ...datas];
          });
        }

        if (data.unread > 0) {
          setNewNotif(true);
        } else {
          setNewNotif(false);
        }

        if (newId.length > 0 && show === true) {
          axios
            .post(
              `${backendUrl}api/talent/notifikasi/read`,
              { notification_recipient_ids: newId },
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + accessToken,
                },
              }
            )
            .then((res) => {
              console.log(res);
            })
            .catch(function (e) {
              console.log(e);
            });
        }

        if (res.data.data.current_page === res.data.data.last_page) {
          setPageNotif(false);
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  }, [page, show]);

  function removeNotif(id, index) {
    axios
      .post(
        `${backendUrl}api/talent/notifikasi/delete/`,
        { notification_recipient_id: id },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then((res) => {
        setNotif((existingItems) => {
          return [
            ...existingItems.slice(0, index),
            ...existingItems.slice(index + 1),
          ];
        });
        setShow(false);
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  return (
    <Site.Header>
      <Site.Logo
        href={header.href}
        src={header.imageURL}
        alt={header.alt}
      ></Site.Logo>
      <div className="d-flex order-lg-2 ml-auto">
        <Dropdown className="notifikasi" drop="start" onToggle={handleShow}>
          <Dropdown.Toggle as={CustomToggle}>
            <i className="bx bx-bell"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu show={show}>
            <div className="value">
              {notif.length > 0 ? (
                <>
                  {notif.map((data, index) => (
                    <div
                      className={
                        "dropdown-item " + (data.is_read == 1 ? "" : "active")
                      }
                      key={"notif" + data.id}
                    >
                      <div className="d-flex justify-content-between">
                        <span className="waktu">{data.created_at}</span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            removeNotif(data.id, index);
                          }}
                        >
                          <i className="bx bx-trash"></i>
                        </span>
                      </div>
                      <a href={data.path}>
                        <div className="judul">{data.title}</div>
                        <div className="isi">{data.content}</div>
                      </a>
                    </div>
                  ))}
                  {paginate.current_page < paginate.last_page ? (
                    <div
                      className="text-center my-3"
                      onClick={() => setPage(page + 1)}
                    >
                      Lebih banyak notifikasi
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <div className="no-notif">Tidak Ada Notifikasi</div>
              )}
            </div>
          </Dropdown.Menu>
        </Dropdown>
        <AccountDropdown
          name={account.name}
          description={account.access_level}
          avatarURL={account.avatarURL}
          options={account.options}
        ></AccountDropdown>
      </div>
    </Site.Header>
  );
}
