import React, { useEffect, useState, useContext } from "react";
import { Button, Image } from "react-bootstrap";
import { Card, Grid } from "tabler-react";
import Calendar from "react-calendar";
import moment from "moment";
import axios from "axios";
import "./Calendar.css";
import TambahJadwal from "./TambahJadwalComponent";
import UbahJadwal from "./UbahJadwalComponent";
import { ContextPermission } from "../../Context/Permission";
import HapusJadwal from "./HapusJadwalComponent";
import { ContextAlert } from "../../Context/Alert";

function JadwalCard(props) {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const d = moment();
  const [value, setValue] = useState(new Date());
  const [marked, setMarker] = useState([]);
  const [dataValue, setDataValue] = useState([]);
  const [lainnya, setLainnya] = useState(3);
  const [dateNow, setDateNow] = useState(moment().format("DD/MM/YYYY"));
  const [month, setMonth] = useState({
    month: d.format("MM"),
    year: d.format("YYYY"),
  });
  const statePermission = useContext(ContextPermission);
  const [message, setMessage] = useContext(ContextAlert);

  function onChangeCalendar(date) {
    setLainnya(3);
    setValue(date);
    setDateNow(moment(date).format("DD/MM/YYYY"));
  }

  function onClickMonth(value, event) {
    var value = moment(value);
    setMonth({ month: value.format("MM"), year: value.format("YYYY") });
  }

  function onActiveStartDateChange(action) {
    if (action.action === "next" || action.action === "prev") {
      var value = moment(action.activeStartDate);
      setMonth({ month: value.format("MM"), year: value.format("YYYY") });
    }
  }

  useEffect(() => {
    axios
      .get(
        `${urlMicroservice}talent/artis/jadwal?artist_id=${props.id}&bulan=${month.month}&tahun=${month.year}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setMarker(res.data.data);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }, [month]);

  useEffect(() => {
    axios
      .get(
        `${urlMicroservice}talent/artis/jadwal/show?tanggal=${dateNow}&artist_id=${props.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setDataValue(res.data.data);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }, [dateNow]);

  return (
    <>
      <Card className="border-card mt-5">
        <Card.Body className="p-4">
          <Grid.Row className="justify-content-center align-items-center">
            <Grid.Col width="6">
              <h5 className="font-weight-bold">Jadwal</h5>
            </Grid.Col>
            <Grid.Col width="6" className="text-right">
              <TambahJadwal id={props.id}></TambahJadwal>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="mt-3 mb-3">
            <Grid.Col style={{ fontSize: "12px" }}>
              <Calendar
                onChange={(date) => onChangeCalendar(date)}
                value={value}
                next2AriaLabel
                style={{ fontSize: "12px" }}
                onClickMonth={(value, event) => onClickMonth(value, event)}
                onActiveStartDateChange={(action) =>
                  onActiveStartDateChange(action)
                }
                next2Label={null}
                prev2Label={null}
                nextLabel={<i className="bx bxs-chevron-right-circle"></i>}
                prevLabel={<i className="bx bxs-chevron-left-circle"></i>}
                locale="id"
                tileClassName={({ date, view }) => {
                  if (
                    marked.find((x) => x === moment(date).format("DD/MM/YYYY"))
                  ) {
                    return "highlight";
                  }
                }}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <span className="text-grey mb-3 mt-5">
              {moment(dateNow, "DD/MM/YYYY").format("DD MMMM YYYY")}
            </span>
            <Grid.Col width="12">
              {dataValue &&
                dataValue.map((item, index) => {
                  if (index + 1 <= lainnya) {
                    let aksi = "";
                    if(item.data_from!=="pm"){
                      aksi =  <div className="d-flex">
                                <UbahJadwal data={item}></UbahJadwal>
                                <HapusJadwal data={item}></HapusJadwal>
                              </div>;
                    }
                    return (
                      <Card>
                        <div className="d-flex px-3 py-2 align-items-center justify-content-between">
                          <div>
                            <div className="font-weight-bold">
                              {item.jadwal}
                            </div>
                            <div
                              style={{ fontSize: "12px" }}
                              className="text-grey"
                            >
                              {item.dari_tanggal} - {item.sampai_tanggal}
                            </div>
                          </div>
                          {aksi}
                        </div>
                      </Card>
                    );
                  }
                })}
              {parseInt(dataValue.length) > lainnya ? (
                <div className="text-center mb-5">
                  <Button
                    variant="light"
                    className="font-weight-bold"
                    onClick={() => setLainnya(lainnya + 3)}
                  >
                    Lihat Jadwal Lainnya
                  </Button>
                </div>
              ) : (
                ""
              )}
              {dataValue.length <= 0 ? (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <Image src="/images/calendar.png" />
                  <div className="font-weight-bold mt-5 mb-3">
                    Tidak Ada Jadwal
                  </div>
                  <div className="text-center text-grey">
                    Artis tidak memiliki jadwal pada{" "}
                    {moment(dateNow, "DD/MM/YYYY").format("DD MMMM YYYY")}
                  </div>
                </div>
              ) : (
                ""
              )}
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default JadwalCard;
