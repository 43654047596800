import React, { useState, useEffect, useContext } from "react";
import { Button, Image, Dropdown } from "react-bootstrap";
import { Grid, Card } from "tabler-react";
import { DatePicker } from "antd";
import moment from "moment";
import axios from "axios";
import Loader from "react-loader-spinner";

import SiteWrapper from "../../Layouts/SiteWrapper";
import ArtisCard from "../../Components/Artis/ArtisCardComponent";
import { ContextPermission } from "../../Context/Permission";
import { ContextAlert } from "../../Context/Alert";

function Laporan() {
  type breadcrumbItem = {|
    +value: string,
    +to?: string,
    +active?: boolean,
  |};

  const breadcrumbItems: Array<breadcrumbItem> = [
    {
      value: "Artis",
      to: "/artis",
      active: true,
    },
    {
      value: "Laporan Penerimaan Artis",
      to: "/artis/laporan",
      active: false,
    },
  ];

  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [dataValue, setDataValue] = useState([]);
  const [print, setPrint] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [message, setMessage] = useContext(ContextAlert);
  const statePermission = useContext(ContextPermission);

  const disabledDate = (endValue) => {
    const startValue = date.startDate;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  useEffect(() => {
    if (submit === true && date.startDate && date.endDate) {
      var prints = "";
      if (print === true) {
        prints = "&print";
      }
      axios
        .get(
          `${urlMicroservice}talent/artis/lpa?dari_tanggal=${moment(
            date.startDate,
            "DD/MM/YYYY"
          ).format("DD/MM/YYYY")}&sampai_tanggal=${moment(
            date.endDate,
            "DD/MM/YYYY"
          ).format("DD/MM/YYYY")}${prints}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (print === true) {
            window.open(res.data.data.link, "_blank");
          } else {
            setDataValue(res.data.data);
          }
        })
        .catch((e) => {
          var message = e.response.data.message;
          if (!message) {
            message = e.message;
          }
          setMessage({
            type: "danger",
            message: JSON.stringify(message),
            show: "d-block",
          });
        });
    } else if (submit === true && (!date.startDate || !date.endDate)) {
      setMessage({
        type: "danger",
        message: "Tanggal mulai dan tanggal selesai harus diisi",
        show: "d-block",
      });
    }
    setPrint(false);
    setSubmit(false);
  }, [submit]);

  function reset() {
    setDataValue([]);
    setDate({
      startDate: "",
      endDate: "",
    });
  }

  return (
    <SiteWrapper
      breadcrumbItems={breadcrumbItems}
      title={"Laporan Penerimaan Artis"}
    >
      {statePermission.permission.artis.buat_laporan_artis.active === 1 ? (
        <>
          <Grid.Row>
            <Grid.Col md="12">
              <Card className="border-card mt-5">
                <Card.Body className="rounded py-4 px-5 bg-light-primary text-dark-primary font-weight-bold">
                  Tentukan tanggal penerimaan terlebih dahulu sebelum print
                  laporan.
                </Card.Body>
              </Card>
              <Card className="border-card mt-5">
                <Card.Body className="py-4 px-5">
                  <Grid.Row>
                    <Grid.Col md="10">
                      <div className="d-inline-flex align-items-center">
                        <div>Pilih Tanggal Penerimaan:</div>
                        <div className="w-250px">
                          <DatePicker
                            className={"form-control"}
                            onChange={(dates) => {
                              if (
                                moment(date.endDate, "DD/MM/YYYY").isBefore(
                                  dates
                                )
                              ) {
                                setDate({ startDate: dates, endDate: dates });
                              } else {
                                setDate((prevState) => ({
                                  ...prevState,
                                  startDate: dates,
                                }));
                              }
                            }}
                            value={
                              date.startDate
                                ? moment(date.startDate, "DD/MM/YYYY")
                                : ""
                            }
                            format="DD/MM/YYYY"
                            placeholder="DD/MM/YYYY"
                          />
                        </div>
                        <div className="ml-3 w-250px">
                          <DatePicker
                            className={"form-control"}
                            onChange={(dates) =>
                              setDate((prevState) => ({
                                ...prevState,
                                endDate: dates,
                              }))
                            }
                            value={
                              date.endDate
                                ? moment(date.endDate, "DD/MM/YYYY")
                                : ""
                            }
                            disabledDate={disabledDate}
                            format="DD/MM/YYYY"
                            placeholder="DD/MM/YYYY"
                          />
                        </div>
                        <Button
                          variant="outline-danger"
                          className="mx-3 px-3 w-25"
                          type="button"
                          onClick={() => {
                            setSubmit(true);
                          }}
                        >
                          Terapkan
                        </Button>
                        <Button
                          variant="light"
                          className="px-3 w-25"
                          type="button"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Grid.Col>
                    <Grid.Col md="2" className="text-right">
                      <Button
                        variant="light"
                        className="px-3"
                        type="button"
                        onClick={() => {
                          setPrint(true);
                          setSubmit(true);
                        }}
                      >
                        <i className="bx bxs-printer"></i> Print
                      </Button>
                    </Grid.Col>
                  </Grid.Row>
                </Card.Body>
              </Card>
            </Grid.Col>
            <Grid.Col md="12">
              <Grid.Row className="px-2">
                {dataValue &&
                  dataValue.map((item, index) => {
                    return (
                      <Grid.Col width="3" className="px-1">
                        <ArtisCard button={false} item={item}></ArtisCard>
                      </Grid.Col>
                    );
                  })}
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </>
      ) : (
        "Anda Tidak Memiliki Akses Buat Laporan"
      )}
    </SiteWrapper>
  );
}

export default Laporan;
