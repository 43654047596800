import React from 'react';
import './sub-header.css';

export function SubHeader({ linkTo, text }) {
  return (
    <div className={"d-flex align-self-center p-4 w-100 bg_sub_header"}>
      <div className="mr-3">
        <a href={linkTo}><i className={"bx bx-arrow-back"}></i></a>
      </div>
      <div>
        <span className='font-weight-bold'>{text}</span>
      </div>
    </div>
  );
}
