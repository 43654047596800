import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { Grid } from "tabler-react";

function FotoComposite(prop) {
  return (
    <>
      <Grid.Col width={2} className="px-1">
        <label class="imagecheck mb-4">
          <input
            name="imagecheck"
            onClick={prop.onClick}
            id={prop.id}
            type="checkbox"
            class="imagecheck-input"
            value=""
            disabled={!prop.imageIncrement && prop.progress >= 5}
          />
          <figure class="imagecheck-figure" datavalue={prop.imageIncrement}>
            <Image src={prop.data.foto} class="imagecheck-image" />
          </figure>
        </label>
      </Grid.Col>
    </>
  );
}

export default FotoComposite;
