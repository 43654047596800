import React, { useState, useRef, useEffect, useContext } from "react";
import { Modal, Button, Dropdown, Row, Col, Image } from "react-bootstrap";
import { Grid, FormTextInput, Form } from "tabler-react";
import { ContextAlert } from "../../Context/Alert";
import { getDataNpwp, getDataSupplierByNik } from "../../actions/actions-artis";
import "./kandidat.css";
import axios from "axios";

function TambahArtistSp(prop) {
  const [urlImageAvatar] = useState(
    process.env.REACT_APP_FRONTEND_URL + "images/settings.png"
  );
  const [image, setImage] = useState("/images/avatar.png");
  const token = localStorage.getItem("token");
  const inputFile = useRef(null);

  const handleClose = () => {
    setInput({
      foto: "",
      nama_artis: "",
      status_pernikahan: "",
      alamat: "",
      telepon_1: "",
      nama_ktp: "",
      id_ktp: "",
      email_bp: "",
      kewarganegaraan: "",
      pekerjaan: "",
      kecamatan: "",
      kel_desa: "",
      rw: 0,
      rt: 0,
      gol_darah: "",
      jenis_kelamin: "",
      tempat_lahir: "",
      agama: "",
      tgl_lahir: "",
      status_pernikhan: "",
      erp_id: 0,
      id_dbtalent: 0,
      email: "",
    });
    setImage("/images/avatar.png");
    setInputNpwp([{ nama_npwp: "", npwp: "", alamat_npwp: "" }]);
    prop.setShow(false);
  };
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useContext(ContextAlert);
  const [resWarning, setResWarning] = useState("");

  const [inputNpwp, setInputNpwp] = useState([
    { nama_npwp: "", npwp: "", alamat_npwp: "" },
  ]);
  const [input, setInput] = useState({
    foto: "",
    nama_artis: "",
    status_pernikahan: "",
    alamat: "",
    telepon_1: "",
    nama_ktp: "",
    id_ktp: "",
    email_bp: "",
    kewarganegaraan: "",
    pekerjaan: "",
    kecamatan: "",
    kel_desa: "",
    rw: 0,
    rt: 0,
    gol_darah: "",
    jenis_kelamin: "",
    tempat_lahir: "",
    agama: "",
    tgl_lahir: "",
    status_pernikhan: "",
    erp_id: 0,
    id_dbtalent: 0,
    email: "",
  });

  const handleAddNpwpFields = () => {
    const values = [...inputNpwp];
    values.push({ nama_npwp: "", npwp: "", alamat_npwp: "" });
    setInputNpwp(values);
  };

  const handleRemoveNpwpFields = (index) => {
    const values = [...inputNpwp];
    values.splice(index, 1);
    setInputNpwp(values);
  };

  const handleInputNpwpChange = (index, event) => {
    const values = [...inputNpwp];
    if (event.target.name === "nama_npwp") {
      values[index].nama_npwp = event.target.value;
    } else if (event.target.name === "alamat_npwp") {
      values[index].alamat_npwp = event.target.value;
    } else {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        values[index].npwp = event.target.value;
      }
    }
    setInputNpwp(values);
  };

  function handleChange(evt, name) {
    let value = "";
    if (name) {
      var label = "provinsi_nama";
      if (name === "kota_id") {
        label = "kota_nama";
      }
      setInput({
        ...input,
        [name]: evt.value,
        [label]: evt.label,
      });
    } else {
      let name = evt.target.name;
      value = evt.target.value;
      const re = /^[0-9\b]+$/;
      let newName = [
        "tinggi",
        "berat",
        "nilai_casting",
        "fax",
        "telepon_1",
        "telepon_2",
        "hp_1",
        "hp_2",
        "telepon_manajer",
        "telepon_manajemen_1",
        "telepon_manajemen_2",
        "telepon_agency_1",
        "telepon_agency_2",
      ];

      if (newName.includes(name)) {
        if (value === "" || re.test(value)) {
          setInput({
            ...input,
            [evt.target.name]: value,
          });
        }
      } else {
        if (name === "foto") {
          value = evt.target.files[0];
          setImage(URL.createObjectURL(value));
        }
        setInput({
          ...input,
          [evt.target.name]: value,
        });
      }
    }
  }

  useEffect(() => {
    const mandatoryInput = input.nama_ktp && input.id_ktp && input.email_bp;

    if (mandatoryInput) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [inputNpwp, input]);

  async function submit() {
    if (!input.nama_artis) {
      return setMessage({
        type: "danger",
        message: "Nama artis tidak boleh kosong",
        show: "d-block",
      });
    }

    const data = new FormData();
    data.append("foto", input.foto);
    data.append("nama_artis", input.nama_artis);
    data.append("status_pernikahan", input.status_pernikahan);
    data.append("alamat", input.alamat);
    data.append("telepon_1", input.telepon_1);
    data.append("nama_ktp", input.nama_ktp);
    data.append("id_ktp", input.id_ktp);
    data.append("email_bp", input.email_bp);
    data.append("kewarganegaraan", input.kewarganegaraan);
    data.append("pekerjaan", input.pekerjaan);
    data.append("kecamatan", input.kecamatan);
    data.append("kel_desa", input.kel_desa);
    data.append("rw", input.rw);
    data.append("rt", input.rt);
    data.append("gol_darah", input.gol_darah);
    data.append(
      "gender",
      input.jenis_kelamin.toLowerCase() === "perempuan"
        ? 2
        : input.jenis_kelamin.toLowerCase() === "laki-laki"
        ? 1
        : null
    );
    data.append("tempat_lahir", input.tempat_lahir);
    data.append("agama", input.agama);
    data.append("tanggal_lahir", input.tgl_lahir);
    data.append("status_pernikhan", input.status_pernikhan);
    data.append("email", input.email);
    data.append("tax", JSON.stringify(inputNpwp));

    const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
    axios
      .post(`${urlMicroservice}talent/artis-sp/create`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setMessage({
          type: "success",
          message: JSON.stringify(res.data.message),
          show: "d-block",
        });

        setInput({
          foto: "",
          nama_artis: "",
          status_pernikahan: "",
          alamat: "",
          telepon_1: "",
          nama_ktp: "",
          id_ktp: "",
          email_bp: "",
          kewarganegaraan: "",
          pekerjaan: "",
          kecamatan: "",
          kel_desa: "",
          rw: 0,
          rt: 0,
          gol_darah: "",
          jenis_kelamin: "",
          tempat_lahir: "",
          agama: "",
          tgl_lahir: "",
          status_pernikhan: "",
          erp_id: 0,
          id_dbtalent: 0,
          email: "",
        });
        setImage("/images/avatar.png");
        setInputNpwp([{ nama_npwp: "", npwp: "", alamat_npwp: "" }]);
        prop.setShow(false);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }

  function getDataSupplierBYNik() {
    getDataSupplierByNik(
      { nik: input.id_ktp },
      token,
      ({ data, isFetching, isFound, isError }) => {
        if (data) {
          const items = Array.isArray(data.data) ? data.data[0] : null;

          if (items) {
            setInput({
              ...input,
              nama_ktp: items.supplier_name,
              status_pernikahan: items.status_perkawinan,
              agama: items.agama,
              tgl_lahir: items.tanggal_lahir,
              tempat_lahir: items.tempat_lahir,
              jenis_kelamin:
                items.jenis_kelamin == "L"
                  ? "Laki-laki"
                  : items.jenis_kelamin == "P"
                  ? "Perempuan"
                  : "",
              provinsi: items.provinsi,
              kel_desa: items.kelurahan,
              kecamatan: items.kecamatan,
              rt_rw: items.rt_rw,
              pekerjaan: items.pekerjaan,
              kewarganegaraan: items.kewarganegaraan,
              alamat: items.alamat,
            });
            let dataNpwp = [];
            items.data_npwp.forEach((element) => {
              if (!element.npwp) {
                dataNpwp.push({
                  nama_npwp: element.nama,
                  npwp: element.npwp,
                  alamat_npwp: element.alamat,
                });
              }
            });

            setInputNpwp(dataNpwp);
          }
        }
      }
    );
  }

  const handlegetDataNpwp = (noNpwp, key) => {
    getDataNpwp({ no_npwp: noNpwp }, token, ({ data }) => {
      if (data) {
        const datanpwp = {
          npwp: noNpwp,
          nama_npwp: "",
          alamat_npwp: "",
        };

        if (Array.isArray(data.data)) {
          const validData = data.data.find((element) => element.nama !== "");
          if (validData) {
            datanpwp.nama_npwp = validData.nama;
            datanpwp.alamat_npwp = validData.alamat;
          }
        }

        setInputNpwp((prev) =>
          prev.map((item, idx) =>
            idx === key ? { ...item, ...datanpwp } : item
          )
        );
      }
    });
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <Modal show={prop.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Tambah Artist SP</Modal.Title>
      </Modal.Header>
      <Modal.Body className="my-2">
        <Grid.Row>
          <Grid.Col width={12}></Grid.Col>

          <Grid.Col className="mb-4" width={8}>
            <div className="mb-1">Foto Profil</div>
            <Image src={image} className="profil-icon" />
            <input
              type="file"
              id="foto"
              name="foto"
              ref={inputFile}
              onChange={handleChange}
              style={{ display: "none" }}
              accept="image/png, image/jpeg"
            />
            <Button
              variant="outline-danger"
              className="mr-3 ml-5"
              onClick={onButtonClick}
              type="button"
            >
              Pilih Foto
            </Button>
          </Grid.Col>

          <Grid.Col className="mb-4" width={8}>
            <FormTextInput
              name="id_ktp"
              label="Nomor Identitas"
              placeholder="Masukkan Nomor Identitas"
              value={input.id_ktp}
              onChange={handleChange}
            />
          </Grid.Col>

          {input.id_ktp && (
            <Grid.Col md={2} width={2} className="button-get-data">
              <div>
                <Button
                  variant="outline-danger"
                  onClick={() => getDataSupplierBYNik()}
                >
                  Get Data
                </Button>
              </div>
            </Grid.Col>
          )}

          <Grid.Col className="mb-4" width={12}>
            <FormTextInput
              name="nama_artis"
              label="Nama Artis"
              placeholder="Masukkan Nama Artis"
              value={input.nama_artis}
              onChange={handleChange}
            />
          </Grid.Col>

          <Grid.Col className="mb-4" width={6}>
            <FormTextInput
              name="nama_ktp"
              disabled
              label="Nama Sesuai Identitas"
              value={input.nama_ktp}
              onChange={handleChange}
            />
          </Grid.Col>

          <Grid.Col className="mb-4" width={6}>
            <FormTextInput
              disabled
              name="status_pernikahan"
              label="Status Pernikahan"
              value={input.status_pernikahan}
              onChange={handleChange}
            />
          </Grid.Col>
          <Grid.Col className="mb-4" width={6}>
            <FormTextInput
              disabled
              name="agama"
              label="Agama"
              value={input.agama}
              onChange={handleChange}
            />
          </Grid.Col>
          <Grid.Col className="mb-4" width={6}>
            <FormTextInput
              disabled
              name="Tanggal Lahir"
              label="Tanggal Lahir"
              value={input.tgl_lahir}
              onChange={handleChange}
            />
          </Grid.Col>

          <Grid.Col className="mb-4" width={6}>
            <FormTextInput
              disabled
              name="tempat_lahir"
              label="Tempat Lahir"
              value={input.tempat_lahir}
              onChange={handleChange}
            />
          </Grid.Col>

          <Grid.Col className="mb-4" width={6}>
            <FormTextInput
              disabled
              name="jenis_kelamin"
              label="Jenis Kelamin"
              value={input.jenis_kelamin}
              onChange={handleChange}
            />
          </Grid.Col>

          <Grid.Col className="mb-4" width={6}>
            <FormTextInput
              disabled
              name="provinsi"
              label="Provinsi"
              value={input.provinsi}
              onChange={handleChange}
            />
          </Grid.Col>

          <Grid.Col className="mb-4" width={6}>
            <FormTextInput
              disabled
              name="kel_desa"
              label="Kel/Desa"
              value={input.kel_desa}
              onChange={handleChange}
            />
          </Grid.Col>

          <Grid.Col className="mb-4" width={6}>
            <FormTextInput
              disabled
              name="kecamatan"
              label="Kecamatan"
              value={input.kecamatan}
              onChange={handleChange}
            />
          </Grid.Col>

          <Grid.Col className="mb-4" width={6}>
            <FormTextInput
              disabled
              name="rt_rw"
              label="RT/RW"
              value={input.rt_rw}
              onChange={handleChange}
            />
          </Grid.Col>

          <Grid.Col className="mb-4" width={6}>
            <FormTextInput
              disabled
              name="pekerjaan"
              label="Pekerjaan"
              value={input.pekerjaan}
              onChange={handleChange}
            />
          </Grid.Col>

          <Grid.Col className="mb-4" width={6}>
            <FormTextInput
              disabled
              name="kewarganegaraan"
              label="Kewarganegaraan"
              value={input.kewarganegaraan}
              onChange={handleChange}
            />
          </Grid.Col>

          <Grid.Col className="mb-4" width={12}>
            <Form.Group label={<Form.Label children="Alamat" />}>
              <Form.Textarea
                disabled
                name="alamat"
                rows={3}
                value={input.alamat}
                onChange={handleChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col className="mb-4" width={6}>
            <FormTextInput
              name="telepon_1"
              label="Telepon "
              placeholder="Masukkan Telepon"
              value={input.telepon_1 || ""}
              onChange={handleChange}
            />
          </Grid.Col>

          <Grid.Col className="mb-4" width={6}>
            <FormTextInput
              name="email"
              label="Email"
              type="email"
              placeholder="Masukkan Email"
              value={input.email}
              onChange={handleChange}
              onBlur={(e) => {
                if (input.email_bp == "")
                  setInput({
                    ...input,
                    email_bp: e.target.value,
                  });
              }}
            />
          </Grid.Col>

          <Grid.Col className="mb-4" width={12}>
            <FormTextInput
              name="email_bp"
              label="Email BP"
              type="email"
              placeholder="Masukkan Email BP"
              value={input.email_bp}
              onChange={handleChange}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Col width={12}>
          <Grid.Col width={2}>
            <Image src="/images/icon/npwp.png" className="artis-icon" />
            <h4 className="font-weight-bold mt-4">NPWP</h4>
          </Grid.Col>
          {inputNpwp.map((data, index) => (
            <Grid.Row
              className="mt-4 align-items-center"
              ey={`${data}~${index}`}
            >
              <Grid.Col className="mb-4" width={8}>
                <FormTextInput
                  name="npwp"
                  label="NPWP"
                  placeholder="Masukkan NPWP"
                  value={data.npwp}
                  onChange={(event) => handleInputNpwpChange(index, event)}
                />
              </Grid.Col>
              {data.npwp && (
                <Grid.Col className="button-get-data" width={2}>
                  <div>
                    <Button
                      variant="outline-danger"
                      onClick={() => handlegetDataNpwp(data.npwp, index)}
                    >
                      Get Data
                    </Button>
                  </div>
                </Grid.Col>
              )}

              <Grid.Col className="mb-4" width={12}>
                <FormTextInput
                  name="nama_npwp"
                  label="Nama NPWP"
                  disabled
                  value={data.nama_npwp}
                  onChange={(event) => handleInputNpwpChange(index, event)}
                />
              </Grid.Col>

              <Grid.Col className="mb-4" width={12}>
                <Form.Group label={<Form.Label children="Alamat" />}>
                  <Form.Textarea
                    name="alamat_npwp"
                    rows={3}
                    disabled
                    value={data.alamat_npwp}
                    onChange={(event) => handleInputNpwpChange(index, event)}
                  />
                </Form.Group>
              </Grid.Col>
              {index == 0 ? (
                ""
              ) : (
                <Grid.Col className="mb-4" width={1}>
                  <Button
                    variant="none"
                    onClick={() => handleRemoveNpwpFields(index)}
                  >
                    <i className="bx bxs-trash text-danger fa-2x"></i>
                  </Button>
                </Grid.Col>
              )}
            </Grid.Row>
          ))}
          <div className="mb-3">
            <Button
              variant="outline-danger"
              onClick={() => handleAddNpwpFields()}
            >
              <i className="bx bx-plus"></i> Tambah NPWP
            </Button>
          </div>
        </Grid.Col>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={handleClose}>
          Batal
        </Button>
        <Button variant="danger" onClick={() => submit()} disabled={disabled}>
          Simpan
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TambahArtistSp;
