// @flow

import React, { useContext, useEffect, useState } from "react";
import AlertCustom from "../../Components/Modal/AlertCustomComponent";
import { ContextAlert } from "../../Context/Alert";
import { ContextPermission } from "../../Context/Permission";

// import type { NotificationProps } from "tabler-react";
import LayoutApp from "./LayoutApp";

type Props = {|
  +children: React.Node,
|};

// type State = {|
//   notificationsObjects: Array<NotificationProps>,
// |};

type navItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
|};

function SiteWrapper(props: Props) {

  const navbar = [
    {
      id: "overview",
      value: "Overview",
      to: process.env.REACT_APP_FRONTEND_PM_URL + props.title_id + "/overview",
      // icon: "bxs-id-card",
    },
    {
      id: "time_table",
      value: "Time Table",
      to: process.env.REACT_APP_FRONTEND_PM_URL + props.title_id + "/timetable",
      // icon: "bxs-user-circle",
    },
    {
      id: "budgeting",
      value: "Budgeting",
      to: process.env.REACT_APP_FRONTEND_PM_URL + props.title_id + "/budgeting",
      // icon: "bxs-bookmarks",
    },
    {
      id: "casting",
      value: "Casting",
      to: `/casting/${props.title_id}`,
      // icon: "bxs-film",
    },
    {
      id: "bagan",
      value: "Bagan",
      to: `/bagan/${props.title_id}`,
      // icon: "bxs-group",
    },
    {
      id: "scenario",
      value: "Skenario",
      to: process.env.REACT_APP_FRONTEND_PM_URL + props.title_id + "/scenario",
      // icon: "bxs-file",
    },
    {
      id: "plan_shooting_harian",
      value: "Plan Shooting Harian",
      to: process.env.REACT_APP_FRONTEND_PM_URL + props.title_id + "/daily-shooting-plan",
      // icon: "bxs-file",
    },
    {
      id: "project_management",
      value: "Project Management",
      to: process.env.REACT_APP_FRONTEND_PM_URL + props.title_id + "/project-management",
      // icon: "bxs-file",
    },
    {
      id: "laporan",
      value: "Laporan ",
      to: process.env.REACT_APP_FRONTEND_PM_URL + props.title_id + "/report",
      // icon: "bxs-file",
    },
  ];

  const statePermission = useContext(ContextPermission);
  const [message, setMessage] = useContext(ContextAlert);
  const [navBarItems, setNavBarItems] = useState([]);

  const checkIfBaganAndCastingActive = () => {
    const data = { ...statePermission.permission.bagan, ...statePermission.permission.casting };
    const dataArray = Object.values(data);
    const hasActiveOne = dataArray.some(item => item.active === 1);
    return hasActiveOne;
  };

  useEffect(() => {
    let filteredArray = Object.entries(
      statePermission.detail_judul_menu
    ).filter(function (item) {
      return item[1].active === 1;
    });

    let filteredNavbar = filteredArray.map((item) => {
      return item[0];
    });

    let newFilteredNavbar = navbar.filter(function (item) {
      return filteredNavbar.includes(item.id);
    });

    if (checkIfBaganAndCastingActive()) {
      newFilteredNavbar = newFilteredNavbar.filter(item => item.id === 'casting' || item.id === 'bagan');
    }
    
    setNavBarItems(newFilteredNavbar);
  }, [props]);

  return (
    <LayoutApp
      navBarItems={navBarItems}
      breadcrumbItems={props.breadcrumbItems}
      title={props.title}
      linkBack={props.linkBack}
    >
      <AlertCustom
        setMessage={setMessage}
        showError={message.show}
        message={message.message}
        type={message.type}
      />
      {props.children}
    </LayoutApp>
  );
}

export default SiteWrapper;
