import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { Card } from "tabler-react";
import { DatePicker } from "antd";
import moment from "moment";
import axios from "axios";
import Loader from "react-loader-spinner";
import { ContextAlert } from "../../Context/Alert";

function AvailabilityCard(props) {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [availability, setAvailability] = useState(0);
  const [editable, setEditable] = useState(0);
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [message, setMessage] = useContext(ContextAlert);

  function changeAvailabilityDate() {
    setEditable(1);
  }

  function cancelAvailabilityDate() {
    setEditable(0);
  }

  useEffect(() => {
    setDate({
      startDate: props.data.dari_tanggal
        ? moment(props.data.dari_tanggal)
        : null,
      endDate: props.data.sampai_tanggal
        ? moment(props.data.sampai_tanggal)
        : null,
    });
    setAvailability(props.data.availability);
  }, [props.data]);

  function submit(availability) {
    if (!date.startDate || !date.endDate) {
      setMessage({
        type: "danger",
        message: "Tanggal mulai dan tanggal selesai harus diisi",
        show: "d-block",
      });
    } else {
      const data = {
        id: props.data.id,
        availability: availability,
        dari_tanggal: moment(date.startDate).format("DD/MM/YYYY"),
        sampai_tanggal: moment(date.endDate).format("DD/MM/YYYY"),
      };
      axios
        .post(`${urlMicroservice}talent/artis/availability`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setEditable(0);
        })
        .catch((e) => {
          var message = e.response.data.message;
          if (!message) {
            message = e.message;
          }
          setMessage({
            type: "danger",
            message: JSON.stringify(message),
            show: "d-block",
          });
        });
    }
  }

  function changeAvailability(evt) {
    let checked = evt.target.checked;
    if (checked === true) {
      setAvailability(1);
      setEditable(0);
      submit(true);
    } else {
      setAvailability(0);
      setEditable(1);
    }
  }

  const disabledDate = (endValue) => {
    const startValue = date.startDate;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  return (
    <Card className="border-card line-warning mt-5">
      <Card.Body className="p-4">
        <h4 className="font-weight-bold mb-4">Availability</h4>
        <div className="d-flex">
          <div>
            Artis ini{" "}
            {availability == 1 ? (
              <span class="text-success font-weight-bold">Available</span>
            ) : (
              <span class="text-danger font-weight-bold">Tidak Available</span>
            )}
          </div>
          <Form className="ml-auto">
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={availability == 1 ? true : false}
              onChange={changeAvailability}
            />
          </Form>
        </div>
        {availability == 0 ? (
          editable == 1 ? (
            <div>
              <div className={"mt-3"}>
                <div className="text-grey">Dari</div>
                <DatePicker
                  className={"form-control"}
                  onChange={(dates) => {
                    if (moment(date.endDate, "DD/MM/YYYY").isBefore(dates)) {
                      setDate({ startDate: dates, endDate: dates });
                    } else {
                      setDate((prevState) => ({
                        ...prevState,
                        startDate: dates,
                      }));
                    }
                  }}
                  value={
                    date.startDate ? moment(date.startDate, "DD/MM/YYYY") : ""
                  }
                  format="DD/MM/YYYY"
                />
                <div className="text-grey">Hingga</div>
                <DatePicker
                  className={"form-control"}
                  onChange={(dates) =>
                    setDate((prevState) => ({ ...prevState, endDate: dates }))
                  }
                  value={date.endDate ? moment(date.endDate, "DD/MM/YYYY") : ""}
                  disabledDate={disabledDate}
                  format="DD/MM/YYYY"
                />
              </div>
              <div className={"mt-4"}>
                <Button
                  variant="danger"
                  onClick={() => submit(false)}
                  className="mr-3 py-0 px-3"
                  type="button"
                >
                  Simpan
                </Button>
                <Button
                  variant="outline-danger"
                  onClick={cancelAvailabilityDate}
                  className="py-0 px-3"
                  type="button"
                >
                  Batal
                </Button>
              </div>
            </div>
          ) : (
            <div className={"my-3"}>
              <div className="mb-2">
                <div className="text-grey">Dari</div>
                <span className="font-weight-bold ">
                  {date.startDate ? moment(date.startDate).format("LL") : ""}
                </span>
              </div>
              <div>
                <div className="text-grey">Hingga</div>
                <span className="font-weight-bold">
                  {date.endDate ? moment(date.endDate).format("LL") : ""}
                </span>
              </div>
              <Button
                variant="light"
                className="mt-4"
                onClick={changeAvailabilityDate}
                type="button"
              >
                <i class="bx bxs-edit-alt"></i> Ubah
              </Button>
            </div>
          )
        ) : (
          ""
        )}
      </Card.Body>
    </Card>
  );
}
export default AvailabilityCard;
