import { API_CREATE } from "../Utils/api-list";
import ServerManager from "../Utils/server-manager";

export const GetArtis = (params, body, token, callback) => {
  const queryString = new URLSearchParams(params).toString();
  const options = {
    url: API_CREATE.getArtis(queryString),
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  ServerManager().post(options, (res) => {
    callback(res);
  });
};

export const GetKandidatDanArtis = (params, body, token, callback) => {
  const queryString = new URLSearchParams(params).toString();
  const options = {
    url: API_CREATE.GetKandidatDanArtis(queryString),
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  ServerManager().post(options, (res) => {
    callback(res);
  });
};

export const GetArtisDetail = (params, token, callback) => {
  const queryString = new URLSearchParams(params).toString();
  const options = {
    url: API_CREATE.getArtisDetail(queryString),
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  ServerManager().get(options, (res) => {
    callback(res);
  });
};

export const GetArtisNPWP = (params, token, callback) => {
  const queryString = new URLSearchParams(params).toString();
  const options = {
    url: API_CREATE.getArtisNpwp(queryString),
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  ServerManager().get(options, (res) => {
    callback(res);
  });
};

export const GetArtisBiografi = (params, token, callback) => {
  const queryString = new URLSearchParams(params).toString();
  const options = {
    url: API_CREATE.getArtisBiografi(queryString),
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  ServerManager().get(options, (res) => {
    callback(res);
  });
};

export const UpdateArtisNPWP = ({ body, token, artisId }, callback) => {
  const options = {
    url: API_CREATE.updateArtisNpwp(artisId),
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  new ServerManager().post(options, (res) => {
    callback(res);
  });
};

export const UpdateArtisBiografi = ({ body, token, params }, callback) => {
  const queryString = new URLSearchParams(params).toString();
  const options = {
    url: API_CREATE.updateArtisBiografi(queryString),
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  new ServerManager().post(options, (res) => {
    callback(res);
  });
};

export const GetArtisPhoto = (params, token, callback) => {
  const queryString = new URLSearchParams(params).toString();
  const options = {
    url: API_CREATE.getArtisPhoto(queryString),
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  ServerManager().get(options, (res) => {
    callback(res);
  });
};

export const GetArtisVideo = (params, token, callback) => {
  const queryString = new URLSearchParams(params).toString();
  const options = {
    url: API_CREATE.getArtisVideo(queryString),
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  ServerManager().get(options, (res) => {
    callback(res);
  });
};

export const getDataSupplierByNik = (params, token, callback) => {
  let data = JSON.stringify(params);
  const options = {
    url: API_CREATE.getSupplierDataByNIk(data),
    body: data,
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    maxBodyLength: Infinity,
  };
  ServerManager().post(options, (res) => {
    callback(res);
  });
};

export const getDataNpwp = (params, token, callback) => {
  let data = JSON.stringify(params);
  const options = {
    url: API_CREATE.getDataNpwp(data),
    body: data,
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    maxBodyLength: Infinity,
  };
  ServerManager().post(options, (res) => {
    callback(res);
  });
};

export const apiValidationData = (params, token, callback) => {
  let data = JSON.stringify(params);
  const options = {
    url: API_CREATE.validationData(data),
    body: data,
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    maxBodyLength: Infinity,
  };
  ServerManager().post(options, (res) => {
    callback(res);
  });
};

export const GetArtisSpBiografi = (params, token, callback) => {
  const queryString = new URLSearchParams(params).toString();
  const options = {
    url: API_CREATE.getArtisSpBiografi(queryString),
    header: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  ServerManager().get(options, (res) => {
    callback(res);
  });
};
