import React, { useContext } from "react";

import {
  Button,
  Image,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import { Card, Badge, Grid } from "tabler-react";
import { Link } from "react-router-dom";
import { ContextPermission } from "../../Context/Permission";
import HapusArtisSp from "./HapusArtisSp";
import JadikanArtis from "./JadikanArtisCardComponent";
import EditArtistSp from "./EditArtistSp";

export const ArtistSpCard = (prop) => {
  const statePermission = useContext(ContextPermission);
  const shouldShowTooltip =
    getLatestCatatanLog(prop.item.catatan_new) &&
    getLatestCatatanLog(prop.item.catatan_new) !== "-" &&
    getLatestCatatanLog(prop.item.catatan_new) > 20;

  function getLatestCatatanLog(catatanArray) {
    if (!catatanArray || catatanArray.length === 0) {
      return null;
    }
    const data = catatanArray.reduce((latest, current) => {
      return new Date(current.catatan_log) > new Date(latest.catatan_log)
        ? current
        : latest;
    });

    return data?.catatan;
  }

  return (
    <Card className="card-rounded">
      <Card.Body className="p-0 ">
        <Link to={"/artist-sp-detail/" + prop.item.id}>
          <Image src={prop.item.foto} className="img-rounded img-list-artis" />
        </Link>
        <div className="p-3">
          <Link to={"/artist-sp-detail/" + prop.item.id}>
            <OverlayTrigger
              key={"top"}
              placement={"top-start"}
              overlay={
                <Tooltip id={"tooltip-name"}>{prop.item.nama_artis}.</Tooltip>
              }
            >
              <h4 className="font-weight-bold text-truncate">
                {prop.item.nama_artis}
              </h4>
            </OverlayTrigger>
            <div>
              {prop.item.usia} &#183;{" "}
              {prop.item.gender === 1 ? "Laki-Laki" : "Perempuan"}
            </div>

            <div className="mt-3">
              {prop?.item?.availability === 1 ? (
                <Badge color="success" className="mr-1 p-2">
                  Available
                </Badge>
              ) : (
                <Badge color="danger" className="mr-1 p-2">
                  Not Available
                </Badge>
              )}
            </div>

            <div className="mt-3">
              <div>
                <i class="bx bxs-camera-movie text-danger"></i>{" "}
                {prop.item.is_exclusive === 1 ? "Eksklusif" : "Non Eksklusif"}
              </div>
              <div className="mt-2">
                <i class="bx bxs-buildings text-warning"></i>{" "}
                {prop.item.manajemen}
              </div>
              <div className="mt-2">
                {shouldShowTooltip ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {getLatestCatatanLog(prop.item.catatan_new)}
                      </Tooltip>
                    }
                  >
                    <p className="ellipsis-text">
                      <i className="bx bxs-notepad text-dark"></i>{" "}
                      {getLatestCatatanLog(prop.item.catatan_new)}
                    </p>
                  </OverlayTrigger>
                ) : (
                  <p className="ellipsis-text">
                    <i className="bx bxs-notepad text-dark"></i>{" "}
                    {getLatestCatatanLog(prop.item.catatan_new)}
                  </p>
                )}
              </div>
            </div>
          </Link>

          {prop.button ? (
            <Grid.Row className="mt-3">
              <Grid.Col className="mb-1" width="12">
                {statePermission.permission.kandidat.jadikan_artis.active ===
                1 ? (
                  <JadikanArtis
                    dataProvinsi={prop.dataProvinsi}
                    data={prop.item}
                  ></JadikanArtis>
                ) : (
                  ""
                )}
                {statePermission.permission.artis_sp.edit_biografi_sp.active ===
                1 ? (
                  <Dropdown className="artis w-100 mt-3">
                    <Dropdown.Toggle bsPrefix=" ">
                      <i className="fa fa-ellipsis-h"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="px-3">
                        <EditArtistSp
                          data={prop.item}
                          fetch={() => {
                            prop.fetch();
                          }}
                        />
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}
              </Grid.Col>
            </Grid.Row>
          ) : (
            ""
          )}
        </div>
      </Card.Body>
    </Card>
  );
};
