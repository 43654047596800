import React from "react";

import { Button } from "react-bootstrap";
import { Card } from "tabler-react";

function RiwayatFilterCard(props) {
  return (
    <Card className="border-card mt-5">
      <Card.Body>
        <h5 className="font-weight-bold">{props.item.waktu.tanggal}</h5>
        <div className="filter-data">
          <div className="d-flex justify-content-between align-items-center">
            <span>{props.item.waktu.jam}</span>
            {props.dropdown}
          </div>
          <div className="d-flex align-items-center my-1 pb-2 overflow-auto">
            {props.item.config &&
              props.item.config.map((item) => {
                return (
                  <Button
                    variant="outline-dark"
                    className="filter-button"
                    type="button"
                  >
                    {item}
                  </Button>
                );
              })}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
export default RiwayatFilterCard;
