import React, { useState } from "react";
import { Modal, Button, Row, Col, Image } from "react-bootstrap";
import axios from "axios";
import AlertCustom from "../../Components/Modal/AlertCustomComponent";

const AlertDelete = (props) => {
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [urlImageAvatar] = useState(
    process.env.REACT_APP_FRONTEND_URL + "images/delete.png"
  );
  const [message, setMessage] = useState({
    type: "",
    message: "",
    show: "d-none",
  });
  const [disable, setDisable] = useState(false);

  const style = {
    cursorPointer: {
      cursor: "pointer",
    },
  };

  const handleButtonDelete = () => {
    axios
      .delete(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `talent/title/delete/${props.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setShow(false);
        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  };

  return (
    <>
      <span
        className="p-3 font-weight-bold"
        style={style.cursorPointer}
        onClick={handleShow}
      >
        X
      </span>
      <Modal show={show} onHide={handleClose}>
        <AlertCustom
          setMessage={setMessage}
          showError={message.show}
          message={message.message}
          type={message.type}
        ></AlertCustom>
        <Modal.Body>
          <Row className="my-5">
            <Col sm="12" className="text-center">
              <Image
                src={urlImageAvatar}
                roundedCircle
                style={{ height: "100px", width: "100px" }}
              />
            </Col>
            <Col sm="12" className="text-center mt-5">
              <p>Hapus Judul {props.name}?</p>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col sm="6" className="text-right">
              <Button
                variant="outline-danger"
                className="px-5"
                onClick={handleClose}
              >
                Batal
              </Button>
            </Col>
            <Col sm="6">
              <Button
                variant="danger"
                className="px-5"
                onClick={handleButtonDelete}
              >
                Hapus
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AlertDelete;
