import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import { FormTextInput, Form } from "tabler-react";
import axios from "axios";
import { ContextAlert } from "../../Context/Alert";
import Select from "react-select";

const Catatan = (prop) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useContext(ContextAlert);
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [status, setStatus] = useState({});

  const dataStatus = [
    {
      value: "delete",
      label: "Hapus Catatan",
    },
    {
      value: "insert",
      label: "Tambahkan Catatan",
    },
  ];

  const handleClose = () => {
    setStatus({});
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const onSubmit = async (data) => {
    data.preventDefault();
    try {
      const response = await axios.post(
        `${urlMicroservice}talent/bagan/catatan-artist/${prop.id}`,
        {
          type: status?.value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessage({
        type: "success",
        message: `Berhasil mengubah data`,
        show: "d-block",
      });
      setStatus({});
      setShow(false);
    } catch (e) {
      let message;

      if (e.response && e.response.data && e.response.data.message) {
        const errorData = e.response.data.message;

        // Cek jika message berbentuk objek dan memiliki status key
        if (typeof errorData === "object") {
          // Jika error berbentuk objek dengan format { status: { key: value } }, ambil value
          message = Object.values(errorData).flat().join(", "); // Gabungkan semua pesan error jadi satu string
        } else {
          message = errorData; // Jika hanya string, gunakan langsung
        }
      } else {
        message = e.message || "An error occurred";
      }

      setMessage({
        type: "danger",
        message: message, // Jangan gunakan JSON.stringify di sini agar pesan lebih mudah dibaca
        show: "d-block",
      });
    }
  };

  const handleInputStatus = (item) => {
    setStatus(item);
  };

  return (
    <>
      <Dropdown.Item className="text-dark" onClick={handleShow}>
        Catatan
      </Dropdown.Item>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Catatan Artist</Modal.Title>
        </Modal.Header>
        <form onSubmit={onSubmit}>
          <Modal.Body className="my-2">
            <div>
              <label>Catatan</label>
              <Select
                name="Pilih Status Artis"
                options={dataStatus}
                onChange={(event) => handleInputStatus(event)}
                value={status}
                placeholder="Pilih Status Artis"
                isMulti={false}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              type="button"
              onClick={handleClose}
            >
              Batal
            </Button>
            <Button variant="danger" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default Catatan;
