import React,{useEffect} from "react";

import { Alert } from "tabler-react";

function AlertCustom(props) {

  function alertClose(event){
    props.setMessage({
      message:null,
      show:'d-none'
    });
  }

  useEffect(() => {
    if(props.showError!=='d-none'){
      setTimeout(() => {
        alertClose();
      }, 3000);
    }
  }, [props.showError]);     
    
  return (
    <Alert className={props.showError} type={props.type}>
      <button className="btn btn-icon close" onClick={alertClose}></button>
      {props.message}
    </Alert>
  )
}

export default AlertCustom;
