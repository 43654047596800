import { Divider } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { Button, Form, FloatingLabel } from "react-bootstrap";
import { Card } from "tabler-react";
import axios from "axios";
import { ContextPermission } from "../../Context/Permission";
import { ContextAlert } from "../../Context/Alert";
import AlertDeleteCatatan from "../Modal/AlertDeleteCatatan";

function CatatanCard(props) {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [editable, setEditable] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [log, setLog] = useState("");
  const [text, setText] = useState("");
  const [initText, setInitText] = useState("");
  const statePermission = useContext(ContextPermission);
  const [message, setMessage] = useContext(ContextAlert);
  const [idEdit, setIdEdit] = useState();

  // useEffect(() => {
  //   var value = props.data.catatan ? props.data.catatan : "";
  //   setInitText(value);
  //   setText(value);
  //   setLog(props.data.catatan_log);
  // }, [props.data]);

  const [buttonEdit, setButtonEdit] = useState();

  function handleChange(evt) {
    const value = evt.target.value;
    setText(value);
  }

  function cancelEdit() {
    setIdEdit();
    setEditable(false);
    setText("");
  }

  function handleSimpan() {
    if (idEdit) {
      updateCatatan();
    } else {
      saveEdit();
    }
  }

  function updateCatatan() {
    const data = {
      id: idEdit,
      note: text,
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${urlMicroservice}talent/artis/catatan-update`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        props.fetch();
        setText("");
        setEditable(false);
        setIdEdit(null);
      })
      .catch((error) => {
        console.log(error);
        var message = error.response?.data?.message || error.message;
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }

  function saveEdit() {
    const data = {
      id: props.data.id,
      catatan: text,
    };
    axios
      .post(`${urlMicroservice}talent/artis/catatan`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        props.fetch();
        setText("");
        setEditable(false);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }

  function butonEdit(id, value) {
    setIdEdit(id);
    setEditable(true);
    setText(value);
  }

  return (
    <Card className="border-card mt-5">
      <Card.Body className="p-4">
        <Form>
          <div className="d-flex align-items-center mb-4">
            <h4 className="font-weight-bold mb-0">Catatan</h4>
            {statePermission.permission.artis.tambah_catatan.active === 1 ? (
              <>
                {editable === true ? (
                  <div className="ml-auto">
                    <Button
                      variant="outline-danger"
                      onClick={() => cancelEdit()}
                      className="mr-3"
                      type="button"
                    >
                      Batal
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleSimpan()}
                      type="button"
                    >
                      Simpan
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="outline-danger"
                    className="ml-auto"
                    onClick={() => setEditable(true)}
                    type="button"
                  >
                    <i className="bx bxs-edit-alt"></i> Tambah Catatan
                  </Button>
                )}
              </>
            ) : (
              ""
            )}
          </div>
          {editable === true ? (
            <Form.Control
              as="textarea"
              name="catatan"
              onChange={handleChange}
              placeholder="Masukkan catatan disini"
              value={text}
              style={{ height: "100px" }}
            />
          ) : (
            <div>
              {props.data?.catatan_new?.length > 0 &&
                props.data.catatan_new
                  .sort(
                    (a, b) => new Date(a.catatan_log) - new Date(b.catatan_log)
                  )
                  .map((item, index) => (
                    <div key={index}>
                      <div className="__catatan_main">
                        <div>
                          {showMore || !item.catatan ? (
                            <p
                              className="pointer"
                              onClick={() => butonEdit(item.id, item.catatan)}
                            >
                              {item.catatan}
                            </p>
                          ) : (
                            <p
                              className="pointer"
                              onClick={() => butonEdit(item.id, item.catatan)}
                            >{`${item.catatan.substring(0, 150)}`}</p>
                          )}
                          {item.catatan && item.catatan.length > 150 ? (
                            <div>
                              <a
                                className="text-danger mt-2"
                                onClick={() => setShowMore(!showMore)}
                              >
                                {showMore
                                  ? "Tampilkan lebih sedikit"
                                  : "Tampilkan lebih banyak"}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                          {item.catatan ? (
                            <div className="text-grey mt-3">
                              Terakhir ditambahkan pada {item.catatan_log}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="__catatan_delete">
                          <AlertDeleteCatatan
                            id={item.id}
                            name={item.catatan}
                            fetch={() => props.fetch()}
                          />
                        </div>
                      </div>
                      {index < props.data.catatan_new.length - 1 && (
                        <hr className="my-4 border-t-2 border-gray-300" />
                      )}
                    </div>
                  ))}
            </div>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
}
export default CatatanCard;
