import React, { useState, useEffect } from "react";
import {Container, Row, Col, Card, Button, Form, Navbar, Accordion } from 'react-bootstrap'
import { Switch, Tabs, Checkbox, Empty, Form as FormAntd } from 'antd';
import { BsXLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Loader from 'react-loader-spinner';
import Fade from 'react-bootstrap/Fade'
import Collapse from 'react-bootstrap/Collapse'
import AlertCustom from "../../Components/Modal/AlertCustomComponent";
import {useHistory} from "react-router-dom";

function EditRole (props){
    let history                                       = useHistory();
    const [statusRole, setStatusRole]                 = useState('Aktif');
    const token                                       = localStorage.getItem('token');
    const [loader, setLoader]                         = useState(true);
    const [disable, setDisable]                       = useState(false);
    const [message, setMessage]                       = useState({type:'',message:'', show:'d-none'});
    const [inputNamaRole, setInputNamaRole]           = useState('');
    const [inputStatus, setInputStatus]               = useState(1);
    const [inputCheckedStatus, setInputCheckedStatus] = useState(false);
    const loginUrl                                    = process.env.REACT_APP_LOGIN_TOKEN_URL;

    // s.data
    const [dataIzinArtis, setDataIzinArtis]           = useState([]);
    const [dataIzinKandidat, setDataIzinKandidat]     = useState([]);
    const [dataIzinBookmark, setDataIzinBookmark]     = useState([]);
    const [dataIzinJudul, setDataIzinJudul]           = useState([]);
    const [dataIzinUser, setDataIzinUser]             = useState([]);
    const [dataIzinRole, setDataIzinRole]             = useState([]);
    const [dataIzinReport, setDataIzinReport]         = useState([]);
    const [dataIzinCrew, setDataIzinCrew]         = useState([]);

    const [aksesArtis, setAksesArtis]                 = useState('');
    const [aksesKandidat, setAksesKandidat]           = useState('');
    const [aksesBookmark, setAksesBookmark]           = useState('');
    const [aksesJudul, setAksesJudul]                 = useState('');
    const [aksesUser, setAksesUser]                   = useState('');
    const [aksesRole, setAksesRole]                   = useState('');
    const [aksesReport, setAksesReport]               = useState('');
    const [aksesCrew, setAksesCrew]               = useState('');

    const [openArtis, setOpenArtis]                   = useState(false);    
    const [openKandidat, setOpenKandidat]             = useState(false);    
    const [openBookmark, setOpenBookmark]             = useState(false);    
    const [openJudul, setOpenJudul]                   = useState(false);    
    const [openUser, setOpenUser]                     = useState(false);    
    const [openRole, setOpenRole]                     = useState(false);    
    const [openReport, setOpenReport]                 = useState(false);
    const [openCrew, setOpenCrew]                 = useState(false);

    const [switchArtis, setSwitchArtis]               = useState(false);
    const [switchKandidat, setSwitchKandidat]         = useState(false);
    const [switchBookmark, setSwitchBookmark]         = useState(false);
    const [switchJudul, setSwitchJudul]               = useState(false);
    const [switchUser, setSwitchUser]                 = useState(false);
    const [switchRole, setSwitchRole]                 = useState(false);
    const [switchReport, setSwitchReport]             = useState(false);
    const [switchCrew, setSwitchCrew]             = useState(false);

    // e.data 

    // s.data parent
    const [dataParentJudulOverview, setDataParentJudulOverview]                        = useState([]);
    const [dataParentJudulTimeTable, setDataParentJudulTimeTable]                      = useState([]);
    const [dataParentJudulBudgeting, setDataParentJudulBudgeting]                      = useState([]);
    const [dataParentJudulCasting, setDataParentJudulCasting]                          = useState([]);
    const [dataParentJudulBagan, setDataParentJudulBagan]                              = useState([]);
    const [dataParentJudulScenario, setDataParentJudulScenario]                        = useState([]);
    const [dataParentJudulPlanShootingHarian, setDataParentJudulPlanShootingHarian]    = useState([]);
    const [dataParentJudulProjectManagement, setDataParentJudulProjectManagement]      = useState([]);
    const [dataParentJudulLaporan, setDataParentJudulLaporan]                          = useState([]);

    const [aksesParentJudulOverview, setAksesParentJudulOverview]                      = useState('');
    const [aksesParentJudulTimeTable, setAksesParentJudulTimeTable]                    = useState('');
    const [aksesParentJudulBudgeting, setAksesParentJudulBudgeting]                    = useState('');
    const [aksesParentJudulCasting, setAksesParentJudulCasting]                        = useState('');
    const [aksesParentJudulBagan, setAksesParentJudulBagan]                            = useState('');
    const [aksesParentJudulScenario, setAksesParentJudulScenario]                      = useState('');
    const [aksesParentJudulPlanShootingHarian, setAksesParentJudulPlanShootingHarian]  = useState('');
    const [aksesParentJudulProjectManagement, setAksesParentJudulProjectManagement]    = useState('');
    const [aksesParentJudulLaporan, setAksesParentJudulLaporan]                        = useState('');

    const [openParentJudulOverview, setOpenParentJudulOverview]                        = useState(false);
    const [openParentJudulTimeTable, setOpenParentJudulTimeTable]                      = useState(false);
    const [openParentJudulBudgeting, setOpenParentJudulBudgeting]                      = useState(false);
    const [openParentJudulCasting, setOpenParentJudulCasting]                          = useState(false);
    const [openParentJudulBagan, setOpenParentJudulBagan]                              = useState(false);
    const [openParentJudulScenario, setOpenParentJudulScenario]                        = useState(false);
    const [openParentJudulPlanShootingHarian, setOpenParentJudulPlanShootingHarian]    = useState(false);
    const [openParentJudulProjectManagement, setOpenParentJudulProjectManagement]      = useState(false);
    const [openParentJudulLaporan, setOpenParentJudulLaporan]                          = useState(false);

    const [switchParentOverview, setSwitchParentOverview]                              = useState(false);
    const [switchParentTimeTable, setSwitchParentTimeTable]                            = useState(false);
    const [switchParentBudgeting, setSwitchParentBudgeting]                            = useState(false);
    const [switchParentCasting, setSwitchParentCasting]                                = useState(false);
    const [switchParentBagan, setSwitchParentBagan]                                    = useState(false);
    const [switchParentScenario, setSwitchParentScenario]                              = useState(false);
    const [switchParentPlanShootingHarian, setSwitchParentPlanShootingHarian]          = useState(false);
    const [switchParentProjectManagement, setSwitchParentProjectManagement]            = useState(false);
    const [switchParentLaporan, setSwitchParentLaporan]                                = useState(false);
    // e.data parent 

    const [checkboxArtis, setCheckboxArtis]         = useState([]);
    const [checkboxKandidat, setCheckboxKandidat]   = useState([]);
    const [checkboxBookmark, setCheckboxBookmark]   = useState([]);
    const [checkboxJudul, setCheckboxJudul]         = useState([]);
    const [checkboxUser, setCheckboxUser]           = useState([]);
    const [checkboxRole, setCheckboxRole]           = useState([]);
    const [checkboxReport, setCheckboxReport]       = useState([]);
    const [checkboxCrew, setCheckboxCrew]       = useState([]);
    
    const [checkboxParentJudulOverview, setCheckboxParentJudulOverview]                     = useState([]);
    const [checkboxParentJudulTimeTable, setCheckboxParentJudulTimeTable]                   = useState([]);
    const [checkboxParentJudulBudgeting, setCheckboxParentJudulBudgeting]                   = useState([]);
    const [checkboxParentJudulCasting, setCheckboxParentJudulCasting]                       = useState([]);
    const [checkboxParentJudulBagan, setCheckboxParentJudulBagan]                           = useState([]);
    const [checkboxParentJudulScenario, setCheckboxParentJudulScenario]                     = useState([]);
    const [checkboxParentJudulPlanShootingHarian, setCheckboxParentJudulPlanShootingHarian] = useState([]);
    const [checkboxParentJudulProjectManagement, setCheckboxParentJudulProjectManagement]   = useState([]);
    const [checkboxParentJudulLaporan, setCheckboxParentJudulLaporan]                       = useState([]);

    useEffect(() => {
        setDisable(true);
        axios.get(process.env.REACT_APP_BE_DBTALENT_URL+`user/roles/edit/`+props.match.params.idRole,
        { 
        headers: {
            'Authorization' : `Bearer ${token}`, 
            'Accept'        : 'application/json', 
            'Content-Type'  : 'application/json'
        }
        })
        .then((res) =>{
            // console.log(res);
            setInputNamaRole(res.data.data.model.name);
            // s.set data
            setAksesArtis(res.data.data.master_permission[0]);
            setAksesKandidat(res.data.data.master_permission[1]);
            setAksesBookmark(res.data.data.master_permission[2]);
            setAksesJudul(res.data.data.master_permission[3]);
            setAksesUser(res.data.data.master_permission[4]);
            setAksesRole(res.data.data.master_permission[5]);
            setAksesReport(res.data.data.master_permission[6]);
            setAksesCrew(res.data.data.master_permission[7]);

            setDataIzinArtis(res.data.data.model.permission.Artis);
            setDataIzinKandidat(res.data.data.model.permission.Kandidat);
            setDataIzinBookmark(res.data.data.model.permission.Bookmark);
            setDataIzinJudul(res.data.data.model.permission.Judul);
            setDataIzinUser(res.data.data.model.permission.User);
            setDataIzinRole(res.data.data.model.permission.Role);
            setDataIzinReport(res.data.data.model.permission.Report);
            setDataIzinCrew(res.data.data.model.permission.Crew);
            // e.set data

            // s.set data parent
            setAksesParentJudulOverview(res.data.data.master_permission[3].step_parent[0]);
            setAksesParentJudulTimeTable(res.data.data.master_permission[3].step_parent[1]);
            setAksesParentJudulBudgeting(res.data.data.master_permission[3].step_parent[2]);
            setAksesParentJudulCasting(res.data.data.master_permission[3].step_parent[3]);
            setAksesParentJudulBagan(res.data.data.master_permission[3].step_parent[4]);
            setAksesParentJudulScenario(res.data.data.master_permission[3].step_parent[5]);
            setAksesParentJudulPlanShootingHarian(res.data.data.master_permission[3].step_parent[6]);
            setAksesParentJudulProjectManagement(res.data.data.master_permission[3].step_parent[7]);
            setAksesParentJudulLaporan(res.data.data.master_permission[3].step_parent[8]);

            setDataParentJudulOverview(res.data.data.model.permission_judul.Overview);
            setDataParentJudulTimeTable(res.data.data.model.permission_judul.TimeTable);
            setDataParentJudulBudgeting(res.data.data.model.permission_judul.Budgeting);
            setDataParentJudulCasting(res.data.data.model.permission_judul.Casting);
            setDataParentJudulBagan(res.data.data.model.permission_judul.Bagan);
            setDataParentJudulScenario(res.data.data.model.permission_judul.Scenario);
            setDataParentJudulPlanShootingHarian(res.data.data.model.permission_judul.PlanShootingHarian);
            setDataParentJudulProjectManagement(res.data.data.model.permission_judul.ProjectManagement);
            setDataParentJudulLaporan(res.data.data.model.permission_judul.Laporan);
            // e.set data parent

            setCheckboxArtis(res.data.data.master_permission[0].child);
            setCheckboxKandidat(res.data.data.master_permission[1].child);
            setCheckboxBookmark(res.data.data.master_permission[2].child);
            setCheckboxJudul(res.data.data.master_permission[3].child);
            setCheckboxUser(res.data.data.master_permission[4].child);
            setCheckboxRole(res.data.data.master_permission[5].child);
            setCheckboxReport(res.data.data.master_permission[6].child);
            setCheckboxCrew(res.data.data.master_permission[7].child);

            res.data.data.model.status === "1" ? setInputCheckedStatus(true) : setInputCheckedStatus(false);

            res.data.data.master_permission[0].chaked === 1 ? setSwitchArtis(true) : setSwitchArtis(false);
            res.data.data.master_permission[1].chaked === 1 ? setSwitchKandidat(true) : setSwitchKandidat(false);
            res.data.data.master_permission[2].chaked === 1 ? setSwitchBookmark(true) : setSwitchBookmark(false);
            res.data.data.master_permission[3].chaked === 1 ? setSwitchJudul(true) : setSwitchJudul(false);
            res.data.data.master_permission[4].chaked === 1 ? setSwitchUser(true) : setSwitchUser(false);
            res.data.data.master_permission[5].chaked === 1 ? setSwitchRole(true) : setSwitchRole(false);
            res.data.data.master_permission[6].chaked === 1 ? setSwitchReport(true) : setSwitchReport(false);
            res.data.data.master_permission[7].chaked === 1 ? setSwitchCrew(true) : setSwitchCrew(false);

            res.data.data.master_permission[0].chaked === 1 ? setOpenArtis(true) : setOpenArtis(false);
            res.data.data.master_permission[1].chaked === 1 ? setOpenKandidat(true) : setOpenKandidat(false);
            res.data.data.master_permission[2].chaked === 1 ? setOpenBookmark(true) : setOpenBookmark(false);
            res.data.data.master_permission[3].chaked === 1 ? setOpenJudul(true) : setOpenJudul(false);
            res.data.data.master_permission[4].chaked === 1 ? setOpenUser(true) : setOpenUser(false);
            res.data.data.master_permission[5].chaked === 1 ? setOpenRole(true) : setOpenRole(false);
            res.data.data.master_permission[6].chaked === 1 ? setOpenReport(true) : setOpenReport(false);
            res.data.data.master_permission[7].chaked === 1 ? setOpenCrew(true) : setOpenCrew(false);
            
            res.data.data.master_permission[3].step_parent[0].chaked === 1 ? setSwitchParentOverview(true) : setSwitchParentOverview(false);
            res.data.data.master_permission[3].step_parent[1].chaked === 1 ? setSwitchParentTimeTable(true) : setSwitchParentTimeTable(false);
            res.data.data.master_permission[3].step_parent[2].chaked === 1 ? setSwitchParentBudgeting(true) : setSwitchParentBudgeting(false);
            res.data.data.master_permission[3].step_parent[3].chaked === 1 ? setSwitchParentCasting(true) : setSwitchParentCasting(false);
            res.data.data.master_permission[3].step_parent[4].chaked === 1 ? setSwitchParentBagan(true) : setSwitchParentBagan(false);
            res.data.data.master_permission[3].step_parent[5].chaked === 1 ? setSwitchParentScenario(true) : setSwitchParentScenario(false);
            res.data.data.master_permission[3].step_parent[6].chaked === 1 ? setSwitchParentPlanShootingHarian(true) : setSwitchParentPlanShootingHarian(false);
            res.data.data.master_permission[3].step_parent[7].chaked === 1 ? setSwitchParentProjectManagement(true) : setSwitchParentProjectManagement(false);
            res.data.data.master_permission[3].step_parent[8].chaked === 1 ? setSwitchParentLaporan(true) : setSwitchParentLaporan(false);

            res.data.data.master_permission[3].step_parent[0].chaked === 1 ? setOpenParentJudulOverview(true) : setOpenParentJudulOverview(false);
            res.data.data.master_permission[3].step_parent[1].chaked === 1 ? setOpenParentJudulTimeTable(true) : setOpenParentJudulTimeTable(false);
            res.data.data.master_permission[3].step_parent[2].chaked === 1 ? setOpenParentJudulBudgeting(true) : setOpenParentJudulBudgeting(false);
            res.data.data.master_permission[3].step_parent[3].chaked === 1 ? setOpenParentJudulCasting(true) : setOpenParentJudulCasting(false);
            res.data.data.master_permission[3].step_parent[4].chaked === 1 ? setOpenParentJudulBagan(true) : setOpenParentJudulBagan(false);
            res.data.data.master_permission[3].step_parent[5].chaked === 1 ? setOpenParentJudulScenario(true) : setOpenParentJudulScenario(false);
            res.data.data.master_permission[3].step_parent[6].chaked === 1 ? setOpenParentJudulPlanShootingHarian(true) : setOpenParentJudulPlanShootingHarian(false);
            res.data.data.master_permission[3].step_parent[7].chaked === 1 ? setOpenParentJudulProjectManagement(true) : setOpenParentJudulProjectManagement(false);
            res.data.data.master_permission[3].step_parent[8].chaked === 1 ? setOpenParentJudulLaporan(true) : setOpenParentJudulLaporan(false);

            setCheckboxParentJudulOverview(res.data.data.master_permission[3].step_parent[0].step_child);
            setCheckboxParentJudulTimeTable(res.data.data.master_permission[3].step_parent[1].step_child);
            setCheckboxParentJudulBudgeting(res.data.data.master_permission[3].step_parent[2].step_child);
            setCheckboxParentJudulCasting(res.data.data.master_permission[3].step_parent[3].step_child);
            setCheckboxParentJudulBagan(res.data.data.master_permission[3].step_parent[4].step_child);
            setCheckboxParentJudulScenario(res.data.data.master_permission[3].step_parent[5].step_child);
            setCheckboxParentJudulPlanShootingHarian(res.data.data.master_permission[3].step_parent[6].step_child);
            setCheckboxParentJudulProjectManagement(res.data.data.master_permission[3].step_parent[7].step_child);
            setCheckboxParentJudulLaporan(res.data.data.master_permission[3].step_parent[8].step_child);

            setLoader(false);
            setDisable(false);

            // console.log('edit role', res.data.data);
        })
        .catch(error =>{
            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })
    }, [token]);

    const submitEditRole = () =>{
        let permission = [];

        for(let i of dataIzinArtis){
            permission.push(i);
        }
        for(let i of dataIzinKandidat){
            permission.push(i);
        }
        for(let i of dataIzinBookmark){
            permission.push(i);
        }
        for(let i of dataIzinJudul){
            permission.push(i);
        }
        for(let i of dataIzinUser){
            permission.push(i);
        }
        for(let i of dataIzinRole){
            permission.push(i);
        }
        for(let i of dataIzinReport){
            permission.push(i);
        }
        for(let i of dataIzinCrew){
            permission.push(i);
        }
        
        for(let i of dataParentJudulOverview){
            permission.push(i);
        }
        for(let i of dataParentJudulTimeTable){
            permission.push(i);
        }
        for(let i of dataParentJudulBudgeting){
            permission.push(i);
        }
        for(let i of dataParentJudulCasting){
            permission.push(i);
        }
        for(let i of dataParentJudulBagan){
            permission.push(i);
        }
        for(let i of dataParentJudulScenario){
            permission.push(i);
        }
        for(let i of dataParentJudulPlanShootingHarian){
            permission.push(i);
        }
        for(let i of dataParentJudulProjectManagement){
            permission.push(i);
        }
        for(let i of dataParentJudulLaporan){
            permission.push(i);
        }

        let data = {
            permission,
            name : inputNamaRole,
            status : inputStatus,
        }

        if(inputNamaRole === ''){
            var message = 'Input Nama Role tidak boleh kosong';
            setMessage({
                type:'danger',
                message:message,
                show:'d-block'
            });
        }else{
            setDisable(true);
            axios.post(process.env.REACT_APP_BE_DBTALENT_URL+`user/roles/update/`+props.match.params.idRole, data,
            { 
            headers: {
                'Authorization' : `Bearer ${token}`, 
                'Accept'        : 'application/json', 
                'Content-Type'  : 'application/json'
            }
            })
            .then((res) =>{
                setMessage({
                    type:'success',
                    message:"Berhasil Mengubah Data Role",
                    show:'d-block'
                });
                setTimeout(() => {
                    history.push('/manajemen-role');
                }, 1000);
                })
            .catch(error =>{
                setDisable(false);
                var message = error.message;
                if(typeof error.response != "undefined"){
                    message = error.response.data.message;
                }
                setMessage({
                    type:'danger',
                    message:message,
                    show:'d-block'
                });

                if (error.response) {
                    if(error.response.status===401){
                        window.open(loginUrl,"_parent")
                    }
                }
            })
        }
    }

    const changeStatusRole = (checked) =>{
        if(checked === true){
            setStatusRole('Aktif')
            setInputCheckedStatus(true)
            setInputStatus(1);
        }else{
            setInputStatus(0);
            setInputCheckedStatus(false)
            setStatusRole('Non-Aktif')
        }
    }

    const changeInputNamaRole = (e) =>{
        let val = e.target.value;
        setInputNamaRole(val);
    }

    // s.artis
    const changeSwitchArtis = (valSwitch, eSwitch) =>{
        
        if(eSwitch === true){
            setSwitchArtis(true)
            setDataIzinArtis(
                [...dataIzinArtis, valSwitch],
                );
        }else{
            setSwitchArtis(false)
            var array = [...dataIzinArtis];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinArtis(
                    array,
                );
            }
        }
    }

    const changeCheckArtis = (e) =>{
        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinArtis(
                [...dataIzinArtis, val],
            );
                
            var newsDataChekbox = [...checkboxArtis];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxArtis(newsDataChekbox);
                
        }else if(e.target.checked === false){
            var array = [...dataIzinArtis];
            var index = array.indexOf(e.target.value);

            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinArtis(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxArtis];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxArtis(newsDataChekbox);
        }

    }

    // e.artis
    
    // s.kandidat
    const changeSwitchKandidat = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setSwitchKandidat(true)
            setDataIzinKandidat(
                [...dataIzinKandidat, valSwitch],
            );
        }else{
            setSwitchKandidat(false)
            var array = [...dataIzinKandidat];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinKandidat(
                    array,
                );
            }
        }
    }

    const changeCheckKandidat = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinKandidat(
                [...dataIzinKandidat, val],
            );

            var newsDataChekbox = [...checkboxKandidat];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxKandidat(newsDataChekbox);

        }else{
            var array = [...dataIzinKandidat];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinKandidat(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxKandidat];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxKandidat(newsDataChekbox);
        }

    }
    // e.kandidat
    
    // s.bookmark
    const changeSwitchBookmark = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setSwitchBookmark(true)
            setDataIzinBookmark(
                [...dataIzinBookmark, valSwitch],
            );

            var newsDataChekbox = [...checkboxBookmark];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxBookmark(newsDataChekbox);

        }else{
            setSwitchBookmark(false)
            var array = [...dataIzinBookmark];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinBookmark(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxBookmark];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxBookmark(newsDataChekbox);
        }
    }

    const changeCheckBookmark = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinBookmark(
                [...dataIzinBookmark, val],
            );
        }else{
            var array = [...dataIzinBookmark];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinBookmark(
                    array,
                );
            }
        }

    }
    // e.bookmark
    
    // s.judul
    const changeSwitchJudul = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setSwitchJudul(true)
            setDataIzinJudul(
                [...dataIzinJudul, valSwitch],
            );
        }else{
            setSwitchJudul(false)
            var array = [...dataIzinJudul];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinJudul(
                    array,
                );
            }
        }
    }

    const changeCheckJudul = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinJudul(
                [...dataIzinJudul, val],
            );

            var newsDataChekbox = [...checkboxJudul];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxJudul(newsDataChekbox);

        }else{
            var array = [...dataIzinJudul];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinJudul(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxJudul];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxJudul(newsDataChekbox);
        }

    }
    // e.judul
    
    // s.user
    const changeSwitchUser = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setSwitchUser(true)
            setDataIzinUser(
                [...dataIzinUser, valSwitch],
            );
        }else{
            setSwitchUser(false)
            var array = [...dataIzinUser];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinUser(
                    array,
                );
            }
        }
    }

    const changeCheckUser = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinUser(
                [...dataIzinUser, val],
            );

            var newsDataChekbox = [...checkboxUser];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxUser(newsDataChekbox);

        }else{
            var array = [...dataIzinUser];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinUser(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxUser];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxUser(newsDataChekbox);

        }

    }
    // e.user
    
    // s.role
    const changeSwitchRole = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setSwitchRole(true)
            setDataIzinRole(
                [...dataIzinRole, valSwitch],
            );
        }else{
            setSwitchRole(false)
            var array = [...dataIzinRole];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinRole(
                    array,
                );
            }
        }
    }

    const changeCheckRole = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinRole(
                [...dataIzinRole, val],
            );

            var newsDataChekbox = [...checkboxRole];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxRole(newsDataChekbox);

        }else{
            var array = [...dataIzinRole];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinRole(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxRole];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxRole(newsDataChekbox);
        }

    }
    // e.role
    
    // s.report
    const changeSwitchReport = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setSwitchReport(true)
            setDataIzinReport(
                [...dataIzinReport, valSwitch],
            );
        }else{
            setSwitchReport(false)
            var array = [...dataIzinReport];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinReport(
                    array,
                );
            }
        }
    }

    const changeCheckReport = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinReport(
                [...dataIzinReport, val],
            );

            var newsDataChekbox = [...checkboxReport];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxReport(newsDataChekbox);

        }else{
            var array = [...dataIzinReport];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinReport(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxReport];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxReport(newsDataChekbox);
        }

    }
    // e.report
    
    // s.crew
    const changeSwitchCrew = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setSwitchCrew(true)
            setDataIzinCrew(
                [...dataIzinCrew, valSwitch],
            );
        }else{
            setSwitchCrew(false)
            var array = [...dataIzinCrew];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinCrew(
                    array,
                );
            }
        }
    }

    const changeCheckCrew = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinCrew(
                [...dataIzinCrew, val],
            );

            var newsDataChekbox = [...checkboxCrew];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxCrew(newsDataChekbox);

        }else{
            var array = [...dataIzinCrew];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinCrew(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxCrew];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxCrew(newsDataChekbox);
        }

    }
    // e.crew
    
    
    // s.parentJudul overview
    const changeSwitchParentJudulOverview = (valSwitch, eSwitch) =>{
        
        if(eSwitch === true){
            setSwitchParentOverview(true)
            setDataParentJudulOverview(
                [...dataParentJudulOverview, valSwitch],
            );
        }else{
            setSwitchParentOverview(false)
            var array = [...dataParentJudulOverview];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulOverview(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulOverview = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulOverview(
                [...dataParentJudulOverview, val],
            );

            var newsDataChekbox = [...checkboxParentJudulOverview];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxParentJudulOverview(newsDataChekbox);
        }else{
            var array = [...dataParentJudulOverview];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulOverview(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxParentJudulOverview];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxParentJudulOverview(newsDataChekbox);
        }

    }
    // e.parentJudul overview
    
    // s.parentJudul time table
    const changeSwitchParentJudulTimeTable = (valSwitch, eSwitch) =>{
        
        if(eSwitch === true){
            setSwitchParentTimeTable(true)
            setDataParentJudulTimeTable(
                [...dataParentJudulTimeTable, valSwitch],
            );
        }else{
            setSwitchParentTimeTable(false)
            var array = [...dataParentJudulTimeTable];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulTimeTable(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulTimeTable = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulTimeTable(
                [...dataParentJudulTimeTable, val],
            );

            var newsDataChekbox = [...checkboxParentJudulTimeTable];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxParentJudulTimeTable(newsDataChekbox);

        }else{
            var array = [...dataParentJudulTimeTable];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulTimeTable(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxParentJudulTimeTable];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxParentJudulTimeTable(newsDataChekbox);
        }

    }
    // e.parentJudul time table
    
    // s.parentJudul bugeting
    const changeSwitchParentJudulBudgeting = (valSwitch, eSwitch) =>{
        
        if(eSwitch === true){
            setSwitchParentBudgeting(true)
            setDataParentJudulBudgeting(
                [...dataParentJudulBudgeting, valSwitch],
            );

        }else{
            setSwitchParentBudgeting(false)
            var array = [...dataParentJudulBudgeting];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulBudgeting(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulBudgeting = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulBudgeting(
                [...dataParentJudulBudgeting, val],
            );

            var newsDataChekbox = [...checkboxParentJudulBudgeting];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxParentJudulBudgeting(newsDataChekbox);
        }else{
            var array = [...dataParentJudulBudgeting];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulBudgeting(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxParentJudulBudgeting];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxParentJudulBudgeting(newsDataChekbox);
        }

    }
    // e.parentJudul bugeting
    
    // s.parentJudul casting
    const changeSwitchParentJudulCasting = (valSwitch, eSwitch) =>{
        
        if(eSwitch === true){
            setSwitchParentCasting(true)
            setDataParentJudulCasting(
                [...dataParentJudulCasting, valSwitch],
            );
        }else{
            setSwitchParentCasting(false)
            var array = [...dataParentJudulCasting];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulCasting(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulCasting = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulCasting(
                [...dataParentJudulCasting, val],
            );

            var newsDataChekbox = [...checkboxParentJudulCasting];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxParentJudulCasting(newsDataChekbox);

        }else{
            var array = [...dataParentJudulCasting];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulCasting(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxParentJudulCasting];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxParentJudulCasting(newsDataChekbox);
        }

    }
    // e.parentJudul casting
    
    // s.parentJudul bagan
    const changeSwitchParentJudulBagan = (valSwitch, eSwitch) =>{
        
        if(eSwitch === true){
            setSwitchParentBagan(true)
            setDataParentJudulBagan(
                [...dataParentJudulBagan, valSwitch],
            );
        }else{
            setSwitchParentBagan(false)
            var array = [...dataParentJudulBagan];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulBagan(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulBagan = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulBagan(
                [...dataParentJudulBagan, val],
            );

            var newsDataChekbox = [...checkboxParentJudulBagan];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxParentJudulBagan(newsDataChekbox);

        }else{
            var array = [...dataParentJudulBagan];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulBagan(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxParentJudulBagan];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxParentJudulBagan(newsDataChekbox);
        }

    }
    // e.parentJudul bagan
    
    // s.parentJudul skenario
    const changeSwitchParentJudulScenario = (valSwitch, eSwitch) =>{
        
        if(eSwitch === true){
            setSwitchParentScenario(true)
            setDataParentJudulScenario(
                [...dataParentJudulScenario, valSwitch],
            );
        }else{
            setSwitchParentScenario(false)
            var array = [...dataParentJudulScenario];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulScenario(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulScenario = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulScenario(
                [...dataParentJudulScenario, val],
            );

            var newsDataChekbox = [...checkboxParentJudulScenario];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxParentJudulScenario(newsDataChekbox);

        }else{
            var array = [...dataParentJudulScenario];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulScenario(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxParentJudulScenario];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxParentJudulScenario(newsDataChekbox);
        }

    }
    // e.parentJudul skenario
    
    // s.parentJudul plan
    const changeSwitchParentJudulPlanShootingHarian = (valSwitch, eSwitch) =>{
        
        if(eSwitch === true){
            setSwitchParentPlanShootingHarian(true)
            setDataParentJudulPlanShootingHarian(
                [...dataParentJudulPlanShootingHarian, valSwitch],
            );
        }else{
            setSwitchParentPlanShootingHarian(false)
            var array = [...dataParentJudulPlanShootingHarian];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulPlanShootingHarian(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulPlanShootingHarian = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulPlanShootingHarian(
                [...dataParentJudulPlanShootingHarian, val],
            );

            var newsDataChekbox = [...checkboxParentJudulPlanShootingHarian];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxParentJudulPlanShootingHarian(newsDataChekbox);

        }else{
            var array = [...dataParentJudulPlanShootingHarian];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulPlanShootingHarian(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxParentJudulPlanShootingHarian];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxParentJudulPlanShootingHarian(newsDataChekbox);
        }

    }
    // e.parentJudul plan
    
    // s.parentJudul pm
    const changeSwitchParentJudulProjectManagement = (valSwitch, eSwitch) =>{
        
        if(eSwitch === true){
            setSwitchParentProjectManagement(true)
            setDataParentJudulProjectManagement(
                [...dataParentJudulProjectManagement, valSwitch],
            );
        }else{
            setSwitchParentProjectManagement(false)
            var array = [...dataParentJudulProjectManagement];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulProjectManagement(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulProjectManagement = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulProjectManagement(
                [...dataParentJudulProjectManagement, val],
            );

            var newsDataChekbox = [...checkboxParentJudulProjectManagement];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxParentJudulProjectManagement(newsDataChekbox);

        }else{
            var array = [...dataParentJudulProjectManagement];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulProjectManagement(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxParentJudulProjectManagement];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxParentJudulProjectManagement(newsDataChekbox);
        }

    }
    // e.parentJudul pm
    
    // s.parentJudul laporan
    const changeSwitchParentJudulLaporan = (valSwitch, eSwitch) =>{
        
        if(eSwitch === true){
            setSwitchParentLaporan(true)
            setDataParentJudulLaporan(
                [...dataParentJudulLaporan, valSwitch],
            );
        }else{
            setSwitchParentLaporan(false)
            var array = [...dataParentJudulLaporan];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulLaporan(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulLaporan = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulLaporan(
                [...dataParentJudulLaporan, val],
            );

            var newsDataChekbox = [...checkboxParentJudulLaporan];
            newsDataChekbox[e.target.valuea].chaked = 1;
            setCheckboxParentJudulLaporan(newsDataChekbox);

        }else{
            var array = [...dataParentJudulLaporan];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulLaporan(
                    array,
                );
            }

            var newsDataChekbox = [...checkboxParentJudulLaporan];
            newsDataChekbox[e.target.valuea].chaked = 0;
            setCheckboxParentJudulLaporan(newsDataChekbox);

        }

    }
    // e.parentJudul laporan
    
    return(
        <>
            <Container fluid>
                <Loader visible={loader} className="loaders" type="ThreeDots" color="#ED1C24" height="100" width="100" />
                <AlertCustom 
                    setMessage={setMessage}
                    showError={message.show}
                    message={message.message}
                    type={message.type}
                ></AlertCustom>
                <Row>
                    <Col sm="12">
                        <Navbar expand="lg" variant="light" bg="white">
                            <Container>
                                <Navbar.Brand as={Link} to={"/manajemen-role"}><BsXLg size="15px" /> <span className="ml-3">Edit Role</span></Navbar.Brand>
                                <Navbar.Toggle />
                                <Navbar.Collapse className="justify-content-end">
                                <Navbar.Text>
                                    <Button variant="danger" type="submit" onClick={submitEditRole} disabled={disable}>Simpan Perubahan</Button>
                                </Navbar.Text>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </Col>
                </Row>
                <Row className="mt-5 justify-content-md-center">
                    <Col sm="6">
                        <Card className="border-card">
                            <Card.Body>
                                <Row>
                                    <Col sm="12">
                                        <h4 className="font-weight-bold">Informasi Role</h4>
                                    </Col>
                                    <Col sm="12">
                                        <Form>
                                            <Form.Group className="mb-4" controlId="nama">
                                                <Form.Label>Nama Role</Form.Label>
                                                    <Form.Control type="text" placeholder="Masukan Nama Role" value={inputNamaRole} onChange={changeInputNamaRole}/>
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col sm="12">
                                        <p>Status</p>
                                            <Switch checked={inputCheckedStatus} onChange={changeStatusRole}/> <span>{statusRole}</span>
                                        
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3 justify-content-md-center">
                    <Col sm="6">
                        <Card className="border-card">
                            <Card.Body>
                                <Row>
                                    <Col sm="12">
                                        <p>Izin Akses</p>
                                    </Col>
                                </Row>

                                <Row>
                                    {/* s.artis */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5" >
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                checked={switchArtis}
                                                onClick={() => setOpenArtis(!openArtis)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openArtis}
                                                onChange={e => {changeSwitchArtis(aksesArtis&&aksesArtis.name, e)}}
                                            /> <span className="ml-3">{aksesArtis&&aksesArtis.name}</span>
                                            <Collapse in={openArtis}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesArtis&&aksesArtis.child.length > 0 ?
                                                        <Row className="ml-3">
                                                            {checkboxArtis.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox key={i} checked={child.chaked === 1 ? true : false} onChange={name => {changeCheckArtis(name)}} value={child.name} valuea={i}>{child.name}</Checkbox></Col> 
                                                            ))}
                                                        </Row>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.artis */}
                                    
                                    {/* s.kandidat */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                checked={switchKandidat}
                                                onClick={() => setOpenKandidat(!openKandidat)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openKandidat}
                                                onChange={e => {changeSwitchKandidat(aksesKandidat&&aksesKandidat.name, e)}}
                                            /> <span className="ml-3">{aksesKandidat&&aksesKandidat.name}</span>
                                            <Collapse in={openKandidat}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesKandidat&&aksesKandidat.child.length > 0 ?
                                                        <Row className="ml-3">
                                                            {checkboxKandidat&&checkboxKandidat.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox checked={child.chaked ? true : false} onChange={name => {changeCheckKandidat(name)}} value={child.name} valuea={i}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.kandidat */}
                                    
                                    {/* s.bookmark */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch
                                                checked={switchBookmark} 
                                                onClick={() => setOpenBookmark(!openBookmark)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openBookmark}
                                                onChange={e => {changeSwitchBookmark(aksesBookmark&&aksesBookmark.name, e)}}
                                            /> <span className="ml-3">{aksesBookmark&&aksesBookmark.name}</span>
                                            <Collapse in={openBookmark}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesBookmark&&aksesBookmark.child.length > 0 ?
                                                        <Row className="ml-3">
                                                            {checkboxBookmark&&checkboxBookmark.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox checked={child.chaked ? true : false} onChange={name => {changeCheckBookmark(name)}} value={child.name} valuea={i}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.bookmark */}
                                    
                                    {/* s.judul */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                checked={switchJudul}
                                                onClick={() => setOpenJudul(!openJudul)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openJudul}
                                                onChange={e => {changeSwitchJudul(aksesJudul&&aksesJudul.name, e)}}
                                            /> <span className="ml-3">{aksesJudul&&aksesJudul.name}</span>
                                            <Collapse in={openJudul}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesJudul&&aksesJudul.child.length > 0 ?
                                                        <>
                                                        <Row className="ml-3">
                                                            {checkboxJudul&&checkboxJudul.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox checked={child.chaked ? true : false} onChange={name => {changeCheckJudul(name)}} value={child.name} valuea={i}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>

                                                        <Row>
                                                            {/* s.overview */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        checked={switchParentOverview}
                                                                        onClick={() => setOpenParentJudulOverview(!openParentJudulOverview)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulOverview}
                                                                        onChange={e => {changeSwitchParentJudulOverview(aksesParentJudulOverview&&aksesParentJudulOverview.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulOverview&&aksesParentJudulOverview.name}</span>
                                                                    <Collapse in={openParentJudulOverview}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulOverview&&aksesParentJudulOverview.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {checkboxParentJudulOverview&&checkboxParentJudulOverview.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox checked={step_child.chaked ? true : false} onChange={name => {changeCheckParentJudulOverview(name)}} value={step_child.name} valuea={i}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.overview */}
                                                            
                                                            {/* s.time table */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        checked={switchParentTimeTable}
                                                                        onClick={() => setOpenParentJudulTimeTable(!openParentJudulTimeTable)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulTimeTable}
                                                                        onChange={e => {changeSwitchParentJudulTimeTable(aksesParentJudulTimeTable&&aksesParentJudulTimeTable.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulTimeTable&&aksesParentJudulTimeTable.name}</span>
                                                                    <Collapse in={openParentJudulTimeTable}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulTimeTable&&aksesParentJudulTimeTable.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {checkboxParentJudulTimeTable&&checkboxParentJudulTimeTable.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox checked={step_child.chaked ? true : false} onChange={name => {changeCheckParentJudulTimeTable(name)}} value={step_child.name} valuea={i}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.time table */}
                                                            
                                                            {/* s.budgeting */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        checked={switchParentBudgeting}
                                                                        onClick={() => setOpenParentJudulBudgeting(!openParentJudulBudgeting)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulBudgeting}
                                                                        onChange={e => {changeSwitchParentJudulBudgeting(aksesParentJudulBudgeting&&aksesParentJudulBudgeting.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulBudgeting&&aksesParentJudulBudgeting.name}</span>
                                                                    <Collapse in={openParentJudulBudgeting}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulBudgeting&&aksesParentJudulBudgeting.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {checkboxParentJudulBudgeting&&checkboxParentJudulBudgeting.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox checked={step_child.chaked ? true : false} onChange={name => {changeCheckParentJudulBudgeting(name)}} value={step_child.name} valuea={i}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.budgeting */}

                                                            {/* s.casting */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        checked={switchParentCasting}
                                                                        onClick={() => setOpenParentJudulCasting(!openParentJudulCasting)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulCasting}
                                                                        onChange={e => {changeSwitchParentJudulCasting(aksesParentJudulCasting&&aksesParentJudulCasting.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulCasting&&aksesParentJudulCasting.name}</span>
                                                                    <Collapse in={openParentJudulCasting}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulCasting&&aksesParentJudulCasting.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {checkboxParentJudulCasting&&checkboxParentJudulCasting.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox checked={step_child.chaked ? true : false} onChange={name => {changeCheckParentJudulCasting(name)}} value={step_child.name} valuea={i}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.casting */}
                                                            
                                                            {/* s.bagan */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        checked={switchParentBagan}
                                                                        onClick={() => setOpenParentJudulBagan(!openParentJudulBagan)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulBagan}
                                                                        onChange={e => {changeSwitchParentJudulBagan(aksesParentJudulBagan&&aksesParentJudulBagan.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulBagan&&aksesParentJudulBagan.name}</span>
                                                                    <Collapse in={openParentJudulBagan}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulBagan&&aksesParentJudulBagan.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {checkboxParentJudulBagan&&checkboxParentJudulBagan.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox checked={step_child.chaked ? true : false} onChange={name => {changeCheckParentJudulBagan(name)}} value={step_child.name} valuea={i}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.bagan */}
                                                            
                                                            {/* s.scenario */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        checked={switchParentScenario}
                                                                        onClick={() => setOpenParentJudulScenario(!openParentJudulScenario)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulScenario}
                                                                        onChange={e => {changeSwitchParentJudulScenario(aksesParentJudulScenario&&aksesParentJudulScenario.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulScenario&&aksesParentJudulScenario.name}</span>
                                                                    <Collapse in={openParentJudulScenario}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulScenario&&aksesParentJudulScenario.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {checkboxParentJudulScenario&&checkboxParentJudulScenario.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox checked={step_child.chaked ? true : false} onChange={name => {changeCheckParentJudulScenario(name)}} value={step_child.name} valuea={i}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.scenario */}
                                                            
                                                            {/* s.shoot */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        checked={switchParentPlanShootingHarian}
                                                                        onClick={() => setOpenParentJudulPlanShootingHarian(!openParentJudulPlanShootingHarian)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulPlanShootingHarian}
                                                                        onChange={e => {changeSwitchParentJudulPlanShootingHarian(aksesParentJudulPlanShootingHarian&&aksesParentJudulPlanShootingHarian.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulPlanShootingHarian&&aksesParentJudulPlanShootingHarian.name}</span>
                                                                    <Collapse in={openParentJudulPlanShootingHarian}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulPlanShootingHarian&&aksesParentJudulPlanShootingHarian.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {checkboxParentJudulPlanShootingHarian&&checkboxParentJudulPlanShootingHarian.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox checked={step_child.chaked ? true : false} onChange={name => {changeCheckParentJudulPlanShootingHarian(name)}} value={step_child.name} valuea={i}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.shoot */}
                                                            
                                                            {/* s.pm */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        checked={switchParentProjectManagement}
                                                                        onClick={() => setOpenParentJudulProjectManagement(!openParentJudulProjectManagement)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulProjectManagement}
                                                                        onChange={e => {changeSwitchParentJudulProjectManagement(aksesParentJudulProjectManagement&&aksesParentJudulProjectManagement.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulProjectManagement&&aksesParentJudulProjectManagement.name}</span>
                                                                    <Collapse in={openParentJudulProjectManagement}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulProjectManagement&&aksesParentJudulProjectManagement.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {checkboxParentJudulProjectManagement&&checkboxParentJudulProjectManagement.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox checked={step_child.chaked ? true : false} onChange={name => {changeCheckParentJudulProjectManagement(name)}} value={step_child.name} valuea={i}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.pm */}
                                                            
                                                            {/* s.laporan */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        checked={switchParentLaporan}
                                                                        onClick={() => setOpenParentJudulLaporan(!openParentJudulLaporan)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulLaporan}
                                                                        onChange={e => {changeSwitchParentJudulLaporan(aksesParentJudulLaporan&&aksesParentJudulLaporan.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulLaporan&&aksesParentJudulLaporan.name}</span>
                                                                    <Collapse in={openParentJudulLaporan}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulLaporan&&aksesParentJudulLaporan.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {checkboxParentJudulLaporan&&checkboxParentJudulLaporan.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox checked={step_child.chaked ? true : false} onChange={name => {changeCheckParentJudulLaporan(name)}} value={step_child.name} valuea={i}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.laporan */}

                                                        </Row>
                                                        </>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.judul */}
                                    
                                    {/* s.user */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                checked={switchUser}
                                                onClick={() => setOpenUser(!openUser)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openUser}
                                                onChange={e => {changeSwitchUser(aksesUser&&aksesUser.name, e)}}
                                            /> <span className="ml-3">{aksesUser&&aksesUser.name}</span>
                                            <Collapse in={openUser}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesUser&&aksesUser.child.length > 0 ?
                                                        <Row className="ml-3">
                                                            {checkboxUser&&checkboxUser.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox checked={child.chaked ? true : false} onChange={name => {changeCheckUser(name)}} value={child.name} valuea={i}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.user */}
                                    
                                    {/* s.role */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                checked={switchRole}
                                                onClick={() => setOpenRole(!openRole)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openRole}
                                                onChange={e => {changeSwitchRole(aksesRole&&aksesRole.name, e)}}
                                            /> <span className="ml-3">{aksesRole&&aksesRole.name}</span>
                                            <Collapse in={openRole}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesRole&&aksesRole.child.length > 0 ?
                                                        <Row className="ml-3">
                                                            {checkboxRole&&checkboxRole.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox checked={child.chaked ? true : false} onChange={name => {changeCheckRole(name)}} value={child.name} valuea={i}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.role */}
                                    
                                    {/* s.report */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                checked={switchReport}
                                                onClick={() => setOpenReport(!openReport)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openReport}
                                                onChange={e => {changeSwitchReport(aksesReport&&aksesReport.name, e)}}
                                            /> <span className="ml-3">{aksesReport&&aksesReport.name}</span>
                                            <Collapse in={openReport}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesReport&&aksesReport.child.length > 0 ?
                                                        <Row className="ml-3">
                                                            {checkboxReport&&checkboxReport.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox checked={child.chaked ? true : false} onChange={name => {changeCheckReport(name)}} value={child.name} valuea={i}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.report */}
                                    
                                    {/* s.crew */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                checked={switchCrew}
                                                onClick={() => setOpenCrew(!openCrew)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openCrew}
                                                onChange={e => {changeSwitchCrew(aksesCrew&&aksesCrew.name, e)}}
                                            /> <span className="ml-3">{aksesCrew&&aksesCrew.name}</span>
                                            <Collapse in={openCrew}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesCrew&&aksesCrew.child.length > 0 ?
                                                        <Row className="ml-3">
                                                            {checkboxCrew&&checkboxCrew.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox checked={child.chaked ? true : false} onChange={name => {changeCheckCrew(name)}} value={child.name} valuea={i}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.crew */}

                                </Row>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default EditRole