import React, { useContext, useState } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import { FormTextInput, Form } from "tabler-react";
import axios from "axios";
import { ContextAlert } from "../../Context/Alert";

function TambahBagan(prop) {
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [message, setMessage] = useContext(ContextAlert);
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");

  const handleClose = () => {
    setShow(false);
    setInput({});
  };
  const handleShow = () => setShow(true);

  const [input, setInput] = useState({
    bagan_name: "",
    description: "",
  });

  function handleChange(evt) {
    let value = evt.target.value;
    if (evt.target.name === "bagan_name") {
      if (value) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
    setInput({
      ...input,
      [evt.target.name]: value,
    });
  }

  const onSubmit = (data) => {
    data.preventDefault();
    setDisabled(true);
    axios
      .post(`${urlMicroservice}talent/bagan/store/${prop.id}`, input, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setMessage({
          type: "success",
          message: "Berhasil Menambahkan Bagan",
          show: "d-block",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(function (e) {
        setDisabled(false);
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  };

  return (
    <>
      <Button variant="danger" onClick={handleShow}>
        <i className="bx bx-plus"></i> Buat Bagan Baru
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Buat Bagan Baru</Modal.Title>
        </Modal.Header>
        <form onSubmit={onSubmit}>
          <Modal.Body className="my-2">
            <FormTextInput
              name="bagan_name"
              label="Nama Bagan"
              placeholder="Masukkan nama bagan"
              value={input.bagan_name}
              onChange={handleChange}
            />
            <Form.Textarea
              name="description"
              label="Deskripsi"
              rows={2}
              placeholder="Masukkan deskripsi bagan"
              value={input.description}
              onChange={handleChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              type="button"
              onClick={handleClose}
            >
              Batal
            </Button>
            <Button variant="danger" type="submit" disabled={disabled}>
              Buat
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default TambahBagan;
