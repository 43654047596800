import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

function Breadcrumbs(props) {
  const listItems = props.itemsObjects.map((item) => (
    <Breadcrumb.Item to={item.to} as={Link} active={item.active}>
      {item.value}
    </Breadcrumb.Item>
  ));

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <div>
          <Breadcrumb>{listItems}</Breadcrumb>
          <h4 className="title font-weight-bold">{props.title}</h4>
        </div>
        <div className="mr-2">{props.rightBreadcrumb}</div>
      </div>
    </>
  );
}

export default Breadcrumbs;
