import React, {useState} from 'react';
import {Modal, Button, Row, Col, Form} from "react-bootstrap";
import axios from 'axios';
import AlertCustom from '../../../Components/Modal/AlertCustomComponent';

function BuatGroup(props) {
    const [show, setShow]           = useState(false);
    const handleClose               = () => setShow(false);
    const handleShow                = () => setShow(true);
    const [groupName, setGroupName] = useState('');
    const [message, setMessage]     = useState({type:'',message:'', show:'d-none'});
    const [disable, setDisable]     = useState(false);
    const loginUrl                  = process.env.REACT_APP_LOGIN_TOKEN_URL;

    const submitTambahGroup = () =>{

      const data = new FormData();
      data.append('group_name', groupName);
     
      if(groupName === ''){
        var message = 'Nama group tidak boleh kosong';
        setMessage({
            type:'danger',
            message:message,
            show:'d-block'
        });
      }else{
        setDisable(true);
        axios.post(process.env.REACT_APP_BE_DBTALENT_URL+`talent/group/store`, data,
        { 
          headers: {
              'Authorization' : `Bearer ${props.token}`, 
              'Accept'        : 'application/json', 
              'Content-Type'  : 'application/json'
          }
        })
        .then((res) =>{
          setMessage({
            type:'success',
            message:"Berhasil Menambahkan Group",
            show:'d-block'
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch(error =>{
          setDisable(false);
          var message = error.message;
          if(typeof error.response != "undefined"){
            message = error.response.data.message;
          }
          setMessage({
            type:'danger',
            message:message,
            show:'d-block'
          });

          if (error.response) {
            if(error.response.status===401){
                window.open(loginUrl,"_parent")
            }
          }
        })

      }
    }

    const changeGroupNama = (e) => {
      let val = e.target.value;
      setGroupName(val);
    }

    return (
      <>
        <Button variant="danger" onClick={handleShow}> + Buat Group</Button>
  
        <Modal 
          show={show} 
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <AlertCustom 
            setMessage={setMessage}
            showError={message.show}
            message={message.message}
            type={message.type}
          ></AlertCustom>
          <Modal.Header closeButton>
            <Modal.Title>Buat Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-0">
                <Col sm="12" className="mt-0">
                  <Form.Group className="mb-0" controlId="nama">
                      <Form.Label>Buat Group <span className='text-danger'>*</span></Form.Label>
                      <Form.Control type="text" placeholder="Masukan Nama Group" onChange={changeGroupNama}/>
                  </Form.Group>
                </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="outline-danger" className="px-5 mr-2" onClick={handleClose}>Batal</Button>
                    <Button variant="danger" className="px-5 py-2" disabled={disable} onClick={submitTambahGroup}>Buat</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  export default BuatGroup