import React from 'react';
import 'boxicons/css/boxicons.min.css';
import { Nav } from 'react-bootstrap';
import styles from './sidebar.module.scss';

export function Sidebar({ menuObj, activePath, subHeader  }) {

  const listItems = menuObj.map((item, index) =>
    <Nav.Link className={(activePath===item.to?styles.active+" ":"")+styles.nav_link} href={item.to} key={index}>
      <i className={"bx "+item.icon}></i>
      {item.value}
    </Nav.Link>
  );

  return (
    <Nav className={"flex-column "+styles.menu_sidebar} data-subheader={subHeader}>
      {listItems}
    </Nav>
  );
}
