import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Dropdown, Form } from "react-bootstrap";
import axios from "axios";
import { ContextAlert } from "../../Context/Alert";
import Select from "react-select";

const Pengalaman = (prop) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useContext(ContextAlert);
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [status, setStatus] = useState({});
  const [year, setYear] = useState();

  const dataStatus = [
    {
      value: 0,
      label: "Tambah",
    },
    {
      value: 1,
      label: "Hapus",
    },
  ];

  const handleClose = () => {
    setStatus({});
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const onSubmit = async (data) => {
    data.preventDefault();

    const currentYear = new Date().getFullYear();

    if (
      status?.value === 0 &&
      (year == null ||
        year.length !== 4 ||
        isNaN(year) ||
        year < 1900 ||
        year > currentYear)
    ) {
      setMessage({
        type: "danger",
        message: "Tahun belum sesuai",
        show: "d-block",
      });
      return;
    }

    try {
      const response = await axios.post(
        `${urlMicroservice}talent/bagan/pengalaman-artist/${prop.id}`,
        {
          type: status?.value,
          year: year ? year : 1234,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessage({
        type: "success",
        message: `Berhasil mengubah data`,
        show: "d-block",
      });
      setStatus({});
      setYear();
      setShow(false);
    } catch (e) {
      let message;

      if (e.response && e.response.data && e.response.data.message) {
        const errorData = e.response.data.message;
        if (typeof errorData === "object") {
          message = Object.values(errorData).flat().join(", ");
        } else {
          message = errorData;
        }
      } else {
        message = e.message || "An error occurred";
      }

      setMessage({
        type: "danger",
        message: message,
        show: "d-block",
      });
    }
  };

  const handleInputStatus = (item) => {
    setStatus(item);
  };

  return (
    <>
      <Dropdown.Item className="text-dark" onClick={handleShow}>
        Pengalaman
      </Dropdown.Item>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Atau Hapus</Modal.Title>
        </Modal.Header>
        <form onSubmit={onSubmit}>
          <Modal.Body className="my-2">
            <div>
              <label>
                Tambah Atau Hapus <span className="text-danger">*</span>
              </label>
              <Select
                name="Pilih Status Artis"
                options={dataStatus}
                onChange={(event) => handleInputStatus(event)}
                value={status}
                placeholder="Pilih Status Artis"
                isMulti={false}
              />
            </div>
            {status?.value === 0 && (
              <Form.Group className="mt-4" controlId="nama">
                <Form.Label>
                  Tahun Judul <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Masukan Tahun"
                  onChange={(e) => setYear(e.target.value)}
                  value={year}
                />
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              type="button"
              onClick={handleClose}
            >
              Batal
            </Button>
            <Button variant="danger" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default Pengalaman;
