import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { Form } from "tabler-react";
import axios from "axios";
import AlertCustom from "../../../Components/Modal/AlertCustomComponent";
import { BsShareFill } from "react-icons/bs";

function ShareFileReports(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [message, setMessage] = useState({
    type: "",
    message: "",
    show: "d-none",
  });

  const initialDataForm = {
    user_id: 0,
    name_to: null,
    email_to: null,
    role_to: null,
  };

  const [disable, setDisable] = useState(false);
  const [dataForm, setDataForm] = useState(initialDataForm);
  const [dataUser, setDataUser] = useState([]);
  const loginUrl = process.env.REACT_APP_LOGIN_TOKEN_URL;

  const submitEditFileProspect = () => {
    if (dataForm.user_id === 0) {
      var message = "Nama tidak boleh kosong";
      setMessage({
        type: "danger",
        message: message,
        show: "d-block",
      });
    } else {
      setDisable(true);
      const data = JSON.stringify(dataForm);

      axios
        .post(
          process.env.REACT_APP_BE_DBTALENT_URL +
            `talent/report/prospect/share/` +
            props.id,
          data,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setMessage({
            type: "success",
            message: "Berhasil Bagikan File",
            show: "d-block",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          setDisable(false);
          var message = error.message;
          if (typeof error.response != "undefined") {
            message = error.response.data.message;
          }
          setMessage({
            type: "danger",
            message: message,
            show: "d-block",
          });

          if (error.response) {
            if (error.response.status === 401) {
              window.open(loginUrl, "_parent");
            }
          }
        });
    }
  };

  const getUser = () => {
    setDisable(true);
    axios
      .get(process.env.REACT_APP_BE_DBTALENT_URL + `user/get-user`, {
        headers: {
          Authorization: `Bearer ${props.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setDataUser(res.data.data);
        setDisable(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  };

  const handleSelectUser = (id) => {
    const data = dataUser.find((item) => item.id == id);
    if (data) {
      setDataForm({
        user_id: data.id,
        name_to: data.name,
        email_to: data.email,
        role_to: data.role_name,
      });
    }
  };

  return (
    <>
      <Button
        disabled={props.disable}
        variant="light"
        className="mr-2"
        onClick={() => {
          handleShow();
          getUser();
        }}
      >
        <BsShareFill className="mr-2 pt-1" size={16} />
        Bagikan file
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <AlertCustom
          setMessage={setMessage}
          showError={message.show}
          message={message.message}
          type={message.type}
        ></AlertCustom>

        <Modal.Header closeButton>
          <Modal.Title>Bagikan File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-0">
            <Col sm="12" className="mt-0">
              <Form.Group label="Nama">
                <Form.Select
                  name="nama"
                  onChange={(e) => handleSelectUser(e.target.value)}
                >
                  <option value={""}>- Pilih Nama -</option>
                  {dataUser &&
                    dataUser.map((items, key) => (
                      <option key={key} value={items.id}>
                        {items.name} - {items.role_name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            className="px-5 mr-2"
            onClick={handleClose}
          >
            Batal
          </Button>
          <Button
            variant="danger"
            className="px-5 py-2"
            disabled={disable}
            onClick={submitEditFileProspect}
          >
            Bagikan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShareFileReports;
