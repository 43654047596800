import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { Radio } from "antd";
import axios from "axios";
import AlertCustom from "../../../../../Components/Modal/AlertCustomComponent";
import Select from "react-select";

const EditStatusArtist = ({ daftarTalent, reloadDaftarArtist }) => {
  const dataStatus = [
    {
      value: 0,
      label: "Not Available",
    },
    {
      value: 1,
      label: "Available",
    },
  ];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    type: "",
    message: "",
    show: "d-none",
  });
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [status, setStatus] = useState([]);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    handleUpdateData();
  }, [daftarTalent]);

  const handleUpdateData = () => {
    if (Array.isArray(daftarTalent) && daftarTalent.length > 0) {
      daftarTalent.map((e) => {
        const item = dataStatus.find((val) => val.value == e.availability);

        handleInputStatus(item, e.artist_id);
      });
    }
  };

  const onSubmit = async () => {
    try {
      const response = await axios.post(
        `${urlMicroservice}talent/casting/talent/available`,
        {
          artist: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessage({
        type: "success",
        message: `Berhasil mengubah data`,
        show: "d-block",
      });
      setStatus([]);
      setShow(false);
      reloadDaftarArtist();
    } catch (e) {
      let message;

      if (e.response && e.response.data && e.response.data.message) {
        const errorData = e.response.data.message;

        if (typeof errorData === "object") {
          message = Object.values(errorData).flat().join(", ");
        } else {
          message = errorData;
        }
      } else {
        message = e.message || "An error occurred";
      }

      setMessage({
        type: "danger",
        message: message,
        show: "d-block",
      });
    }
  };

  const serachDefaultValue = (value) => {
    return dataStatus.find((item) => item.value == value);
  };

  function capitalizeFirstLetter(string) {
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const handleInputStatus = (item, id) => {
    setStatus((prevStatus) => {
      const existingIndex = prevStatus.findIndex((e) => e.artist_id === id);

      if (existingIndex !== -1) {
        const updatedStatus = [...prevStatus];
        updatedStatus[existingIndex].availability = item.value;
        return updatedStatus;
      } else {
        return [...prevStatus, { artist_id: id, availability: item.value }];
      }
    });
  };

  return (
    <>
      <Button
        variant="outline-danger w-100"
        size="sm"
        onClick={() => handleShow()}
      >
        Ubah Status Artist
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <AlertCustom
          setMessage={setMessage}
          showError={message.show}
          message={message.message}
          type={message.type}
        ></AlertCustom>

        <Modal.Header closeButton>
          <Modal.Title>Ubah Status Artist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-0">
            {daftarTalent.length > 0
              ? daftarTalent.map((item, key) => (
                  <Col key={key} sm="12" className="mt-4">
                    <div>
                      <label>
                        <span style={{ fontWeight: 600 }}>
                          {capitalizeFirstLetter(item.nama_artis)}
                        </span>
                      </label>
                      <Select
                        name="Pilih Status Artis"
                        options={dataStatus}
                        onChange={(event) =>
                          handleInputStatus(event, item.artist_id)
                        }
                        defaultValue={serachDefaultValue(item.availability)}
                        placeholder="Pilih Status Artis"
                        isMulti={false}
                      />
                    </div>
                  </Col>
                ))
              : ""}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            className="px-5 mr-2"
            onClick={handleClose}
          >
            Batal
          </Button>
          <Button
            disabled={disable}
            variant="danger"
            className="px-5 py-2"
            onClick={() => onSubmit()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditStatusArtist;
