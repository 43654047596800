import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

function Breadcrumbs(props) {
  const listItems = props.itemsObjects.map((item) => (
    <Breadcrumb.Item to={item.to} as={Link} active={item.active}>
      {item.value}
    </Breadcrumb.Item>
  ));

  return (
    <>
      <Breadcrumb>{listItems}</Breadcrumb>
      <h4 className="title">{props.title}</h4>
    </>
  );
}

export default Breadcrumbs;
