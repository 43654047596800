import React, { createContext, useReducer, useState } from "react";

const initialState = {
  nama_artis: "",
  gender: [1, 2],
  usia_min: "",
  usia_maks: "",
  tinggi_min: "",
  tinggi_maks: "",
  berat_min: "",
  berat_maks: "",
  peran: [],
  nilai_casting_min: "",
  nilai_casting_maks: "",
  talent_prospect: [],
  judul_sinetron: "",
  eksklusif: "",
  penerimaan_artis_dari_tanggal: "",
  penerimaan_artis_sampai_tanggal: "",
  tampil_filter: false,
  submit: false,
  filter_id: "",
  casting_tim: "",
  management: "",
  category_casting: [],
  karakter: "",
};

const Store = ({ children }) => {
  const [state, dispatch] = useState(initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
